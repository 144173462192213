import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEmpty } from "lodash";
import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Row } from "reactstrap";
import FalconCardHeader from "../../components/common/FalconCardHeader";
import DonationsTable from "../../containers/donation/DonationsTable";

import FirebaseTimestamp from "../../lib/FirebaseTimestamp";
import Money from "../../lib/Money";
import { fetchDonations } from "../../redux/actions/donations.actions";
import { fetchDonor } from "../../redux/actions/donors.actions";
import { getDonations } from "../../redux/reducers/donations.reducer";
import { getDonorById } from "../../redux/reducers/donors.reducer";

export default function DetailDonorPage({ match }) {
  const dispatch = useDispatch();
  const donorId = match.params.id;
  const donor = useSelector(getDonorById(donorId));
  const donations = useSelector(getDonations);
  const { loading } = useSelector(state => state.ui);

  useEffect(() => {
    dispatch(fetchDonor({ donorId }));
    dispatch(
      fetchDonations({
        constraints: [["donor.user_id", "==", donorId]],
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [donorId]);

  if (loading || isEmpty(donor)) {
    return <h1>Loading</h1>;
  }

  return (
    <Fragment>
      <Card className="mb-3">
        <CardBody>
          <div className="d-flex align-items-start">
            <div className="mr-4">
              {donor.image && (
                <img
                  src={donor.image.url}
                  alt={donor.name}
                  width={100}
                  height={100}
                  className="rounded-circle"
                />
              )}
            </div>

            <div>
              <div className="d-flex align-items-center mb-3">
                <h3 className="mb-0 mr-2">{donor.name}</h3>
                <FontAwesomeIcon icon="check-circle" className="text-primary" />
              </div>
              <p>
                Donor since{" "}
                <Fragment>{new FirebaseTimestamp(donor.created_at).format("D MMMM YYYY")}</Fragment>
              </p>
              <p>
                ID : <b>{donor.id}</b>
              </p>
              <p>
                Email : <b>{donor.email}</b>
              </p>
              <div dangerouslySetInnerHTML={{ __html: donor.description }}></div>
            </div>
          </div>
        </CardBody>
      </Card>

      <Card className="mb-3">
        <CardBody>
          <Row>
            <Col className="text-center">
              <FontAwesomeIcon icon="heart" className="fs--1" />{" "}
              <b>{donor.counter ? donor.counter.waqfs ?? 0 : 0}</b> waqf telah diikuti
            </Col>
            <Col className="text-center">
              <FontAwesomeIcon icon="money-bill-alt" className="fs--1" />{" "}
              <b>
                {new Money(donor.counter ? donor.counter.total_donation ?? 0 : 0).currencyFormat()}
              </b>{" "}
              total donasi
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card className="mb-3">
        <FalconCardHeader title="Donasi" light={false}></FalconCardHeader>

        <CardBody className="p-0">
          <DonationsTable donations={donations} />
        </CardBody>
      </Card>
    </Fragment>
  );
}

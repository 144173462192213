import React, { useEffect, useState, Fragment } from "react";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

import { fetchWaqf, fetchWaqfDonors } from "../../redux/actions/waqfs.actions";
import { getWaqfById } from "../../redux/reducers/waqfs.reducer";
import WaqfReportForm from "../../components/form/WaqfReportForm";
import DetailWaqfTab from "../../containers/waqf/DetailWaqfTab";
import {
  createWaqfReport,
  deleteWaqfReport,
  fetchWaqfReports,
  updateWaqfReport,
} from "../../redux/actions/waqf-reports.actions";
import { getWaqfReports } from "../../redux/reducers/waqf-reports.reducer";
import WaqfReportCard from "../../containers/waqf/WaqfReportCard";
import { waqfReportFactory } from "../../entities/waqf-report";

export default function DetailWaqfPage({ match }) {
  const waqfId = match.params.id;
  const dispatch = useDispatch();
  const waqf = useSelector(getWaqfById(waqfId));
  const waqfReports = useSelector(getWaqfReports);
  const { loading } = useSelector(state => state.ui);
  const [selectedWaqf, setSelectedWaqf] = useState(null);

  const [activeTab, setActiveTab] = useState(1);

  function handleSubmit(values, { resetForm }) {
    if (values.id) {
      dispatch(updateWaqfReport({ waqfId, waqfReport: values }));
    } else {
      dispatch(
        createWaqfReport({
          waqfId,
          waqfReport: values,
        })
      );
    }

    resetForm();
  }

  function handleEdit(waqfReportId) {
    setSelectedWaqf(waqfReports.find(o => o.id === waqfReportId));
    setActiveTab(3);
  }

  function handleDelete(waqfReportId) {
    dispatch(deleteWaqfReport({ waqfId, waqfReportId }));
  }

  function handleCancelForm() {
    setSelectedWaqf(null);
    setActiveTab(1);
  }

  useEffect(() => {
    dispatch(fetchWaqf({ waqfId }));
    dispatch(fetchWaqfReports({ waqfId }));
    dispatch(fetchWaqfDonors({ waqfId }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading || !waqf) {
    return <h1>Loading...</h1>;
  }

  return (
    <Fragment>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === 1 })}
            onClick={() => setActiveTab(1)}
          >
            Detail
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === 2 })}
            onClick={() => setActiveTab(2)}
          >
            Daftar Laporan
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === 3 })}
            onClick={() => setActiveTab(3)}
          >
            Form Laporan
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab}>
        {/* NOTE: DETAIL */}
        <TabPane tabId={1}>
          <DetailWaqfTab waqf={waqf} />
        </TabPane>

        {/* NOTE: REPORT LIST */}
        <TabPane tabId={2} className="mt-3">
          {waqfReports.map(report => (
            <WaqfReportCard
              key={report.id}
              report={report}
              onEdit={handleEdit}
              onDelete={handleDelete}
            />
          ))}
        </TabPane>

        {/* NOTE: REPORT */}
        <TabPane tabId={3}>
          <WaqfReportForm
            initialValues={selectedWaqf ?? waqfReportFactory()}
            classNames="mt-3"
            waqfAmountCollected={waqf.counter ? waqf.counter.total_donation : 0}
            onSubmit={handleSubmit}
            onCancel={handleCancelForm}
          />
        </TabPane>
      </TabContent>
    </Fragment>
  );
}

import React, { createRef, Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";

import { fetchThreads } from "../../redux/actions/threads.actions";
import { getThreads } from "../../redux/reducers/threads.reducer";
import FirebaseTimestamp from "../../lib/FirebaseTimestamp";
import Avatar from "../../components/common/Avatar";

const opFormatter = op => {
  return (
    <div className="d-flex align-items-center">
      <Avatar src={op.photo} useImage />
      <span className="ml-2">{op.name}</span>
    </div>
  );
};

const counterFormatter = counter => (
  <div className="d-flex">
    <div className="d-flex align-items-center mr-3">
      <FontAwesomeIcon icon="heart" />
      <span className="ml-1">{counter.likes}</span>
    </div>
    <div className="d-flex align-items-center">
      <FontAwesomeIcon icon="comments" />
      <span className="ml-1">{counter.replies}</span>
    </div>
  </div>
);
const bodyFormatter = body => <div dangerouslySetInnerHTML={{ __html: body }}></div>;
const timeFormatter = time => <Fragment>{new FirebaseTimestamp(time).formatted}</Fragment>;

const columns = [
  {
    dataField: "op",
    text: "Original Poster",
    formatter: opFormatter,
    classes: "border-0 align-middle pr-4",
    headerClasses: "border-0",
    headerStyle: { minWidth: "200px" },
    sort: true,
  },
  {
    dataField: "title",
    text: "Judul",
    classes: "border-0 align-middle pr-4",
    headerClasses: "border-0",
    headerStyle: { minWidth: "200px" },
    sort: true,
  },
  {
    dataField: "body",
    text: "Isi",
    formatter: bodyFormatter,
    classes: "border-0 align-middle pr-4",
    headerClasses: "border-0",
    headerStyle: { minWidth: "300px" },
    sort: true,
  },
  {
    dataField: "counter",
    text: "Likes & Replies",
    formatter: counterFormatter,
    classes: "border-0 align-middle pr-4",
    headerClasses: "border-0",
    headerStyle: { minWidth: "100px" },
    sort: true,
  },
  {
    dataField: "created_at",
    text: "Ditambahkan pada",
    formatter: timeFormatter,
    classes: "border-0 align-middle",
    headerClasses: "border-0",
    sort: true,
  },
  {
    dataField: "action",
    classes: "border-0 align-middle text-right",
    headerClasses: "border-0",
    text: "",
  },
];

function appendAction(thread) {
  return {
    ...thread,
    action: <ThreadAction thread={thread} />,
  };
}

const ThreadAction = ({ thread }) => {
  return (
    <Fragment>
      <Link to={`/threads/${thread.id}`}>
        <Button color="link" size="sm" className="text-600 btn-reveal mr-1">
          <FontAwesomeIcon icon="eye" className="fs--1 text-info" />
        </Button>
      </Link>
    </Fragment>
  );
};

const ThreadsTable = () => {
  let table = createRef();
  const dispatch = useDispatch();
  const threads = useSelector(getThreads);

  useEffect(() => {
    dispatch(fetchThreads());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PaginationProvider
      pagination={paginationFactory({
        custom: true,
        totalSize: threads.length,
        sizePerPage: threads.length,
      })}
    >
      {({ paginationProps, paginationTableProps }) => {
        return (
          <Fragment>
            <div className="table-responsive">
              <BootstrapTable
                ref={table}
                bootstrap4
                keyField="id"
                data={threads.map(appendAction)}
                columns={columns}
                bordered={false}
                classes="table-dashboard table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
                rowClasses="btn-reveal-trigger border-top border-200"
                headerClasses="bg-200 text-900 border-y border-200"
                noDataIndication="Tidak ada pertanyaan"
                {...paginationTableProps}
              />
            </div>
            <Row noGutters className="px-1 py-3">
              <Col className="pl-3 fs--1">
                <span>{paginationProps.totalSize} Pertanyaan</span>
              </Col>
            </Row>
          </Fragment>
        );
      }}
    </PaginationProvider>
  );
};

export default ThreadsTable;

import React from "react";
import { Nav } from "reactstrap";

import NotificationDropdown from "./NotificationDropdown";
import ProfileDropdown from "./ProfileDropdown";

const TopNavRightSideNavItem = () => {
  return (
    <Nav navbar className="navbar-nav-icons ml-auto flex-row align-items-center">
      <NotificationDropdown />
      <ProfileDropdown />
    </Nav>
  );
};

export default TopNavRightSideNavItem;

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import rocket from "../../assets/img/illustrations/rocket.png";

import AuthenticationLayout from "../../layouts/authentication";

import { logout } from "../../redux/actions/auth.actions";

export default function LogoutPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthenticationLayout
      leftSideContent={
        <p className="mb-0 mt-4 mt-md-5 fs--1 font-weight-semi-bold text-300">
          Read our{" "}
          <Link className="text-underline text-300" to="#!">
            terms
          </Link>{" "}
          and{" "}
          <Link className="text-underline text-300" to="#!">
            conditions{" "}
          </Link>
        </p>
      }
    >
      <div className="text-center">
        <img className="d-block mx-auto mb-4" src={rocket} alt="shield" width={70} />
        <h3>See you again!</h3>
        <p>
          Thanks for using Wakafyuk. You are <br className="d-none d-sm-block" />
          now successfully signed out.
        </p>
        <Button tag={Link} color="primary" size="sm" className="mt-3" to={`/auth/login`}>
          <FontAwesomeIcon icon="chevron-left" transform="shrink-4 down-1" className="mr-1" />
          Return to Login
        </Button>
      </div>
    </AuthenticationLayout>
  );
}

import React from "react";
import { useDispatch } from "react-redux";

import NewsForm from "../../components/form/NewsForm";
import { createNews } from "../../redux/actions/news.actions";

export default function CreateNewsPage() {
  const dispatch = useDispatch();

  function handleSubmit(values) {
    dispatch(createNews({ news: values }));
  }

  return <NewsForm title="Tambah Berita" onSubmit={handleSubmit} />;
}

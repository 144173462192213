import { push } from "connected-react-router";

import {
  BANNER,
  CREATE_BANNER,
  DELETE_BANNER,
  FETCH_BANNER,
  FETCH_BANNERS,
  setBanners,
  updateBanners,
  UPDATE_BANNER,
} from "../../actions/banners.actions";
import {
  databaseDelete,
  databaseFetchCollection,
  databaseFetchItem,
  databasePrepareWrite,
  DATABASE_DELETE_SUCCESS,
  DATABASE_ERROR,
  DATABASE_FETCH_COLLECTION_SUCCESS,
  DATABASE_FETCH_ITEM_SUCCESS,
  DATABASE_STORE_SUCCESS,
  DATABASE_UPDATE_SUCCESS,
} from "../../actions/database.actions";
import { collectionName as collection, bannerFactory as factory } from "../../../entities/banner";
import { setLoader } from "../../actions/ui.actions";
import { setToast } from "../../actions/toast.actions";

export const bannersMiddleware = ({ dispatch }) => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_BANNERS:
      const { constraints } = action.meta;

      next([
        databaseFetchCollection({ collection, feature: BANNER, constraints }),
        setLoader({ state: true, feature: BANNER }),
      ]);
      break;

    case `${BANNER} ${DATABASE_FETCH_COLLECTION_SUCCESS}`:
      next([setBanners({ banners: action.payload }), setLoader({ state: false, feature: BANNER })]);
      break;

    case FETCH_BANNER:
      next([
        databaseFetchItem({ path: `${collection}/${action.payload}`, feature: BANNER }),
        setLoader({ state: true, feature: BANNER }),
      ]);
      break;

    case `${BANNER} ${DATABASE_FETCH_ITEM_SUCCESS}`:
      next([
        updateBanners({ banner: action.payload }),
        setLoader({ state: false, feature: BANNER }),
      ]);
      break;

    case CREATE_BANNER:
      next([
        databasePrepareWrite({
          collection,
          factory,
          data: action.payload,
          feature: BANNER,
          databaseAction: "create",
        }),
      ]);
      break;

    case `${BANNER} ${DATABASE_STORE_SUCCESS}`:
      next([
        setToast({
          message: "Berhasil menambahkan banner",
          feature: BANNER,
        }),
        setLoader({ state: false, feature: BANNER }),
      ]);
      dispatch(push("/banners"));
      break;

    case UPDATE_BANNER:
      next([
        databasePrepareWrite({
          collection,
          factory,
          data: action.payload,
          feature: BANNER,
          databaseAction: "update",
        }),
      ]);
      break;

    case `${BANNER} ${DATABASE_UPDATE_SUCCESS}`:
      next([
        setToast({
          message: "Berhasil mengupdate banner",
          feature: BANNER,
        }),
        setLoader({
          state: false,
          feature: BANNER,
        }),
      ]);
      dispatch(push("/banners"));
      break;

    case DELETE_BANNER:
      next([
        databaseDelete({
          collection,
          id: action.payload,
          feature: BANNER,
        }),
        setLoader({
          state: true,
          feature: BANNER,
        }),
      ]);
      break;

    case `${BANNER} ${DATABASE_DELETE_SUCCESS}`:
      next([
        setToast({
          message: "Berhasil menghapus banner",
          feature: BANNER,
        }),
        setLoader({
          state: false,
          feature: BANNER,
        }),
      ]);
      break;

    case `${BANNER} ${DATABASE_ERROR}`:
      next([
        setToast({
          message: action.payload.message.replace(/:.*$/, ""),
          type: "error",
          feature: BANNER,
        }),
        setLoader({ state: false, feature: BANNER }),
      ]);
      break;

    default:
      break;
  }
};

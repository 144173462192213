// NOTE: if you want to change menu roles, also change the roles in file route
export const appRoutes = [
  {
    name: "Home",
    to: "/",
    exact: true,
    icon: "chart-pie",
  },
  {
    name: "Program Wakaf",
    to: "/waqfs",
    exact: true,
    icon: "hand-holding-heart",
    roles: ["admin", "nazir"],
  },
  {
    name: "Donasi",
    to: "/donations",
    exact: true,
    icon: "money-bill-alt",
    roles: ["admin", "nazir", "payment_admin"],
  },
  {
    name: "Donatur",
    to: "/donors",
    exact: true,
    icon: "pray",
    roles: ["admin"],
  },
  {
    name: "Forum",
    to: "/threads",
    exact: true,
    icon: "users",
    roles: ["admin", "nazir"],
  },
  {
    name: "Master Data",
    to: "/",
    exact: true,
    icon: "database",
    roles: ["admin"],
    children: [
      {
        to: "/categories",
        name: "Kategori",
        roles: ["admin"],
        exact: true,
      },
      {
        to: "/nazirs",
        name: "Nazir",
        roles: ["admin"],
        exact: true,
      },
      {
        to: "/payment-methods",
        name: "Metode Pembayaran",
        roles: ["admin"],
        exact: true,
      },
      {
        to: "/banners",
        name: "Banner",
        roles: ["admin"],
        exact: true,
      },
      {
        to: "/events",
        name: "Event",
        roles: ["admin"],
        exact: true,
      },
      {
        to: "/news",
        name: "Berita",
        roles: ["admin"],
        exact: true,
      },
    ],
  },
];

import React, { createRef, Fragment } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { deletePaymentMethod } from "../../redux/actions/paymentMethods.actions";

const logoFormatter = logo => (
  <Fragment>
    <img src={logo.url} alt={logo.name} width={50} />
  </Fragment>
);

const columns = [
  {
    dataField: "name",
    text: "Nama Metode Pembayaran",
    classes: "border-0 align-middle pr-4",
    headerClasses: "border-0",
    headerStyle: { minWidth: "250px" },
    sort: true,
  },
  {
    dataField: "code",
    text: "Kode",
    classes: "border-0 align-middle pr-4",
    headerClasses: "border-0",
    headerStyle: { minWidth: "80px" },
    sort: true,
  },
  {
    dataField: "type",
    text: "Jenis",
    classes: "border-0 align-middle pr-4",
    headerClasses: "border-0",
    headerStyle: { minWidth: "80px" },
    sort: true,
  },
  {
    dataField: "logo",
    text: "Logo",
    formatter: logoFormatter,
    classes: "border-0 align-middle pr-4",
    headerClasses: "border-0",
    headerStyle: { minWidth: "80px" },
    sort: true,
  },
  {
    dataField: "action",
    classes: "border-0 align-middle pr-4 text-right",
    headerClasses: "border-0",
    text: "",
  },
];

function appendAction(paymentMethod) {
  return {
    ...paymentMethod,
    action: <PaymentMethodAction paymentMethod={paymentMethod} />,
  };
}

const PaymentMethodAction = ({ paymentMethod }) => {
  const dispatch = useDispatch();

  function handleDelete() {
    confirmAlert({
      title: "Hapus Metode Pembayaran",
      message: `Apakah anda yakin ingin menghapus metode pembayaran ${paymentMethod.name}`,
      buttons: [
        {
          label: "Ya, Hapus",
          onClick: () =>
            dispatch(
              deletePaymentMethod({
                paymentMethodId: paymentMethod.id,
              })
            ),
        },
        {
          label: "Tidak",
        },
      ],
    });
  }

  return (
    <Fragment>
      <Link to={`/payment-methods/${paymentMethod.id}`}>
        <Button color="link" size="sm" className="text-600 btn-reveal mr-1">
          <FontAwesomeIcon icon="edit" className="fs--1 text-info" />
        </Button>
      </Link>

      <Button color="link" size="sm" className="text-600 btn-reveal" onClick={handleDelete}>
        <FontAwesomeIcon icon="trash" className="fs--1 text-danger" />
      </Button>
    </Fragment>
  );
};

const PaymentMethodsTable = ({ paymentMethods }) => {
  let table = createRef();

  return (
    <PaginationProvider
      pagination={paginationFactory({
        custom: true,
        totalSize: paymentMethods.length,
        sizePerPage: paymentMethods.length,
      })}
    >
      {({ paginationProps, paginationTableProps }) => {
        return (
          <Fragment>
            <div className="table-responsive">
              <BootstrapTable
                ref={table}
                bootstrap4
                keyField="id"
                data={paymentMethods.map(appendAction)}
                columns={columns}
                bordered={false}
                classes="table-dashboard table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
                rowClasses="btn-reveal-trigger border-top border-200"
                headerClasses="bg-200 text-900 border-y border-200"
                noDataIndication="Tidak ada data metode pembayaran"
                {...paginationTableProps}
              />
            </div>

            <Row noGutters className="px-1 py-3">
              <Col className="pl-3 fs--1">
                <span>{paginationProps.totalSize} Metode Pembayaran</span>
              </Col>
            </Row>
          </Fragment>
        );
      }}
    </PaginationProvider>
  );
};

export default PaymentMethodsTable;

// action types
export const DATABASE_FETCH_COLLECTION = "Fetching data from database";
export const DATABASE_FETCH_COLLECTION_SUCCESS = "Fetch data from database success";
export const DATABASE_FETCH_ITEM = "Fetching single data from database";
export const DATABASE_FETCH_ITEM_SUCCESS = "Fetch single data from database success";
export const DATABASE_STORE = "Storing data to database";
export const DATABASE_STORE_SUCCESS = "Store data to database success";
export const DATABASE_UPDATE = "Updating data in database";
export const DATABASE_UPDATE_SUCCESS = "Update data in database success";
export const DATABASE_DELETE = "Deleting data from database";
export const DATABASE_DELETE_SUCCESS = "Delete data from database success";
export const DATABASE_ERROR = "Database error";
export const DATABASE_PREPARE_WRITE = "Prepare data before writing to database";

// action creators
export const databaseFetchCollection = ({
  collection,
  feature,
  constraints = [],
  orderBy = [],
  startAfter = "",
  limit = 0,
}) => ({
  type: `${feature} ${DATABASE_FETCH_COLLECTION}`,
  payload: collection,
  meta: { feature, constraints, orderBy, startAfter, limit },
});

export const databaseFetchCollectionSuccess = ({ items, feature, collection }) => ({
  type: `${feature} ${DATABASE_FETCH_COLLECTION_SUCCESS}`,
  payload: items,
  meta: { feature, collection },
});

export const databaseFetchItem = ({ path, feature, subCollection = "" }) => ({
  type: `${feature} ${DATABASE_FETCH_ITEM}`,
  payload: path,
  meta: { feature, subCollection },
});

export const databaseFetchItemSuccess = ({ item, feature }) => ({
  type: `${feature} ${DATABASE_FETCH_ITEM_SUCCESS}`,
  payload: item,
  meta: { feature },
});

export const databaseStore = ({ values, collection, feature }) => ({
  type: `${feature} ${DATABASE_STORE}`,
  payload: values,
  meta: { collection, feature },
});

export const databaseStoreSuccess = ({ response, feature }) => ({
  type: `${feature} ${DATABASE_STORE_SUCCESS}`,
  payload: response,
  meta: { feature },
});

export const databaseUpdate = ({ values, collection, feature }) => ({
  type: `${feature} ${DATABASE_UPDATE}`,
  payload: values,
  meta: { collection, feature },
});

export const databaseUpdateSuccess = ({ response, collection, feature }) => ({
  type: `${feature} ${DATABASE_UPDATE_SUCCESS}`,
  payload: response,
  meta: { feature, collection },
});

export const databaseDelete = ({ id, collection, feature }) => ({
  type: `${feature} ${DATABASE_DELETE}`,
  payload: id,
  meta: { collection, feature },
});

export const databaseDeleteSuccess = ({ response, feature, collection }) => ({
  type: `${feature} ${DATABASE_DELETE_SUCCESS}`,
  payload: response,
  meta: { feature, collection },
});

export const databaseError = ({ error, feature }) => ({
  type: `${feature} ${DATABASE_ERROR}`,
  payload: error,
  meta: { feature },
});

export const databasePrepareWrite = ({ data, feature, databaseAction, collection, factory }) => ({
  type: `${feature} ${DATABASE_PREPARE_WRITE}`,
  payload: data,
  meta: { feature, databaseAction, collection, factory },
});

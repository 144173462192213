import { push } from "connected-react-router";
import moment from "moment";

import { collectionName as collection, eventFactory as factory } from "../../../entities/event";
import FirebaseTimestamp from "../../../lib/FirebaseTimestamp";
import {
  databaseDelete,
  databaseFetchCollection,
  databaseFetchItem,
  databasePrepareWrite,
  DATABASE_DELETE_SUCCESS,
  DATABASE_ERROR,
  DATABASE_FETCH_COLLECTION_SUCCESS,
  DATABASE_FETCH_ITEM_SUCCESS,
  DATABASE_STORE_SUCCESS,
  DATABASE_UPDATE_SUCCESS,
} from "../../actions/database.actions";
import {
  CREATE_EVENT,
  DELETE_EVENT,
  EVENT,
  FETCH_EVENT,
  FETCH_EVENTS,
  setEvents,
  updateEvents,
  UPDATE_EVENT,
} from "../../actions/events.actions";
import { setToast } from "../../actions/toast.actions";
import { setLoader } from "../../actions/ui.actions";

export const eventsMiddleware = ({ dispatch }) => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_EVENTS:
      var { constraints, orderBy, limit } = action.meta;

      next([
        databaseFetchCollection({ collection, feature: EVENT, constraints, orderBy, limit }),
        setLoader({
          state: true,
          feature: EVENT,
        }),
      ]);
      break;

    case `${EVENT} ${DATABASE_FETCH_COLLECTION_SUCCESS}`:
      next([setEvents({ events: action.payload }), setLoader({ state: false, feature: EVENT })]);
      break;

    case FETCH_EVENT:
      next([
        databaseFetchItem({ path: `${collection}/${action.payload}`, feature: EVENT }),
        setLoader({ state: true, feature: EVENT }),
      ]);
      break;

    case `${EVENT} ${DATABASE_FETCH_ITEM_SUCCESS}`:
      next([updateEvents({ event: action.payload }), setLoader({ state: false, feature: EVENT })]);
      break;

    case CREATE_EVENT:
      next(
        databasePrepareWrite({
          collection,
          factory,
          data: action.payload,
          feature: EVENT,
          databaseAction: "create",
        })
      );
      break;

    case `${EVENT} ${DATABASE_STORE_SUCCESS}`:
      next([
        setToast({ message: "Berhasil menambahkan event", feature: EVENT }),
        setLoader({ state: false, feature: EVENT }),
      ]);
      dispatch(push("/events"));
      break;

    case UPDATE_EVENT:
      next(
        databasePrepareWrite({
          collection,
          factory,
          data: action.payload,
          feature: EVENT,
          databaseAction: "update",
        })
      );
      break;

    case `${EVENT} ${DATABASE_UPDATE_SUCCESS}`:
      next([
        setToast({
          message: "Berhasil mengupdate event",
          feature: EVENT,
        }),
        setLoader({
          state: false,
          feature: EVENT,
        }),
      ]);
      dispatch(push("/events"));
      break;

    case DELETE_EVENT:
      next([
        databaseDelete({ collection, id: action.payload, feature: EVENT }),
        setLoader({
          state: true,
          feature: EVENT,
        }),
      ]);
      break;

    case `${EVENT} ${DATABASE_DELETE_SUCCESS}`:
      next([
        setToast({
          message: "Berhasil menghapus event",
          feature: EVENT,
        }),
        setLoader({
          state: false,
          feature: EVENT,
        }),
      ]);
      dispatch(push("/events"));
      break;

    case `${EVENT} ${DATABASE_ERROR}`:
      next([
        setToast({
          message: action.payload.message.replace(/:.*$/, ""),
          type: "error",
          feature: EVENT,
        }),
        setLoader({ state: false, feature: EVENT }),
      ]);
      break;

    default:
      break;
  }
};

export const formatEvent = event => ({
  ...event,
  start: moment(new FirebaseTimestamp(event.start).format()).toDate(),
  end: moment(new FirebaseTimestamp(event.end).format()).toDate(),
});

import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ListGroup from "reactstrap/es/ListGroup";
import ListGroupItem from "reactstrap/es/ListGroupItem";
import { isEmpty } from "lodash";

import FalconCardHeader from "../common/FalconCardHeader";
import Notification from "../notification/Notification";
import { fetchNotifications } from "../../redux/actions/notifications.actions";
import { getNotifications } from "../../redux/reducers/notifications.reducer";
import { fieldPath } from "../../singletons/firebase";

const NotificationDropdown = () => {
  const dispatch = useDispatch();
  let notifications = useSelector(getNotifications);
  const user = useSelector(state => state.auth);
  notifications = notifications.filter(o => !o.read).slice(0, 5);

  const [isOpen, setIsOpen] = useState(false);

  // Handler
  const handleToggle = e => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (!isEmpty(user)) {
      dispatch(
        fetchNotifications({
          constraints: [["read", "==", false]],
          orderBy: [[fieldPath.documentId()]],
          limit: 10,
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Dropdown
      nav
      inNavbar
      isOpen={isOpen}
      toggle={handleToggle}
      onMouseOver={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setIsOpen(true);
      }}
      onMouseLeave={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setIsOpen(false);
      }}
    >
      <DropdownToggle
        nav
        className={classNames("px-0", {
          "notification-indicator notification-indicator-primary": !isEmpty(notifications), // read indicator
        })}
      >
        <FontAwesomeIcon icon="bell" transform="shrink-6" className="fs-4" />
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-card">
        <Card className="card-notification shadow-none" style={{ maxWidth: "20rem" }}>
          <FalconCardHeader
            className="card-header"
            title="Notifications"
            titleTag="h6"
            light={false}
          ></FalconCardHeader>
          <ListGroup flush className="font-weight-normal fs--1">
            <div className="list-group-title">NEW</div>
            <div style={{ maxHeight: 300, overflow: "auto" }}>
              {notifications &&
                notifications.map((notification, index) => (
                  <ListGroupItem key={index} onClick={handleToggle}>
                    <Notification
                      title={notification.title}
                      avatar={{ src: notification.image, icon: "bell" }}
                      unread={!notification.read}
                      flush
                    >
                      {notification.body}
                    </Notification>
                  </ListGroupItem>
                ))}
            </div>
          </ListGroup>
          <div className="card-footer text-center border-top" onClick={handleToggle}>
            <Link className="card-link d-block" to="/notifications">
              View all
            </Link>
          </div>
        </Card>
      </DropdownMenu>
    </Dropdown>
  );
};

export default NotificationDropdown;

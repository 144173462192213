import { DELETE_EVENT, SET_EVENTS, UPDATE_EVENTS } from "../actions/events.actions";
import entityReducer from "./entityReducer";

export const eventsReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_EVENTS:
    case UPDATE_EVENTS:
    case DELETE_EVENT:
      return entityReducer(state, action);

    default:
      return state;
  }
};

// feature selectors
export const getEvents = state => Object.values(state.events);
export const getEventById = id => state => state.events[id];

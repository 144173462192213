import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import {
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import FalconCardHeader from "../../components/common/FalconCardHeader";
import FirebaseTimestamp from "../../lib/FirebaseTimestamp";
import Money from "../../lib/Money";

const WaqfReportCard = ({ report, onEdit, onDelete }) => {
  return (
    <Card className="mb-3">
      <FalconCardHeader
        title={
          <Fragment>
            <p className="mb-1 line-height-1">{report.title}</p>
            <p className="mb-0 fs--1 d-flex">
              <span className="text-primary">{new Money(report.amount ?? 0).currencyFormat()}</span>
              <div className="ml-3">
                <FontAwesomeIcon icon="calendar-alt" />{" "}
                {new FirebaseTimestamp(report.date).formatted}
              </div>
            </p>
          </Fragment>
        }
      >
        <UncontrolledDropdown>
          <DropdownToggle color="Secondary" size="sm" className="p-0">
            <FontAwesomeIcon icon="ellipsis-h" />
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem onClick={() => onEdit(report.id)}>Edit</DropdownItem>
            <DropdownItem className="text-danger" onClick={() => onDelete(report.id)}>
              Delete
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </FalconCardHeader>
      <CardBody>
        <div dangerouslySetInnerHTML={{ __html: report.description }}></div>
        <Row noGutters className="m-n1">
          {report.images &&
            report.images.map(image => (
              <Col md={4} className="p-1">
                <img src={image.url} alt={report.title} className="w-100 rounded" />
              </Col>
            ))}
        </Row>
      </CardBody>
    </Card>
  );
};

export default WaqfReportCard;

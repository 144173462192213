import { SET_SHOW_BURGER_MENU, SET_NAVBAR_COLLAPSED, SET_LOADER } from "../actions/ui.actions";

const initialState = {
  showBurgerMenu: false,
  navbarCollapsed: false,
  loading: false,
};

export const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case action.type.includes(SET_LOADER):
      return { ...state, loading: action.payload };

    case SET_SHOW_BURGER_MENU:
      return { ...state, showBurgerMenu: action.payload };

    case SET_NAVBAR_COLLAPSED:
      return { ...state, navbarCollapsed: action.payload };

    default:
      return state;
  }
};

import { collectionName as collection } from "../../../entities/thread";
import {
  databaseFetchCollection,
  databaseFetchItem,
  DATABASE_FETCH_COLLECTION_SUCCESS,
  DATABASE_FETCH_ITEM_SUCCESS,
} from "../../actions/database.actions";
import {
  FETCH_THREAD,
  FETCH_THREADS,
  setThreads,
  THREAD,
  updateThreads,
} from "../../actions/threads.actions";
import { setLoader } from "../../actions/ui.actions";

export const threadsMiddleware = ({ dispatch }) => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_THREADS:
      next([
        databaseFetchCollection({ collection, feature: THREAD }),
        setLoader({ state: true, feature: THREAD }),
      ]);
      break;

    case `${THREAD} ${DATABASE_FETCH_COLLECTION_SUCCESS}`:
      next([setThreads({ threads: action.payload }), setLoader({ state: true, feature: THREAD })]);
      break;

    case FETCH_THREAD:
      next([
        databaseFetchItem({ path: `${collection}/${action.payload}`, feature: THREAD }),
        setLoader({
          state: true,
          feature: THREAD,
        }),
      ]);
      break;

    case `${THREAD} ${DATABASE_FETCH_ITEM_SUCCESS}`:
      next([
        updateThreads({ thread: action.payload }),
        setLoader({
          state: false,
          feature: THREAD,
        }),
      ]);
      break;

    default:
      break;
  }
};

import { DELETE_NEWS, SET_NEWS_STATE, UPDATE_NEWS_STATE } from "../actions/news.actions";
import entityReducer from "./entityReducer";

export const newsReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_NEWS_STATE:
    case UPDATE_NEWS_STATE:
    case DELETE_NEWS:
      return entityReducer(state, action);

    default:
      return state;
  }
};

// feature selectors
export const getNews = state => Object.values(state.news);
export const getNewsById = id => state => state.news[id];

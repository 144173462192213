import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CountUp from "react-countup";
import { Button, ButtonGroup, Card, CardBody, CardFooter } from "reactstrap";
import Datetime from "react-datetime";
import moment from "moment";

import FalconCardHeader from "../../components/common/FalconCardHeader";
import CardSummary from "../../components/home/CardSummary";
import { fetchDonations } from "../../redux/actions/donations.actions";
import { getDonations } from "../../redux/reducers/donations.reducer";
import DonationChart from "./DonationChart";

const NazirDashboard = ({ counter }) => {
  const [time, setTime] = useState("week");
  const [startDate, setStartDate] = useState(moment().subtract(7, "d"));
  const [endDate, setEndDate] = useState(moment());

  const dispatch = useDispatch();
  const donations = useSelector(getDonations);
  const auth = useSelector(state => state.auth);

  function handleChangeStartDate(date) {
    setTime("");
    setStartDate(date);
  }

  function handleChangeEndDate(date) {
    setTime("");
    setEndDate(date);
  }

  function handleChangeTime(time) {
    switch (time) {
      case "week":
        setStartDate(moment().subtract(7, "d"));
        break;

      case "month":
        setStartDate(moment().subtract(1, "months"));
        break;

      default:
        setStartDate(moment().subtract(1, "years"));
        break;
    }

    setTime(time);
  }

  useEffect(() => {
    dispatch(
      fetchDonations({
        constraints: [
          ["created_at", ">", new Date(startDate)],
          ["created_at", "<", new Date(endDate)],
          ["waqf.nazir_id", "==", auth.id],
        ],
      })
    );
  }, [startDate, endDate, dispatch, auth]);

  return (
    <Fragment>
      <div className="card-deck">
        <CardSummary title="Total Donasi" color="warning" linkText="Lihat Semua" to="/donations">
          <CountUp end={counter.donations ?? 0} duration={3} separator="," decimal="." />
        </CardSummary>

        <CardSummary title="Donasi Terkumpul" color="info" linkText="Lihat Semua" to="/donations">
          <CountUp
            end={counter.total_received ?? 0}
            prefix="IDR "
            duration={3}
            separator=","
            decimal="."
          />
        </CardSummary>

        <CardSummary title="Program Wakaf" color="success" linkText="Lihat Semua" to="/waqfs">
          <CountUp end={counter.waqfs ?? 0} duration={3} separator="," decimal="." />
        </CardSummary>
      </div>

      <Card>
        <FalconCardHeader title="Donasi">
          <div className="d-flex align-items-center">
            <Datetime
              closeOnSelect
              timeFormat={false}
              value={startDate}
              onChange={handleChangeStartDate}
            />
            <FontAwesomeIcon icon="arrow-right" className="mx-3" />
            <Datetime
              closeOnSelect
              timeFormat={false}
              value={endDate}
              onChange={handleChangeEndDate}
            />
          </div>
        </FalconCardHeader>

        <CardBody>
          <DonationChart
            time={time}
            startDate={startDate}
            endDate={endDate}
            donations={donations}
          />
        </CardBody>

        <CardFooter className="d-flex justify-content-end">
          <ButtonGroup>
            <Button
              size="sm"
              color="light"
              active={time === "week"}
              onClick={() => handleChangeTime("week")}
            >
              Minggu
            </Button>
            <Button
              size="sm"
              color="light"
              active={time === "month"}
              onClick={() => handleChangeTime("month")}
            >
              Bulan
            </Button>
            <Button
              size="sm"
              color="light"
              active={time === "year"}
              onClick={() => handleChangeTime("year")}
            >
              Tahun
            </Button>
          </ButtonGroup>
        </CardFooter>
      </Card>
    </Fragment>
  );
};

export default NazirDashboard;

import { entityName } from "../../entities/event";

// feature name
export const EVENT = `[${entityName}]`;

// action types
export const FETCH_EVENTS = `${EVENT} Fetch events`;
export const FETCH_EVENT = `${EVENT} Fetch a event`;
export const SET_EVENTS = `${EVENT} Set events`;
export const UPDATE_EVENTS = `${EVENT} Update an item in events`;
export const CREATE_EVENT = `${EVENT} Create event`;
export const UPDATE_EVENT = `${EVENT} Update a event`;
export const DELETE_EVENT = `${EVENT} Delete a event`;

// action creators
export const fetchEvents = ({ constraints = [], orderBy = [], limit = 0 } = {}) => ({
  type: FETCH_EVENTS,
  meta: {
    constraints,
    orderBy,
    limit,
  },
});

export const setEvents = ({ events }) => ({
  type: SET_EVENTS,
  payload: events,
});

export const fetchEvent = ({ eventId }) => ({
  type: FETCH_EVENT,
  payload: eventId,
});

export const updateEvents = ({ event }) => ({
  type: UPDATE_EVENTS,
  payload: event,
});

export const createEvent = ({ event }) => ({
  type: CREATE_EVENT,
  payload: event,
});

export const updateEvent = ({ event }) => ({
  type: UPDATE_EVENT,
  payload: event,
});

export const deleteEvent = ({ eventId }) => ({
  type: DELETE_EVENT,
  payload: eventId,
});

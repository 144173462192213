export const donationFactory = () => {
  return {
    donor: {
      name: "",
      email: "",
      phone: "",
      is_registered: false,
      is_anonymous: false,
    },
    waqf: {
      id: "",
      title: "",
      nazir_name: "",
    },
    amount: 10000,
    fee_amount: 200,
    unique_code: 0,
    total_amount: 10200,
    message: "",
    payment_method: {
      id: "",
      name: "",
    },
    recurring: "",
    recurring_period: "",
    deadline: null,
    status: "pending",
  };
};

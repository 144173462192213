import { httpRequest, HTTP_SUCCESS } from "../../actions/http.actions";
import { CHECK_PAYMENT_STATUS, LINK_AJA, setPaymentStatus } from "../../actions/linkAja.actions";
import { setLoader } from "../../actions/ui.actions";

export const linkAjaMiddleware = ({ dispatch }) => next => action => {
  next(action);

  switch (action.type) {
    case CHECK_PAYMENT_STATUS:
      next([
        httpRequest({
          body: action.payload,
          feature: LINK_AJA,
          functionName: "linkAjaPaymentCheck",
        }),
        setLoader({ state: true, feature: LINK_AJA }),
      ]);
      break;

    case `${LINK_AJA} ${HTTP_SUCCESS}`:
      const { data } = action.payload;
      next([
        setPaymentStatus({ payment: data.data }),
        setLoader({ state: false, feature: LINK_AJA }),
      ]);
      break;

    default:
      break;
  }
};

import { entityName } from "../../entities/news";

// feature name
export const NEWS = `[${entityName}]`;

// action types
export const FETCH_NEWS = `${NEWS} Fetch news`;
export const FETCH_SINGLE_NEWS = `${NEWS} Fetch a news`;
export const SET_NEWS_STATE = `${NEWS} Set news state`;
export const UPDATE_NEWS_STATE = `${NEWS} Update an item in news state`;
export const CREATE_NEWS = `${NEWS} Create news`;
export const UPDATE_NEWS = `${NEWS} Update a news`;
export const DELETE_NEWS = `${NEWS} Delete a news`;

// action creators
export const fetchNews = () => ({
  type: FETCH_NEWS,
});

export const setNewsState = ({ news }) => ({
  type: SET_NEWS_STATE,
  payload: news,
  meta: { feature: NEWS },
});

export const updateNewsState = ({ news }) => ({
  type: UPDATE_NEWS_STATE,
  payload: news,
  meta: { feature: NEWS },
});

export const fetchSingleNews = ({ newsId }) => ({
  type: FETCH_SINGLE_NEWS,
  payload: newsId,
});

export const createNews = ({ news }) => ({
  type: CREATE_NEWS,
  payload: news,
  meta: { feature: NEWS },
});

export const updateNews = ({ news }) => ({
  type: UPDATE_NEWS,
  payload: news,
  meta: { feature: NEWS },
});

export const deleteNews = ({ newsId }) => ({
  type: DELETE_NEWS,
  payload: newsId,
  meta: { feature: NEWS },
});

import React, { Fragment } from "react";
import { useField } from "formik";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import classNames from "classnames";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  UncontrolledDropdown,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { uploadFile } from "../../../helpers/utils";
import cloudUpload from "../../../assets/img/icons/cloud-upload.svg";
import Flex from "../../common/Flex";
import { isEmpty } from "lodash";
import { storage } from "../../../singletons/firebase";
import { useDispatch } from "react-redux";
import { setToast } from "../../../redux/actions/toast.actions";
import { NAZIR } from "../../../redux/actions/nazirs.actions";

const getSize = size => {
  if (size < 1024) {
    return (
      <Fragment>
        <strong>{size}</strong> Byte
      </Fragment>
    );
  } else if (size < 1024 * 1024) {
    return (
      <Fragment>
        <strong>{(size / 1024).toFixed(2)}</strong> KB
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <strong>{(size / (1024 * 1024)).toFixed(2)}</strong> MB
      </Fragment>
    );
  }
};

const FormikDropzone = props => {
  const dispatch = useDispatch();
  const [field, meta, helpers] = useField(props);
  const error = meta.touched && meta.error;
  const { placeholder, className, preview, isMulti, folder, limit, ...rest } = props;

  async function onDrop(acceptedFiles) {
    if (
      (!isMulti && !isEmpty(field.value)) ||
      (isMulti && !isEmpty(field.value) && limit === field.value.length)
    ) {
      dispatch(
        setToast({
          message: "the limit on the number of files has been exceeded",
          feature: NAZIR,
          type: "error",
        })
      );

      return;
    }

    if (!!acceptedFiles.length) {
      const files = await Promise.all(
        acceptedFiles.map(async o => {
          const file = await uploadFile(o, folder);

          return file;
        })
      );

      helpers.setValue(isMulti ? [...field.value, ...files] : files[0]);
    }
  }

  async function onRemove(file) {
    await storage.refFromURL(file.url).delete();

    helpers.setValue(isMulti ? field.value.filter(f => f.ref !== file.ref) : null);
  }

  function renderPreview(file) {
    const { name, url, size } = file;

    return (
      <Media className="align-items-center py-3 border-bottom btn-reveal-trigger" key={url}>
        <img className="img-fluid" width={38} src={url} alt={name} />
        <Media body tag={Flex} justify="between" align="center" className="ml-3">
          <div>
            <h6 data-dz-name="">{name}</h6>
            <Flex className="position-relative" align="center">
              <p className="mb-0 fs--1 text-400 line-height-1">{getSize(size)}</p>
            </Flex>
          </div>
          <UncontrolledDropdown className="text-sans-serif">
            <DropdownToggle color="link" size="sm" className="text-600 btn-reveal">
              <FontAwesomeIcon icon="ellipsis-h" />
            </DropdownToggle>
            <DropdownMenu className="border py-0" right>
              <div className="bg-white py-2">
                <DropdownItem className="text-danger" onClick={() => onRemove(file)}>
                  Remove File
                </DropdownItem>
              </div>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Media>
      </Media>
    );
  }

  return (
    <Fragment>
      <Dropzone onDrop={onDrop}>
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps({
              className: classNames(
                "p-3 border-dashed border-2x border-300 bg-light rounded-soft text-center cursor-pointer",
                className
              ),
            })}
          >
            <input {...{ ...getInputProps(), ...rest }} />
            {placeholder}
          </div>
        )}
      </Dropzone>
      {preview && !isEmpty(field.value) && (
        <div className="border-top mt-3">
          {isMulti ? field.value.map(renderPreview) : renderPreview(field.value)}
        </div>
      )}
      {error && <span className="text-danger fs--1">{error}</span>}
    </Fragment>
  );
};

FormikDropzone.propTypes = {
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  preview: PropTypes.bool,
  isMulti: PropTypes.bool,
  folder: PropTypes.string,
  limit: PropTypes.number,
};

FormikDropzone.defaultProps = {
  placeholder: <img src={cloudUpload} alt="" width={25} className="mr-2" />,
  files: [],
  preview: true,
  isMulti: false,
  limit: 1,
};

export default FormikDropzone;

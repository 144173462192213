import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route, Switch } from "react-router-dom";

import Login from "../../pages/authentication/Login";
import Logout from "../../pages/authentication/Logout";

const AuthRoutes = ({ match: { url } }) => (
  <Switch>
    <Route path={`${url}/login`} component={Login} />
    <Route path={`${url}/logout`} component={Logout} />

    {/*Redirect*/}
    <Redirect to={`${url}/login`} />
  </Switch>
);

AuthRoutes.propTypes = { match: PropTypes.object };

export default AuthRoutes;

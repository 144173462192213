export const eventFactory = () => {
  return {
    title: "",
    image: null,
    start: null,
    end: null,
    venue_short: "",
    venue: "",
    link: "",
    speakers: [],
    description: null,
  };
};

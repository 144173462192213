import React from "react";
import { useDispatch } from "react-redux";

import BannerForm from "../../components/form/BannerForm";
import { createBanner } from "../../redux/actions/banners.actions";

export default function CreateBannerPage() {
  const dispatch = useDispatch();

  function handleSubmit(values) {
    dispatch(createBanner({ banner: values }));
  }

  return <BannerForm title="Tambah Banner" onSubmit={handleSubmit} />;
}

import { push } from "connected-react-router";

import {
  CATEGORY,
  CREATE_CATEGORY,
  FETCH_CATEGORIES,
  FETCH_CATEGORY,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
  setCategories,
  updateCategories,
} from "../../actions/categories.actions";

import {
  DATABASE_FETCH_COLLECTION_SUCCESS,
  DATABASE_FETCH_ITEM_SUCCESS,
  DATABASE_STORE_SUCCESS,
  DATABASE_UPDATE_SUCCESS,
  DATABASE_DELETE_SUCCESS,
  DATABASE_ERROR,
  databaseFetchCollection,
  databaseFetchItem,
  databaseDelete,
  databasePrepareWrite,
} from "../../actions/database.actions";

import { setLoader } from "../../actions/ui.actions";
import { setToast } from "../../actions/toast.actions";
import {
  collectionName as collection,
  categoryFactory as factory,
} from "../../../entities/category";

export const categoriesMiddleware = ({ dispatch }) => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_CATEGORIES:
      next([
        databaseFetchCollection({ collection, feature: CATEGORY }),
        setLoader({ state: true, feature: CATEGORY }),
      ]);
      break;

    case `${CATEGORY} ${DATABASE_FETCH_COLLECTION_SUCCESS}`:
      next([
        setCategories({ categories: action.payload }),
        setLoader({ state: false, feature: CATEGORY }),
      ]);
      break;

    case FETCH_CATEGORY:
      next([
        databaseFetchItem({ path: `${collection}/${action.payload}`, feature: CATEGORY }),
        setLoader({ state: true, feature: CATEGORY }),
      ]);
      break;

    case `${CATEGORY} ${DATABASE_FETCH_ITEM_SUCCESS}`:
      next([
        updateCategories({ category: action.payload }),
        setLoader({ state: false, feature: CATEGORY }),
      ]);
      break;

    case CREATE_CATEGORY:
      next(
        databasePrepareWrite({
          collection,
          factory,
          data: action.payload,
          feature: CATEGORY,
          databaseAction: "create",
        })
      );
      break;

    case `${CATEGORY} ${DATABASE_STORE_SUCCESS}`:
      next([
        setToast({ message: "Berhasil menambahkan kategori", feature: CATEGORY }),
        setLoader({ state: false, feature: CATEGORY }),
      ]);
      dispatch(push("/categories"));
      break;

    case UPDATE_CATEGORY:
      next(
        databasePrepareWrite({
          collection,
          factory,
          data: action.payload,
          feature: CATEGORY,
          databaseAction: "update",
        })
      );
      break;

    case `${CATEGORY} ${DATABASE_UPDATE_SUCCESS}`:
      next([
        setToast({ message: "Berhasil mengupdate kategori", feature: CATEGORY }),
        setLoader({ state: false, feature: CATEGORY }),
      ]);
      dispatch(push("/categories"));
      break;

    case DELETE_CATEGORY:
      next([
        databaseDelete({ collection, id: action.payload, feature: CATEGORY }),
        setLoader({ state: true, feature: CATEGORY }),
      ]);
      break;

    case `${CATEGORY} ${DATABASE_DELETE_SUCCESS}`:
      next([
        setToast({ message: "Berhasil menghapus kategori", feature: CATEGORY }),
        setLoader({ state: false, feature: CATEGORY }),
      ]);
      break;

    case `${CATEGORY} ${DATABASE_ERROR}`:
      next([
        setToast({
          message: action.payload.message.replace(/:.*$/, ""),
          type: "error",
          feature: CATEGORY,
        }),
        setLoader({ state: false, feature: CATEGORY }),
      ]);
      break;

    default:
      break;
  }
};

// action types
export const HTTP_REQUEST = "Http request";
export const HTTP_SUCCESS = "Http success";
export const HTTP_ERROR = "Http error";

// action creators
export const httpRequest = ({ body, functionName, feature }) => ({
  type: `${feature} ${HTTP_REQUEST}`,
  payload: body,
  meta: { functionName, feature },
});

export const httpSuccess = ({ response, feature }) => ({
  type: `${feature} ${HTTP_SUCCESS}`,
  payload: response,
  meta: { feature },
});

export const httpError = ({ error, feature }) => ({
  type: `${feature} ${HTTP_ERROR}`,
  payload: error,
  meta: { feature },
});

import { DELETE_WAQF, SET_WAQFS, UPDATE_WAQFS } from "../actions/waqfs.actions";
import entityReducer from "./entityReducer";

export const waqfsReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_WAQFS:
    case UPDATE_WAQFS:
    case DELETE_WAQF:
      return entityReducer(state, action);

    default:
      return state;
  }
};

// feature selectors
export const getWaqfs = state => Object.values(state.waqfs);
export const getWaqfById = id => state => state.waqfs[id];

// feature name
export const DASHBOARD = `[DASHBOARD]`;

// action types
export const FETCH_LATEST_DONATIONS = `${DASHBOARD} Fetch latest donations`;
export const SET_LATEST_DONATIONS = `${DASHBOARD} Set latest donations`;
export const FETCH_STATS_APP = `${DASHBOARD} Fetch stats app`;
export const SET_STATS_APP = `${DASHBOARD} Set stats app`;

// action creators
export const fetchLatestDonations = () => ({
  type: FETCH_LATEST_DONATIONS,
});

export const setLatestDonations = ({ latestDonations }) => ({
  type: SET_LATEST_DONATIONS,
  payload: latestDonations,
  meta: {
    feature: DASHBOARD,
  },
});

export const fetchStatsApp = () => ({
  type: FETCH_STATS_APP,
});

export const setStatsApp = ({ statsApp }) => ({
  type: SET_STATS_APP,
  payload: statsApp,
});

export const paymentMethodFieldLabels = {
  name: "Nama Metode Pembayaran",
  code: "Kode Metode Pembayaran",
  type: "Jenis Metode Pembayaran",
  logo: "Logo",
  bank_info: {
    name: "Nama Bank",
    account_holder: "Atas Name",
    account_number: "Nomor Rekening",
  },
  instructions: "Instruksi Pembayaran",
};

import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { Collapse, Nav, NavItem, NavLink as BootstrapNavLink } from "reactstrap";

import { setShowBurgerMenu } from "../../redux/actions/ui.actions";
import NavbarVerticalMenuItem from "./NavbarVerticalMenuItem";

const NavbarVerticalMenu = ({ routes, location }) => {
  const [openedIndex, setOpenedIndex] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    let openedDropdown = null;
    routes.forEach((route, index) => {
      if (location.pathname.indexOf(route.to) === 0) openedDropdown = index;
    });

    setOpenedIndex(openedDropdown);
    // eslint-disable-next-line
  }, []);

  function toggleOpened(e, index) {
    e.preventDefault();
    return setOpenedIndex(openedIndex === index ? null : index);
  }

  function hideBurgerMenu() {
    dispatch(setShowBurgerMenu({ state: false }));
  }

  const getHr = name => {
    if (name === "Widgets" || name === "Documentation") {
      return (
        <div className="navbar-vertical-divider">
          <hr className="navbar-vertical-hr my-2" />
        </div>
      );
    }
  };

  return routes.map((route, index) => {
    if (!route.children) {
      return (
        <Fragment key={index}>
          {getHr(route.name)}
          <NavItem>
            <NavLink className="nav-link" {...route} onClick={hideBurgerMenu}>
              <NavbarVerticalMenuItem route={route} />
            </NavLink>
          </NavItem>
        </Fragment>
      );
    }
    return (
      <Fragment key={index}>
        {getHr(route.name)}
        <NavItem>
          <BootstrapNavLink
            onClick={e => toggleOpened(e, index)}
            className="dropdown-indicator cursor-pointer"
            aria-expanded={openedIndex === index}
          >
            <NavbarVerticalMenuItem route={route} />
          </BootstrapNavLink>
          <Collapse isOpen={openedIndex === index}>
            <Nav>
              <NavbarVerticalMenu routes={route.children} location={location} />
            </Nav>
          </Collapse>
        </NavItem>
      </Fragment>
    );
  });
};

NavbarVerticalMenu.propTypes = {
  routes: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(NavbarVerticalMenu);

import { SET_REGIONS } from "../actions/regions.actions";

const initialState = {
  provinces: {},
  regencies: {},
};

export const regionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_REGIONS:
      return { ...state, [action.meta.key]: action.payload };

    default:
      return state;
  }
};

// feature selectors
export const getProvinces = state => Object.values(state.regions.provinces);
export const getRegencies = state => Object.values(state.regions.regencies);

import React from "react";
import PropTypes from "prop-types";
import { Switch, Redirect } from "react-router-dom";

import DashboardLayout from "../../layouts/default";

// Dashboard
import Dashboard from "../../pages/home/Dashboard";

// Categories
import CategoryList from "../../pages/category/CategoryList";
import CreateCategory from "../../pages/category/CreateCategory";
import EditCategory from "../../pages/category/EditCategory";

// Nazir
import NazirList from "../../pages/nazir/NazirList";
import CreateNazir from "../../pages/nazir/CreateNazir";
import EditNazir from "../../pages/nazir/EditNazir";

// Waqf
import WaqfList from "../../pages/waqf/WaqfList";
import DetailWaqf from "../../pages/waqf/DetailWaqf";
import CreateWaqf from "../../pages/waqf/CreateWaqf";
import EditWaqf from "../../pages/waqf/EditWaqf";

// Donation
import DonationList from "../../pages/donation/DonationList";
import DetailDonation from "../../pages/donation/DetailDonation";

// Donor
import DonorList from "../../pages/donor/DonorList";
import DetailDonor from "../../pages/donor/DetailDonor";

// Payment Method
import PaymentMethodList from "../../pages/payment-method/PaymentMethodList";
import CreatePaymentMethod from "../../pages/payment-method/CreatePaymentMethod";
import EditPaymentMethod from "../../pages/payment-method/EditPaymentMethod";

// Notification
import NotificationList from "../../pages/notifications/NotificationList";

// Thread
import ThreadList from "../../pages/thread/ThreadList";
import DetailThread from "../../pages/thread/DetailThread";
import PrivateRoute from "../../containers/route-guard/PrivateRoute";

// Banner
import BannerList from "../../pages/banner/BannerList";
import CreateBanner from "../../pages/banner/CreateBanner";
import EditBanner from "../../pages/banner/EditBanner";

// Event
import EventList from "../../pages/event/EventList";
import DetailEvent from "../../pages/event/DetailEvent";
import CreateEvent from "../../pages/event/CreateEvent";
import EditEvent from "../../pages/event/EditEvent";

// Berita
import NewsList from "../../pages/news/NewsList";
import CreateNews from "../../pages/news/CreateNews";
import EditNews from "../../pages/news/EditNews";

const AppRoutes = ({ match }) => (
  <DashboardLayout>
    <Switch>
      {/* Dashboard */}
      <PrivateRoute path="/" exact component={Dashboard} />

      {/* Notification */}
      <PrivateRoute
        path="/notifications"
        exact
        component={NotificationList}
        roles={["admin", "nazir"]}
      />

      {/* Category */}
      <PrivateRoute path="/categories" exact component={CategoryList} roles={["admin"]} />
      <PrivateRoute path="/categories/create" exact component={CreateCategory} roles={["admin"]} />
      <PrivateRoute path="/categories/:id" exact component={EditCategory} roles={["admin"]} />

      {/* Nazir */}
      <PrivateRoute path="/nazirs" exact component={NazirList} roles={["admin"]} />
      <PrivateRoute path="/nazirs/create" exact component={CreateNazir} roles={["admin"]} />
      <PrivateRoute path="/nazirs/:id" exact component={EditNazir} roles={["admin"]} />

      {/* Waqf */}
      <PrivateRoute path="/waqfs" exact component={WaqfList} roles={["admin", "nazir"]} />
      <PrivateRoute path="/waqfs/create" exact component={CreateWaqf} roles={["admin"]} />
      <PrivateRoute path="/waqfs/edit/:id" exact component={EditWaqf} roles={["admin"]} />
      <PrivateRoute path="/waqfs/:id" exact component={DetailWaqf} roles={["admin", "nazir"]} />

      {/* Donation */}
      <PrivateRoute
        path="/donations"
        exact
        component={DonationList}
        roles={["admin", "nazir", "payment_admin"]}
      />
      <PrivateRoute
        path="/donations/:id"
        component={DetailDonation}
        roles={["admin", "nazir", "payment_admin"]}
      />

      {/* Donor */}
      <PrivateRoute path="/donors" exact component={DonorList} roles={["admin"]} />
      <PrivateRoute path="/donors/:id" component={DetailDonor} roles={["admin"]} />

      {/* Payment Method */}
      <PrivateRoute path="/payment-methods" exact component={PaymentMethodList} roles={["admin"]} />
      <PrivateRoute
        path="/payment-methods/create"
        exact
        component={CreatePaymentMethod}
        roles={["admin"]}
      />
      <PrivateRoute
        path="/payment-methods/:id"
        exact
        component={EditPaymentMethod}
        roles={["admin"]}
      />

      {/* Thread */}
      <PrivateRoute path="/threads" exact component={ThreadList} roles={["admin", "nazir"]} />
      <PrivateRoute path="/threads/:id" component={DetailThread} roles={["admin", "nazir"]} />

      {/* Banner */}
      <PrivateRoute path="/banners" exact component={BannerList} roles={["admin"]} />
      <PrivateRoute path="/banners/create" exact component={CreateBanner} roles={["admin"]} />
      <PrivateRoute path="/banners/:id" component={EditBanner} roles={["admin"]} />

      {/* Event */}
      <PrivateRoute path="/events" exact component={EventList} roles={["admin"]} />
      <PrivateRoute path="/events/create" exact component={CreateEvent} roles={["admin"]} />
      <PrivateRoute path="/events/:id" exact component={DetailEvent} roles={["admin"]} />
      <PrivateRoute path="/events/:id/edit" exact component={EditEvent} roles={["admin"]} />

      {/* Berita */}
      <PrivateRoute path="/news" exact component={NewsList} roles={["admin"]} />
      <PrivateRoute path="/news/create" exact component={CreateNews} roles={["admin"]} />
      <PrivateRoute path="/news/:id/edit" component={EditNews} roles={["admin"]} />

      {/* Redirect to not found */}
      <Redirect to="/errors" />
    </Switch>
  </DashboardLayout>
);

AppRoutes.propTypes = { match: PropTypes.object };

export default AppRoutes;

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, UncontrolledTooltip } from "reactstrap";

import { setVerticalNavCollapsed } from "../../redux/actions/theme.actions";

const ToggleButton = () => {
  const dispatch = useDispatch();
  const { isNavbarVerticalCollapsed } = useSelector(state => state.theme);

  function handleClick() {
    document.getElementsByTagName("html")[0].classList.toggle("navbar-vertical-collapsed");
    dispatch(setVerticalNavCollapsed({ state: !isNavbarVerticalCollapsed }));
  }

  return (
    <>
      <UncontrolledTooltip placement="left" target="toggleNavigationTooltip">
        Toggle Navigation
      </UncontrolledTooltip>
      <div className="toggle-icon-wrapper">
        <Button
          color="link"
          className="navbar-toggler-humburger-icon navbar-vertical-toggle"
          id="toggleNavigationTooltip"
          onClick={handleClick}
        >
          <span className="navbar-toggle-icon">
            <span className="toggle-line" />
          </span>
        </Button>
      </div>
    </>
  );
};

export default ToggleButton;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";

import PageTitle from "../../components/page/PageTitle";
import { fetchLatestDonations, fetchStatsApp } from "../../redux/actions/dashboard";
import { getLatestDonations, getStatsApp } from "../../redux/reducers/dashboard.reducer";
import { fetchDonors } from "../../redux/actions/donors.actions";
import NazirDashboard from "../../containers/dashboard/NazirDashboard";
import AdminDashboard from "../../containers/dashboard/AdminDashboard";

export default function DashboardPage() {
  const dispatch = useDispatch();
  const statsApp = useSelector(getStatsApp);
  const latestDonations = useSelector(getLatestDonations);
  const user = useSelector(state => state.auth);
  const isNazir = isEmpty(user) ? false : user.roles.includes("nazir");

  useEffect(() => {
    dispatch(fetchLatestDonations());
    dispatch(fetchStatsApp());
    dispatch(
      fetchDonors({
        orderBy: [
          ["counter.total_donation", "desc"],
          ["counter.waqfs", "desc"],
          ["counter.donations", "desc"],
        ],
        limit: 5,
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageTitle
        title="Dashboard"
        subtitle="Lorem ipsum dolor sit amet"
        className="mb-4 mt-3"
        icon="chart-pie"
      />

      {isNazir ? (
        <NazirDashboard counter={user.counter} />
      ) : (
        <AdminDashboard statsApp={statsApp} latestDonations={latestDonations} />
      )}
    </>
  );
}

import { HTTP_REQUEST, httpSuccess, httpError } from "../../actions/http.actions";
import { functions } from "../../../singletons/firebase";

export const httpMiddleware = ({ dispatch }) => next => action => {
  next(action);

  if (action.type.includes(HTTP_REQUEST)) {
    const { functionName, feature } = action.meta;
    const httpsCallable = functions.httpsCallable(functionName);

    httpsCallable(action.payload)
      .then(response => {
        if (response.data.status !== 200) {
          throw new Error(response.data);
        }

        dispatch(httpSuccess({ response, feature }));
      })
      .catch(error => dispatch(httpError({ error, feature })));
  }
};

import { THEME } from "../../actions/theme.actions";
import { setItemToStore } from "../../../helpers/utils";

export const themeMiddleware = () => next => action => {
  next(action);

  if (action.type.includes(THEME)) {
    setItemToStore(action.meta.key, action.payload);
  }
};

import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardImg, Col, Media, Row, Spinner } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import parse from "react-html-parser";

import noImage from "../../assets/img/no-image.jpg";
import Calendar from "../../components/common/Calendar";
import FirebaseTimestamp from "../../lib/FirebaseTimestamp";
import ButtonIcon from "../../components/common/ButtonIcon";
import ContentWithAsideLayout from "../../layouts/ContentWithAsideLayout";
import { getEventById } from "../../redux/reducers/events.reducer";
import { deleteEvent, fetchEvent } from "../../redux/actions/events.actions";
import { eventFieldLabels } from "../../entities/event";
import { isIterableArray } from "../../helpers/utils";

const EventDetailContent = ({ event }) => {
  const { description } = event;

  return (
    <Card>
      <CardBody>{parse(description)}</CardBody>
    </Card>
  );
};

export const EventDetailBanner = ({ event }) => {
  const dispatch = useDispatch();
  const { id, image, start, title, speakers, link } = event;
  const startDate = new FirebaseTimestamp(start);

  function handleDelete() {
    confirmAlert({
      title: "Hapus Event",
      message: `Apakah anda yakin ingin menghapus event ${title}`,
      buttons: [
        {
          label: "Ya, Hapus",
          onClick: () => dispatch(deleteEvent({ eventId: id })),
        },
        { label: "Tidak" },
      ],
    });
  }

  return (
    <Card className="mb-3">
      <CardImg top src={image ? image.url : noImage} alt="Card image" />
      <CardBody>
        <Row className="justify-content-between align-items-center">
          <Col>
            <Media>
              <Calendar
                day={startDate.moment.format("DD")}
                month={startDate.moment.format("MMM")}
              />
              <Media body className="fs--1 ml-2">
                <h5 className="fs-0">{title}</h5>
                <p className="mb-0">{eventFieldLabels.speakers}</p>
                {isIterableArray(speakers) &&
                  speakers.map((speaker, index) => (
                    <span key={index}>
                      {speaker} {speakers.length < index ? <span>&#8729;</span> : ""}
                    </span>
                  ))}
              </Media>
            </Media>
          </Col>
          <Col md="auto" className="mt-4 mt-md-0">
            <Link to={`/events/${id}/edit`}>
              <ButtonIcon color="warning" size="sm" className="mr-2" icon="edit">
                Ubah
              </ButtonIcon>
            </Link>
            <ButtonIcon
              color="danger"
              size="sm"
              className="mr-2"
              icon="trash"
              onClick={handleDelete}
            >
              Hapus
            </ButtonIcon>
          </Col>
        </Row>
        {link && (
          <div className="mt-3">
            <a href={link} target="_blank" rel="noreferrer noopener">
              {link}
            </a>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

const EventDetailAside = ({ event }) => {
  const { start, end, venue_short, venue } = event;
  const startDate = new FirebaseTimestamp(start).formatted;
  const endDate = new FirebaseTimestamp(end).formatted;

  return (
    <Fragment>
      <Card className="mb-3 fs--1">
        <CardBody>
          <h6>Date And Time</h6>
          <p className="mb-1">
            {startDate} – <br />
            {endDate}
          </p>
          <h6 className="mt-4">Location</h6>
          <div className="mb-1">
            {venue_short}
            <br />
            {venue}
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default function DetailEventPage({ match }) {
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.ui);
  const eventId = match.params.id;
  const event = useSelector(getEventById(eventId));

  useEffect(() => {
    dispatch(fetchEvent({ eventId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  if (loading || !event) {
    return <Spinner type="grow" color="primary" />;
  }

  return (
    <Fragment>
      <ContentWithAsideLayout
        banner={<EventDetailBanner event={event} />}
        aside={<EventDetailAside event={event} />}
      >
        <EventDetailContent event={event} />
      </ContentWithAsideLayout>
    </Fragment>
  );
}

import moment from "moment";

import FirebaseTimestamp from "../../lib/FirebaseTimestamp";

export * from "./fieldLabels";
export * from "./validation";
export * from "./factory";

// TODO: Refactor this using enrich type redux middleware
export const waqfFormat = waqf => ({
  ...waqf,
  date: moment(new FirebaseTimestamp(waqf.date).format()).toDate(),
  deadline: waqf.deadline ? moment(new FirebaseTimestamp(waqf.deadline).format()).toDate() : null,
});

export const entityName = "Waqf";
export const collectionName = "waqfs";

import React from "react";
import PropTypes from "prop-types";

import Flex from "../../components/common/Flex";
import Dot from "../../components/common/Dot";

const DonationPaymentMethodStatusItem = ({ color, name, value }) => (
  <Flex justify="between" align="center" className="mb-1">
    <Flex align="center">
      <Dot style={{ backgroundColor: color }} />
      <span className="font-weight-semi-bold">{name}</span>
    </Flex>
    <div className="d-xxl-none">{value}</div>
  </Flex>
);

DonationPaymentMethodStatusItem.propsType = {
  color: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

export default DonationPaymentMethodStatusItem;

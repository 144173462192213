import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import CountUp from "react-countup";
import { Card, CardBody } from "reactstrap";
import FalconCardHeader from "../../components/common/FalconCardHeader";
import CardSummary from "../../components/home/CardSummary";
import Money from "../../lib/Money";
import DonationsTable from "../donation/DonationsTable";

const AdminDashboard = ({ statsApp, latestDonations }) => {
  return (
    <Fragment>
      <div className="card-deck">
        <CardSummary title="Donasi" color="warning" linkText="Lihat Semua" to="/donations">
          <CountUp end={statsApp.donations ?? 0} duration={5} separator="," decimal="." />
        </CardSummary>

        <CardSummary title="Total Donasi" color="info" linkText="Lihat Semua" to="/donations">
          <CountUp
            end={statsApp.total_donation ?? 0}
            duration={5}
            prefix="IDR "
            separator=","
            decimal="."
          />
        </CardSummary>
      </div>

      <Card className="mb-3" color="light">
        <CardBody className="p-3">
          <p class="fs--1 mb-0">
            <a href="/#!">
              <FontAwesomeIcon
                icon="exchange-alt"
                className="mr-2"
                style={{
                  transform: "rotate(90deg)",
                }}
              />
              Total keseluruhan Infaq Aplikasi adalah{" "}
              <strong>{new Money(statsApp.total_fee ?? 0).currencyFormat()}</strong>
            </a>
          </p>
        </CardBody>
      </Card>

      <div className="card-deck">
        <CardSummary title="Donatur Terdaftar" color="warning" linkText="Lihat Semua" to="/donors">
          <CountUp end={statsApp.users ?? 0} duration={5} separator="," decimal="." />
        </CardSummary>

        <CardSummary title="Nazir" color="info" linkText="Lihat Semua" to="/nazirs">
          <CountUp end={statsApp.nazirs ?? 0} duration={5} separator="," decimal="." />
        </CardSummary>

        <CardSummary title="Program Wakaf" color="success" linkText="Lihat Semua" to="/waqfs">
          <CountUp end={statsApp.waqfs ?? 0} duration={5} separator="," decimal="." />
        </CardSummary>
      </div>

      <Card>
        <FalconCardHeader title="Donasi Terbaru" light={false}></FalconCardHeader>
        <CardBody className="p-0">
          <DonationsTable donations={latestDonations} />
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default AdminDashboard;

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navbar, Nav, NavItem } from "reactstrap";
import classNames from "classnames";

import Logo from "./Logo";
import SearchBox from "./SearchBox";
import TopNavRightSideNavItem from "./TopNavRightSideNavItem";

import { navbarBreakPoint, topNavbarBreakpoint } from "../../constants/config";
import { setShowBurgerMenu, setNavbarCollapsed } from "../../redux/actions/ui.actions";
import {
  getHasTopNav,
  getHasVerticalNav,
  getHasComboNav,
} from "../../redux/reducers/theme.reducer";

const NavbarTop = () => {
  const dispatch = useDispatch();
  const isTopNav = useSelector(getHasTopNav);
  const isVertical = useSelector(getHasVerticalNav);
  const isCombo = useSelector(getHasComboNav);
  const { showBurgerMenu, navbarCollapsed } = useSelector(state => state.ui);

  function handleBurgerMenu() {
    if (isTopNav && !isCombo) {
      dispatch(setNavbarCollapsed({ state: navbarCollapsed }));
    }

    if (isCombo || isVertical) {
      dispatch(setShowBurgerMenu({ state: !showBurgerMenu }));
    }
  }

  return (
    <Navbar
      light
      className="navbar-glass fs--1 font-weight-semi-bold row navbar-top sticky-kit"
      expand={isTopNav && topNavbarBreakpoint}
    >
      <div
        className={classNames("toggle-icon-wrapper mr-md-3 mr-2", {
          "d-lg-none": isTopNav && !isCombo,
          [`d-${navbarBreakPoint}-none`]: isVertical || isCombo,
        })}
      >
        <button
          className="navbar-toggler-humburger-icon btn btn-link d-flex flex-center"
          onClick={handleBurgerMenu}
          id="burgerMenu"
        >
          <span className="navbar-toggle-icon">
            <span className="toggle-line" />
          </span>
        </button>
      </div>
      <Logo at="navbar-top" width={40} id="topLogo" />
      <Nav navbar className={`align-items-center d-none d-${topNavbarBreakpoint}-block`}>
        <NavItem>
          <SearchBox />
        </NavItem>
      </Nav>
      <TopNavRightSideNavItem />
    </Navbar>
  );
};

export default NavbarTop;

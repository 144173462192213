import React, { useEffect } from "react";
import { useField } from "formik";
import QuillEditor from "react-quill";
import "react-quill/dist/quill.snow.css";

const FormikEditor = props => {
  const [field, meta, helpers] = useField(props);
  const error = meta.touched && meta.error;

  function onChange(value) {
    helpers.setValue(value);
  }

  useEffect(() => {
    if (!field.value) {
      onChange("");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <QuillEditor
        style={{ height: 300, marginBottom: 44 }}
        {...props}
        value={field.value}
        onChange={onChange}
      />
      {error && <span className="text-danger fs--1">{error}</span>}
    </>
  );
};

export default FormikEditor;

export const waqfFieldLabels = {
  title: "Judul Waqf",
  date: "Tanggal Menerbitkan",
  category: "Kategori",
  location: "Lokasi",
  nazir: "Nazir",
  goal: "Target",
  has_deadline: "Memiliki tenggat waktu",
  deadline: "Tenggat waktu",
  thumbnail_image: "Thumbnail Gambar",
  images: "Gambar",
  description: "Deskripsi",
};

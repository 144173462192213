import { string, object, mixed } from "yup";

export const paymentMethodValidation = object().shape({
  name: string().required("Wajib mengisi nama metode pembayaran"),
  code: string().required("Wajib mengisi kode metode pembayaran"),
  type: string().required("Wajib mengisi tipe metode pembayaran"),
  logo: mixed().required("Wajib mengisi logo metode pembayaran"),
  bank_info: object().shape({
    name: string().required("Wajib mengisi nama bank metode pembayaran"),
    account_holder: string().required("Wajib mengisi pemilik akun bank metode pembayaran"),
    account_number: string().required("Wajib mengisi nomor akun bank metode pembayaran"),
  }),
  instructions: mixed().required("Wajib mengisi instruksi metode pembayaran"),
});

export const linkAjaPaymentStatus = [
  { code: "01", status: "PAYMENT" },
  { code: "04", status: "INQUIRY" },
  { code: "03", status: "VOID" },
  { code: "02", status: "REVERSAL" },
  { code: "0001", status: "INITIATE_PGP" },
  { code: "0010", status: "INQUIRY_POST" },
  { code: "0011", status: "INQUIRY_FAILED" },
  { code: "0012", status: "DUPLICATE_REFERENCE" },
  { code: "0020", status: "INQUIRY_POST_FAILED" },
  { code: "1002", status: "TIMEOUT_PAGE" },
  { code: "1000", status: "LANDING" },
  { code: "1002", status: "LANDING_TIMEOUT" },
  { code: "2000", status: "AUTH_PIN_SUCCESS" },
  { code: "2001", status: "AUTH_PIN_FAILED" },
  { code: "2002", status: "AUTH_PIN_TIMEOUT" },
  { code: "2003", status: "AUTH_OTP" },
  { code: "2004", status: "AUTH_OTP_TIMEOUT" },
  { code: "2005", status: "AUTH_CAPTCHA_FAILED" },
  { code: "3000", status: "PAYMENT_SUCCESS" },
  { code: "3001", status: "PAYMENT_FAILED" },
  { code: "3002", status: "PAYMENT_TIMEOUT" },
  { code: "9999", status: "PAYMENT_CANCELLED" },
  { code: "9998", status: "GENERAL_ERROR" },
];

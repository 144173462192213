import { collectionName as collection } from "../../../entities/notifications";
import {
  databaseFetchCollection,
  DATABASE_FETCH_COLLECTION_SUCCESS,
} from "../../actions/database.actions";
import {
  FETCH_NOTIFICATIONS,
  NOTIFICATION,
  setNotifications,
} from "../../actions/notifications.actions";

export const notificationsMiddleware = ({ getState }) => next => action => {
  next(action);

  const { auth } = getState();

  switch (action.type) {
    case FETCH_NOTIFICATIONS:
      const { constraints, orderBy, startAfter, limit } = action.payload;

      next(
        databaseFetchCollection({
          collection: `users/${auth.uid}/${collection}`,
          feature: NOTIFICATION,
          constraints,
          orderBy,
          startAfter,
          limit,
        })
      );
      break;

    case `${NOTIFICATION} ${DATABASE_FETCH_COLLECTION_SUCCESS}`:
      next(setNotifications({ notifications: action.payload }));
      break;

    default:
      break;
  }
};

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import FalconCardHeader from "../../components/common/FalconCardHeader";
import { Badge, Card, CardBody, Col, Row } from "reactstrap";

const DonorStatus = ({ isRegistered = false }) => (
  <Badge color={isRegistered ? "primary" : "info"}>{isRegistered ? "Terdaftar" : "Anonim"}</Badge>
);

DonorStatus.propTypes = {
  isRegistered: PropTypes.bool,
};

const DonationDonorCard = ({ donor = null }) => {
  const [isRegistered, setIsRegistered] = useState(donor ? donor.is_registered : false);

  useEffect(() => {
    if (donor) {
      setIsRegistered(donor.is_registered);
    }
  }, [donor]);

  return (
    <Card className="mb-3">
      <FalconCardHeader title="Detail Pendonor"></FalconCardHeader>

      <CardBody>
        <Row>
          <Col md={5}>
            <p className="font-weight-semi-bold mb-1">Nama</p>
          </Col>
          <Col>{donor ? donor.name : ""}</Col>
        </Row>

        <Row>
          <Col md={5}>
            <p className="font-weight-semi-bold mb-1">Email</p>
          </Col>
          <Col>{donor ? donor.email : ""}</Col>
        </Row>

        <Row>
          <Col md={5}>
            <p className="font-weight-semi-bold mb-1">Phone</p>
          </Col>
          <Col>{donor ? donor.phone : ""}</Col>
        </Row>

        <Row>
          <Col md={5}>
            <p className="font-weight-semi-bold mb-1">Status Pendonor</p>
          </Col>
          <Col>
            <DonorStatus isRegistered={isRegistered} />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

DonationDonorCard.propTypes = {
  donor: PropTypes.object,
};

export default DonationDonorCard;

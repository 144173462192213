import React from "react";
import { useDispatch } from "react-redux";

import NazirForm from "../../components/form/NazirForm";
import { createNazir } from "../../redux/actions/nazirs.actions";

export default function CreateNazirPage() {
  const dispatch = useDispatch();

  function handleSubmit(values) {
    dispatch(createNazir({ nazir: values }));
  }

  return <NazirForm title="Tambah Nazir" onSubmit={handleSubmit} />;
}

import { getItemFromStore } from "../../helpers/utils";
import {
  SET_COLOR,
  SET_TEXT_DIRECTION,
  SET_CONTAINER_LAYOUT,
  SET_NAV_POSITION,
  SET_NAV_STYLE,
  SET_VERTICAL_NAV_COLLAPSED,
} from "../actions/theme.actions";

const initialState = {
  color: getItemFromStore("color", "light"),
  direction: getItemFromStore("direction", "ltr"),
  container: getItemFromStore("container", "container"),
  navPosition: getItemFromStore("navPosition", "vertical"),
  navStyle: getItemFromStore("navStyle", "transparent"),
  isNavbarVerticalCollapsed: getItemFromStore("isNavbarVerticalCollapsed", false),
};

export const themeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COLOR:
    case SET_TEXT_DIRECTION:
    case SET_CONTAINER_LAYOUT:
      return { ...state, [action.meta.key]: action.payload };

    case SET_NAV_POSITION:
      const positions = ["vertical", "top", "combo"];

      if (positions.includes(action.payload)) {
        return { ...state, navPosition: action.payload };
      }

      return state;

    case SET_NAV_STYLE:
      const styles = ["transparent", "inverted", "card", "vibrant"];

      if (styles.includes(action.payload)) {
        return { ...state, navStyle: action.payload };
      }

      return state;

    case SET_VERTICAL_NAV_COLLAPSED:
      return { ...state, isNavbarVerticalCollapsed: action.payload };

    default:
      return state;
  }
};

// feature selectors
export const getIsDark = state => state.theme.color === "dark";
export const getIsRTL = state => state.theme.direction === "rtl";
export const getIsFluid = state => state.theme.container === "container-fluid";
export const getHasTopNav = state => state.theme.navPosition === "top";
export const getHasVerticalNav = state => state.theme.navPosition === "vertical";
export const getHasComboNav = state => state.theme.navPosition === "combo";

import React, { Fragment } from "react";
import { Form, Formik } from "formik";
import { Button, Card, CardBody, CardFooter } from "reactstrap";

import { replyFactory, replyValidation } from "../../entities/reply";
import FormikEditor from "./formik-field/FormikEditor";

const ReplyForm = ({ initialValues = replyFactory(), onSubmit, loading }) => {
  return (
    <Fragment>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, { resetForm }) => {
          await onSubmit(values);
          resetForm();
        }}
        validationSchema={replyValidation}
      >
        {() => (
          <Card>
            <fieldset disabled={loading}>
              <Form>
                <CardBody>
                  <FormikEditor name="body" />
                </CardBody>
                <CardFooter>
                  <Button type="submit" color="primary">
                    Balas
                  </Button>
                </CardFooter>
              </Form>
            </fieldset>
          </Card>
        )}
      </Formik>
    </Fragment>
  );
};

export default ReplyForm;

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import parse from "react-html-parser";
import classNames from "classnames";

import Avatar from "../common/Avatar";

const Notification = ({ to, avatar, title, time, className, unread, flush, emoji, children }) => (
  <Link
    className={classNames(
      "notification",
      { "bg-200": unread, "notification-flush": flush },
      className
    )}
    to={to}
  >
    {avatar && (
      <div className="notification-avatar">
        <Avatar {...avatar} useImage className="mr-3" />
      </div>
    )}
    <div className="notification-body">
      {title && <strong>{title}</strong>}
      <p className={emoji ? "mb-1" : "mb-0"}>{parse(children)}</p>
      <span className="notification-time">
        {emoji && (
          <span className="mr-1" role="img" aria-label="Emoji">
            {emoji}
          </span>
        )}
        {time}
      </span>
    </div>
  </Link>
);

Notification.propTypes = {
  to: PropTypes.string,
  title: PropTypes.string,
  avatar: PropTypes.shape(Avatar.propTypes),
  time: PropTypes.string,
  className: PropTypes.string,
  unread: PropTypes.bool,
  flush: PropTypes.bool,
  emoji: PropTypes.string,
  children: PropTypes.node,
};

Notification.defaultProps = { to: "", title: "", unread: false, flush: false, time: "" };

export default Notification;

import { get } from "lodash";
import moment from "moment";
import "moment/locale/id";

moment.locale("id");

export default class FirebaseTimestamp {
  timestamp;
  unixTime;
  moment;

  constructor(timestamp) {
    this.timestamp = timestamp;
    this.unixTime = get(timestamp, "seconds", 0) * 1000;
    this.moment = moment(this.unixTime);
  }

  get formatted() {
    if (!this.timestamp) {
      return "";
    }

    return this.format("D MMMM YYYY HH:mm");
  }

  get fromNow() {
    if (!this.timestamp) {
      return "";
    }

    const now = new moment();

    return moment.duration(this.moment.diff(now)).humanize(true);
  }

  format(format) {
    if (!this.timestamp) {
      return "";
    }

    return this.moment.format(format);
  }
}

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Card, CardBody, Col, Row, Spinner } from "reactstrap";
import parse from "react-html-parser";

import FalconCardHeader from "../../components/common/FalconCardHeader";
import { fetchEvents } from "../../redux/actions/events.actions";
import { getEvents } from "../../redux/reducers/events.reducer";
import { isIterableArray } from "../../helpers/utils";
import EventSummary from "../../containers/event/EventSummary";
import { Link } from "react-router-dom";
import ButtonIcon from "../../components/common/ButtonIcon";

export default function EventListPage() {
  const dispatch = useDispatch();
  const events = useSelector(getEvents);
  const { loading } = useSelector(state => state.ui);

  useEffect(() => {
    dispatch(fetchEvents());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card>
      <FalconCardHeader title="Events" light={false}>
        <Link to="/events/create">
          <ButtonIcon icon="plus" transform="shrink-3 down-2" color="falcon-default" size="sm">
            Tambah
          </ButtonIcon>
        </Link>
      </FalconCardHeader>
      <CardBody className="fs--1">
        {loading ? (
          <Spinner color="primary" />
        ) : isIterableArray(events) ? (
          <Row>
            {events.map(({ description, ...rest }, index) => (
              <Col md={6} className="h-100" key={index}>
                <EventSummary divider={events.length !== index + 1} {...rest}>
                  <div className="text-1000 mb-0">{parse(description)}</div>
                </EventSummary>
              </Col>
            ))}
          </Row>
        ) : (
          <Alert color="info" className="mb-0">
            Tidak event!
          </Alert>
        )}
      </CardBody>
    </Card>
  );
}

// action types
export const FETCH_USER = `Fetch a user`;
export const SET_USERS = `Set users`;
export const UPDATE_USERS = `Update an item in users`;
export const CREATE_USER = `Create user`;
export const UPDATE_USER = `Update a user`;
export const DELETE_USER = `Delete a user`;

export const fetchUser = ({ userId, feature, collection = "users" }) => ({
  type: FETCH_USER,
  payload: userId,
  meta: { feature, collection },
});

export const updateUser = ({ user, feature, collection, factory }) => ({
  type: UPDATE_USER,
  payload: user,
  meta: { feature, collection, factory },
});

export const deleteUser = ({ userId, feature, collection }) => ({
  type: DELETE_USER,
  payload: userId,
  meta: { feature, collection },
});

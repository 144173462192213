import React, { useEffect } from "react";
import { useField } from "formik";
import Datetime from "react-datetime";
import classnames from "classnames";
import { isEmpty } from "lodash";
import moment, { isMoment } from "moment";

const FormikDatetime = props => {
  const [field, meta, helpers] = useField(props);
  const error = meta.touched && meta.error;

  function onChange(value) {
    helpers.setValue(isEmpty(value) || !isMoment(value) ? null : value.toDate());
  }

  function renderInput(props) {
    return (
      <input {...props} className={classnames({ "border-danger": !!error }, props.className)} />
    );
  }

  useEffect(() => {
    // create moment from form value
    const temp = moment(field.value);

    // check if value is moment, if not set value to empty string
    // this prevent react-datetime set invalid date
    if (!temp.isValid()) {
      onChange(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Datetime
        closeOnSelect={true}
        {...field}
        {...props}
        onChange={onChange}
        renderInput={renderInput}
      />
      {error && <span className="text-danger fs--1">{error}</span>}
    </>
  );
};

export default FormikDatetime;

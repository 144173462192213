import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Media } from "reactstrap";

import FirebaseTimestamp from "../../lib/FirebaseTimestamp";
import Calendar from "../../components/common/Calendar";
import { eventFieldLabels } from "../../entities/event";
import { isIterableArray } from "../../helpers/utils";

const EventSummary = ({
  id,
  title,
  start,
  end,
  speakers,
  venue_short,
  venue,
  link,
  divider,
  children,
}) => {
  const startDate = new FirebaseTimestamp(start);
  const endDate = new FirebaseTimestamp(end);

  return (
    <Media>
      <Calendar day={startDate.moment.format("DD")} month={startDate.moment.format("MMM")} />
      <Media body className="position-relative pl-3">
        <h6 className="fs-0 mb-0">
          <Link to={`/events/${id}`}>{title}</Link>
        </h6>
        <p className="mb-1">{eventFieldLabels.speakers}</p>
        <ul>
          {isIterableArray(speakers) &&
            speakers.map((speaker, index) => (
              <li key={index}>
                <p className="text-1000 mb-0">{speaker}</p>
              </li>
            ))}
        </ul>
        <p className="text-1000 mb-0">
          {eventFieldLabels.start}: {startDate.formatted}
        </p>
        <p className="text-1000 mb-1">
          {eventFieldLabels.end}: {endDate.formatted}
        </p>

        {children}
        <div>{venue && <span>{venue}</span>}</div>
        <a href={link}>{link}</a>
        {divider && <hr className="border-dashed border-bottom-0" />}
      </Media>
    </Media>
  );
};

EventSummary.propTypes = {
  title: PropTypes.string.isRequired,
  location: PropTypes.string,
  children: PropTypes.node,
  divider: PropTypes.bool,
};

EventSummary.defaultProps = { divider: true };

export default EventSummary;

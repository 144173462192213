import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route, Switch } from "react-router-dom";

import ErrorLayout from "../../layouts/error";
import Error403 from "../../pages/errors/403";
import Error404 from "../../pages/errors/404";
import Error500 from "../../pages/errors/500";

const ErrorRoutes = ({ match: { url } }) => (
  <ErrorLayout>
    <Switch>
      <Route path={`${url}/403`} component={Error403} />
      <Route path={`${url}/404`} component={Error404} />
      <Route path={`${url}/500`} component={Error500} />

      {/*Redirect*/}
      <Redirect to={`${url}/404`} />
    </Switch>
  </ErrorLayout>
);

ErrorRoutes.propTypes = { match: PropTypes.object };

export default ErrorRoutes;

import { push } from "connected-react-router";

import {
  NEWS,
  CREATE_NEWS,
  FETCH_NEWS,
  FETCH_SINGLE_NEWS,
  UPDATE_NEWS,
  DELETE_NEWS,
  setNewsState,
  updateNewsState,
} from "../../actions/news.actions";

import {
  DATABASE_FETCH_COLLECTION_SUCCESS,
  DATABASE_FETCH_ITEM_SUCCESS,
  DATABASE_STORE_SUCCESS,
  DATABASE_UPDATE_SUCCESS,
  DATABASE_DELETE_SUCCESS,
  DATABASE_ERROR,
  databaseFetchCollection,
  databaseFetchItem,
  databaseDelete,
  databasePrepareWrite,
} from "../../actions/database.actions";

import { setLoader } from "../../actions/ui.actions";
import { setToast } from "../../actions/toast.actions";
import { collectionName as collection, newsFactory as factory } from "../../../entities/news";

export const newsMiddleware = ({ dispatch }) => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_NEWS:
      next([
        databaseFetchCollection({ collection, feature: NEWS }),
        setLoader({ state: true, feature: NEWS }),
      ]);
      break;

    case `${NEWS} ${DATABASE_FETCH_COLLECTION_SUCCESS}`:
      next([setNewsState({ news: action.payload }), setLoader({ state: false, feature: NEWS })]);
      break;

    case FETCH_SINGLE_NEWS:
      next([
        databaseFetchItem({ path: `${collection}/${action.payload}`, feature: NEWS }),
        setLoader({ state: true, feature: NEWS }),
      ]);
      break;

    case `${NEWS} ${DATABASE_FETCH_ITEM_SUCCESS}`:
      next([updateNewsState({ news: action.payload }), setLoader({ state: false, feature: NEWS })]);
      break;

    case CREATE_NEWS:
      next(
        databasePrepareWrite({
          collection,
          factory,
          data: action.payload,
          feature: NEWS,
          databaseAction: "create",
        })
      );
      break;

    case `${NEWS} ${DATABASE_STORE_SUCCESS}`:
      next([
        setToast({ message: "Berhasil menambahkan berita", feature: NEWS }),
        setLoader({ state: false, feature: NEWS }),
      ]);
      dispatch(push("/news"));
      break;

    case UPDATE_NEWS:
      next(
        databasePrepareWrite({
          collection,
          factory,
          data: action.payload,
          feature: NEWS,
          databaseAction: "update",
        })
      );
      break;

    case `${NEWS} ${DATABASE_UPDATE_SUCCESS}`:
      next([
        setToast({ message: "Berhasil mengupdate berita", feature: NEWS }),
        setLoader({ state: false, feature: NEWS }),
      ]);
      dispatch(push("/news"));
      break;

    case DELETE_NEWS:
      next([
        databaseDelete({ collection, id: action.payload, feature: NEWS }),
        setLoader({ state: true, feature: NEWS }),
      ]);
      break;

    case `${NEWS} ${DATABASE_DELETE_SUCCESS}`:
      next([
        setToast({ message: "Berhasil menghapus berita", feature: NEWS }),
        setLoader({ state: false, feature: NEWS }),
      ]);
      break;

    case `${NEWS} ${DATABASE_ERROR}`:
      next([
        setToast({
          message: action.payload.message.replace(/:.*$/, ""),
          type: "error",
          feature: NEWS,
        }),
        setLoader({ state: false, feature: NEWS }),
      ]);
      break;

    default:
      break;
  }
};

import {
  databaseFetchCollection,
  DATABASE_FETCH_COLLECTION_SUCCESS,
  DATABASE_FETCH_ITEM_SUCCESS,
} from "../../actions/database.actions";
import {
  DONOR,
  FETCH_DONOR,
  FETCH_DONORS,
  setDonors,
  updateDonors,
} from "../../actions/donors.actions";
import { collectionName as collection } from "../../../entities/donor";
import { setLoader } from "../../actions/ui.actions";
import { fetchUser } from "../../actions/users.actions";

export const donorsMiddleware = ({ dispatch }) => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_DONORS:
      const { constraints, orderBy, limit } = action.meta;

      next([
        databaseFetchCollection({
          collection,
          feature: DONOR,
          constraints: [["roles", "array-contains", "user"], ...constraints],
          orderBy,
          limit,
        }),
        setLoader({ state: true, feature: DONOR }),
      ]);
      break;

    case `${DONOR} ${DATABASE_FETCH_COLLECTION_SUCCESS}`:
      next([setDonors({ donors: action.payload }), setLoader({ state: false, feature: DONOR })]);
      break;

    case FETCH_DONOR:
      dispatch(fetchUser({ userId: action.payload, feature: DONOR, collection }));
      break;

    case `${DONOR} ${DATABASE_FETCH_ITEM_SUCCESS}`:
      next([updateDonors({ donor: action.payload }), setLoader({ state: false, feature: DONOR })]);
      break;

    default:
      break;
  }
};

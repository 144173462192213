import React from "react";
import { Card, CardBody, Col, FormGroup, Label, Row } from "reactstrap";

import FalconCardHeader from "../../components/common/FalconCardHeader";
import FormikDatetime from "../../components/form/formik-field/FormikDatetime";
import FormikEditor from "../../components/form/formik-field/FormikEditor";
import FormikInput from "../../components/form/formik-field/FormikInput";
import { eventFieldLabels } from "../../entities/event";

const EventDetailsForm = () => {
  return (
    <Card className="mb-3">
      <FalconCardHeader title="Detail Event" light={false} />
      <CardBody className="bg-light">
        <FormGroup>
          <Label for="title">{eventFieldLabels.title}</Label>
          <FormikInput name="title" placeholder="Judul Event" />
        </FormGroup>
        <Row form>
          <Col sm tag={FormGroup}>
            <Label for="start">{eventFieldLabels.start}</Label>
            <FormikDatetime name="start" />
          </Col>

          <Col sm tag={FormGroup}>
            <Label for="end">{eventFieldLabels.end}</Label>
            <FormikDatetime name="end" />
          </Col>
        </Row>
        <Row form>
          <Col sm tag={FormGroup}>
            <Label for="venue_short">{eventFieldLabels.venue_short}</Label>
            <FormikInput name="venue_short" placeholder="Venue" />
          </Col>

          <Col sm tag={FormGroup}>
            <Label for="link">{eventFieldLabels.link}</Label>
            <FormikInput name="link" placeholder={eventFieldLabels.link} />
          </Col>
        </Row>

        <Row form>
          <Col sm>
            <Label for="venue">{eventFieldLabels.venue}</Label>
            <FormikInput
              name="venue"
              type="textarea"
              rows={6}
              placeholder={eventFieldLabels.venue}
            />
          </Col>
        </Row>

        <Row form>
          <Col sm>
            <Label for="description">{eventFieldLabels.description}</Label>
            <FormikEditor name="description" />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default EventDetailsForm;

import React, { createRef, Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Progress, Row } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import { confirmAlert } from "react-confirm-alert";

import FirebaseTimestamp from "../../lib/FirebaseTimestamp";
import Money from "../../lib/Money";

const goalFormatter = (goal, row) => {
  const goalInt = Number(goal);
  const totalDonation = row.counter ? row.counter.total_donation : 0;
  const percentage = (totalDonation / goalInt) * 100;

  return (
    <Fragment>
      <Progress value={percentage} />
      {new Money(totalDonation).currencyFormat()} of {new Money(goalInt).currencyFormat()}
    </Fragment>
  );
};

const donationsFormatter = (donations, row) => {
  return <Link to={`/donations?waqf=${row.id}`}>{donations || 0}</Link>;
};

const columns = [
  {
    dataField: "date",
    text: "Tanggal Publikasi",
    formatter: date => new FirebaseTimestamp(date).format("YYYY/MM/DD"),
    classes: "border-0 align-middle",
    headerClasses: "border-0",
    headerStyle: {
      minWidth: "100px",
    },
    sort: true,
  },
  {
    dataField: "title",
    text: "Judul",
    classes: "border-0 align-middle",
    headerClasses: "border-0",
    headerStyle: {
      minWidth: "250px",
    },
    sort: true,
  },
  {
    dataField: "category.name",
    text: "Kategori",
    classes: "border-0 align-middle",
    headerClasses: "border-0",
    headerStyle: {
      minWidth: "150px",
    },
    sort: true,
  },
  {
    dataField: "nazir.name",
    text: "Nazir",
    classes: "border-0 align-middle",
    headerClasses: "border-0",
    headerStyle: {
      minWidth: "150px",
    },
    sort: true,
  },
  {
    dataField: "goal",
    text: "Goal",
    formatter: goalFormatter,
    classes: "border-0 align-middle",
    headerClasses: "border-0",
    headerStyle: {
      minWidth: "300px",
    },
    sort: true,
  },
  {
    dataField: "counter.donations",
    text: "Jumlah Donasi",
    formatter: donationsFormatter,
    classes: "border-0 align-middle",
    headerClasses: "border-0",
    headerStyle: {
      minWidth: "100px",
    },
    sort: true,
  },
  {
    dataField: "deadline",
    text: "Deadline",
    formatter: date => new FirebaseTimestamp(date).fromNow,
    classes: "border-0 align-middle",
    headerClasses: "border-0",
    headerStyle: {
      minWidth: "150px",
    },
    sort: true,
  },
  {
    dataField: "action",
    classes: "border-0 align-middle text-right",
    headerClasses: "border-0",
    text: "",
  },
];

function appendAction(waqf) {
  return {
    ...waqf,
    action: <WaqfAction waqf={waqf} />,
  };
}

const WaqfAction = ({ waqf }) => {
  function handleDelete() {
    confirmAlert({
      title: "Hapus Kategori",
      message: `Apakah anda yakin ingin menghapus kategori ${waqf.title}`,
      buttons: [
        {
          label: "Ya, Hapus",
          onClick: () => null,
        },
        { label: "Tidak" },
      ],
    });
  }

  return (
    <div className="d-flex">
      <Link to={`/waqfs/${waqf.id}`}>
        <Button color="link" size="sm" className="text-600 btn-reveal mr-1">
          <FontAwesomeIcon icon="eye" className="fs--1 text-info" />
        </Button>
      </Link>

      <Link to={`/waqfs/edit/${waqf.id}`}>
        <Button color="link" size="sm" className="text-600 btn-reveal mr-1">
          <FontAwesomeIcon icon="edit" className="fs--1 text-warning" />
        </Button>
      </Link>

      <Button color="link" size="sm" className="text-600 btn-reveal" onClick={handleDelete}>
        <FontAwesomeIcon icon="trash" className="fs--1 text-danger" />
      </Button>
    </div>
  );
};

const WaqfsTable = ({ waqfs }) => {
  let table = createRef();

  return (
    <PaginationProvider
      pagination={paginationFactory({
        custom: true,
        totalSize: waqfs.length,
        sizePerPage: waqfs.length,
      })}
    >
      {({ paginationProps, paginationTableProps }) => (
        <Fragment>
          <div className="table-responsive">
            <BootstrapTable
              ref={table}
              bootstrap4
              keyField="id"
              data={waqfs.map(appendAction)}
              columns={columns}
              bordered={false}
              classes="table-dashboard table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
              rowClasses="btn-reveal-trigger border-top border-200"
              headerClasses="bg-200 text-900 border-y border-200"
              noDataIndication="Tidak ada data waqf"
              {...paginationTableProps}
            />
          </div>
          <Row noGutters className="px-1 py-3">
            <Col className="pl-3 fs--1">
              <span>{paginationProps.totalSize} Wakaf</span>
            </Col>
          </Row>
        </Fragment>
      )}
    </PaginationProvider>
  );
};

WaqfsTable.propTypes = {
  waqfs: PropTypes.array,
};

WaqfsTable.defaultProps = {
  waqfs: [],
};

export default WaqfsTable;

import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Row } from "reactstrap";
import { get } from "lodash";

import FalconCardHeader from "../../components/common/FalconCardHeader";

const DonationWaqfCard = ({ waqf }) => {
  return (
    <Card className="mb-3">
      <FalconCardHeader title="Informasi Waqf"></FalconCardHeader>

      <CardBody>
        <Row>
          <Col md={5}>
            <p className="font-weight-semi-bold mb-1">Waqf ID</p>
          </Col>
          <Col>{get(waqf, "id", "")}</Col>
        </Row>

        <Row>
          <Col md={5}>
            <p className="font-weight-semi-bold mb-1">Nama Waqf</p>
          </Col>
          <Col>{get(waqf, "title", "")}</Col>
        </Row>

        <Row>
          <Col md={5}>
            <p className="font-weight-semi-bold mb-1">Nazir</p>
          </Col>
          <Col>{get(waqf, "nazir_name", "")}</Col>
        </Row>
      </CardBody>
    </Card>
  );
};

DonationWaqfCard.propTypes = {
  waqf: PropTypes.object,
};

export default DonationWaqfCard;

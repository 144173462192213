import { replyFactory as factory } from "../../../entities/reply";
import { collectionName as threadCollection } from "../../../entities/thread";
import {
  databaseDelete,
  databaseFetchCollection,
  databasePrepareWrite,
  DATABASE_DELETE_SUCCESS,
  DATABASE_FETCH_COLLECTION_SUCCESS,
  DATABASE_STORE_SUCCESS,
} from "../../actions/database.actions";
import { setToast } from "../../actions/toast.actions";
import {
  CREATE_REPLY,
  deleteReplies,
  DELETE_REPLY,
  FETCH_REPLIES,
  REPLY,
  setReplies,
  updateReplies,
} from "../../actions/replies.actions";
import { setLoader } from "../../actions/ui.actions";
import { formatFirestore } from "../core/database";

export const repliesMiddleware = () => next => async action => {
  next(action);

  switch (action.type) {
    case FETCH_REPLIES:
      next([
        databaseFetchCollection({
          collection: `${threadCollection}/${action.payload}/replies`,
          feature: REPLY,
          orderBy: [["created_at", "asc"]],
        }),
        setLoader({ state: true, feature: REPLY }),
      ]);
      break;

    case `${REPLY} ${DATABASE_FETCH_COLLECTION_SUCCESS}`:
      next([
        setReplies({ replies: action.payload }),
        setLoader({
          state: false,
          feature: REPLY,
        }),
      ]);
      break;

    case CREATE_REPLY:
      next(
        databasePrepareWrite({
          data: action.payload,
          feature: REPLY,
          databaseAction: "create",
          collection: `${threadCollection}/${action.payload.thread_id}/replies`,
          factory,
        })
      );
      break;

    case `${REPLY} ${DATABASE_STORE_SUCCESS}`:
      var snap = await action.payload.get();

      next([
        updateReplies({ replies: formatFirestore(snap) }),
        setToast({ message: "Berhasil menambahkan balasan", feature: REPLY }),
        setLoader({ state: false, feature: REPLY }),
      ]);
      break;

    case DELETE_REPLY:
      next(databaseDelete({ id: action.payload, feature: REPLY }));
      break;

    case `${REPLY} ${DATABASE_DELETE_SUCCESS}`:
      next([
        deleteReplies({ replyId: action.payload.id }),
        setToast({ message: "Berhasil menghapus balasan", feature: REPLY }),
        setLoader({ state: false, feature: REPLY }),
      ]);
      break;

    default:
      break;
  }
};

import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyAJM2a3DjfJY9OSJQiSh65z7mjem_Y8QMA",
  authDomain: "wakaf-yuk.firebaseapp.com",
  databaseURL: "https://wakaf-yuk.firebaseio.com",
  projectId: "wakaf-yuk",
  storageBucket: "wakaf-yuk.appspot.com",
  messagingSenderId: "1067808225677",
  appId: "1:1067808225677:web:de1498152e0a37268325c4",
  measurementId: "G-VS6DP4CYE5",
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

const auth = firebase.auth();
const db = firebase.firestore();
const fieldPath = firebase.firestore.FieldPath;
const storage = firebase.storage();
const functions = firebase.app().functions("asia-northeast1");

if (window.location.hostname === "localhost") {
  auth.useEmulator("http://localhost:9099/");
  db.useEmulator("localhost", 8080);
  functions.useEmulator("localhost", 5001);
  storage.useEmulator("localhost", 9199);
}

export { auth, db, storage, functions, fieldPath };

import React, { Fragment } from "react";
import { Form, Formik } from "formik";

import { eventFactory, eventValidation } from "../../entities/event";
import ContentWithAsideLayout from "../../layouts/ContentWithAsideLayout";
import EventCreateAside from "./EventCreateAside";
import EventCreateBanner from "./EventCreateBanner";
import EventCreateContent from "./EventCreateContent";
import EventCreateFooter from "./EventCreateFooter";

const EventForm = ({ initialValues = eventFactory(), loading, onSubmit }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={eventValidation}
      onSubmit={onSubmit}
    >
      {({ values }) => (
        <Fragment>
          <Form>
            <fieldset disabled={loading}>
              <ContentWithAsideLayout
                banner={<EventCreateBanner name="image" />}
                aside={<EventCreateAside speakers={values.speakers} />}
                footer={<EventCreateFooter />}
              >
                <EventCreateContent />
              </ContentWithAsideLayout>
            </fieldset>
          </Form>
        </Fragment>
      )}
    </Formik>
  );
};

export default EventForm;

import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";

import ThreadCard from "../../containers/thread/ThreadCard";
import { replyFactory } from "../../entities/reply";
import { createReply, fetchReplies } from "../../redux/actions/replies.actions";
import { fetchThread } from "../../redux/actions/threads.actions";
import { getReplies } from "../../redux/reducers/replies.reducer";
import { getThreadById } from "../../redux/reducers/threads.reducer";
import ReplyForm from "../../components/form/ReplyForm";

export default function DetailThreadPage({ match }) {
  const threadId = match.params.id;
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const user = {
    id: auth.uid,
    name: auth.displayName,
    photo: auth.photoURL,
    //
  };
  const thread = useSelector(getThreadById(threadId));
  const replies = useSelector(getReplies);
  const { loading } = useSelector(state => state.ui);

  function handleReply(values) {
    dispatch(createReply({ reply: values }));
  }

  useEffect(() => {
    dispatch(fetchThread({ threadId }));
    dispatch(fetchReplies({ threadId }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [threadId]);

  if (loading || !thread) {
    return <h1>Loading</h1>;
  }

  return (
    <Fragment>
      <ThreadCard
        replyId={thread.id}
        creator={thread.op}
        {...thread.counter}
        title={thread.title}
        body={thread.body}
        createdAt={thread.created_at}
        hideDelete
      />

      {!isEmpty(replies) && <h4 className="mt-4">Balasan</h4>}

      {replies.map(reply => (
        <ThreadCard
          key={reply.id}
          replyId={reply.id}
          creator={reply.user}
          body={reply.body}
          createdAt={reply.created_at}
          likes={reply.counter ? reply.counter.likes : 0}
          hideReplies
          hideDelete
        />
      ))}

      <ReplyForm
        loading={loading}
        initialValues={{ ...replyFactory(), thread_id: threadId, user }}
        onSubmit={handleReply}
      />
    </Fragment>
  );
}

export const eventFieldLabels = {
  title: "Judul Event",
  image: "Gambar Event",
  start: "Waktu Mulai",
  end: "Waktu Selesai",
  venue_short: "Lokasi Ringkas Event",
  venue: "Lokasi Event",
  link: "Link Event",
  speakers: "Pemateri / Pengisi Acara",
  description: "Deskripsi Event",
};

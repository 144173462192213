import React from "react";
import { useDispatch } from "react-redux";

import CategoryForm from "../../components/form/CategoryForm";
import { createCategory } from "../../redux/actions/categories.actions";

export default function CreateCategoryPage() {
  const dispatch = useDispatch();

  function handleSubmit(values) {
    dispatch(createCategory({ category: values }));
  }

  return <CategoryForm title="Tambah Kategori" onSubmit={handleSubmit} />;
}

export const paymentMethodFactory = () => {
  return {
    name: "",
    code: "",
    type: "transfer",
    logo: null,
    bank_info: {
      name: "",
      account_holder: "",
      account_number: "",
    },
    instructions: "",
  };
};

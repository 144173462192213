import { isEmpty } from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

import ButtonIcon from "../../components/common/ButtonIcon";
import FalconCardHeader from "../../components/common/FalconCardHeader";
import WaqfsTable from "../../containers/waqf/WaqfsTable";
import { fetchWaqfs } from "../../redux/actions/waqfs.actions";
import { getWaqfs } from "../../redux/reducers/waqfs.reducer";

export default function WaqfListPage() {
  const dispatch = useDispatch();
  const waqfs = useSelector(getWaqfs);
  const user = useSelector(state => state.auth);
  const isNazir = isEmpty(user) ? false : user.roles.includes("nazir");

  useEffect(() => {
    dispatch(fetchWaqfs());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card className="mb-3">
      <FalconCardHeader title="Wakaf" light={false}>
        {!isNazir && (
          <Link to="/waqfs/create">
            <ButtonIcon icon="plus" transform="shrink-3 down-2" color="falcon-default" size="sm">
              Tambah
            </ButtonIcon>
          </Link>
        )}
      </FalconCardHeader>
      <CardBody className="p-0">
        <WaqfsTable waqfs={waqfs} />
      </CardBody>
    </Card>
  );
}

import { SET_THREADS, UPDATE_THREADS } from "../actions/threads.actions";
import entityReducer from "./entityReducer";

export const threadsReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_THREADS:
    case UPDATE_THREADS:
      return entityReducer(state, action);

    default:
      return state;
  }
};

// feature selectors
export const getThreads = state => Object.values(state.threads);
export const getThreadById = id => state => state.threads[id];

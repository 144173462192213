import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import PaymentMethodForm from "../../components/form/PaymentMethodForm";
import {
  fetchPaymentMethod,
  updatePaymentMethod,
} from "../../redux/actions/paymentMethods.actions";
import { getPaymentMethod } from "../../redux/reducers/paymentMethods.reducer";

export default function EditPaymentMethodPage({ match }) {
  const dispatch = useDispatch();
  const paymentMethodId = match.params.id;
  const paymentMethod = useSelector(getPaymentMethod(paymentMethodId));
  const { loading } = useSelector(state => state.ui);

  useEffect(() => {
    dispatch(fetchPaymentMethod({ paymentMethodId }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethodId]);

  function handleSubmit(values) {
    dispatch(updatePaymentMethod({ paymentMethod: values }));
  }

  if (loading || !paymentMethod) {
    return <h1>Loading</h1>;
  }

  return (
    <Fragment>
      <PaymentMethodForm
        title="Edit Metode Pembayaran"
        initialValues={paymentMethod}
        onSubmit={handleSubmit}
      />
    </Fragment>
  );
}

import { push } from "connected-react-router";

import {
  collectionName as collection,
  donationFactory as factory,
} from "../../../entities/donation";

import {
  databaseDelete,
  databaseFetchCollection,
  databaseFetchItem,
  databasePrepareWrite,
  DATABASE_DELETE_SUCCESS,
  DATABASE_ERROR,
  DATABASE_FETCH_COLLECTION_SUCCESS,
  DATABASE_FETCH_ITEM_SUCCESS,
  DATABASE_UPDATE_SUCCESS,
} from "../../actions/database.actions";

import {
  DELETE_DONATION,
  DONATION,
  FETCH_DONATION,
  FETCH_DONATIONS,
  setDonations,
  updateDonations,
  UPDATE_DONATION,
} from "../../actions/donations.actions";

import { setToast } from "../../actions/toast.actions";
import { setLoader } from "../../actions/ui.actions";

export const donationsMiddleware = ({ dispatch, getState }) => next => action => {
  next(action);

  const user = getState().auth;

  switch (action.type) {
    case FETCH_DONATIONS:
      const { constraints: metaContraints } = action.meta;
      let constraints = [...metaContraints];

      if (user.roles && user.roles.includes("nazir")) {
        constraints = [...constraints, ["waqf.nazir_id", "==", user.id]];
      }

      next([
        databaseFetchCollection({ collection, constraints, feature: DONATION }),
        setLoader({ state: true, feature: DONATION }),
      ]);
      break;

    case `${DONATION} ${DATABASE_FETCH_COLLECTION_SUCCESS}`:
      next([
        setDonations({ donations: action.payload }),
        setLoader({ state: false, feature: DONATION }),
      ]);
      break;

    case FETCH_DONATION:
      next([
        databaseFetchItem({ path: `${collection}/${action.payload}`, feature: DONATION }),
        setLoader({ state: true, feature: DONATION }),
      ]);
      break;

    case `${DONATION} ${DATABASE_FETCH_ITEM_SUCCESS}`:
      next([
        updateDonations({ donation: action.payload }),
        setLoader({ state: false, feature: DONATION }),
      ]);
      break;

    case UPDATE_DONATION:
      next([
        databasePrepareWrite({
          collection,
          factory,
          data: action.payload,
          feature: DONATION,
          databaseAction: "update",
        }),
      ]);
      break;

    case `${DONATION} ${DATABASE_UPDATE_SUCCESS}`:
      next([
        setToast({ message: "Berhasil mengupdate donasi", feature: DONATION }),
        setLoader({ state: false, feature: DONATION }),
      ]);
      dispatch(push("/donations"));
      break;

    case DELETE_DONATION:
      next([
        databaseDelete({ collection, id: action.payload, feature: DONATION }),
        setLoader({ state: true, feature: DONATION }),
      ]);
      break;

    case `${DONATION} ${DATABASE_DELETE_SUCCESS}`:
      next([
        setToast({ message: "Berhasil menghapus donation", feature: DONATION }),
        setLoader({ state: false, feature: DONATION }),
      ]);
      break;

    case `${DONATION} ${DATABASE_ERROR}`:
      next([
        setToast({
          message: action.payload.message.replace(/:.*$/, ""),
          type: "error",
          feature: DONATION,
        }),
        setLoader({ state: false, feature: DONATION }),
      ]);
      break;

    default:
      break;
  }
};

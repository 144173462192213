import { entityName } from "../../entities/thread";

// feature name
export const THREAD = `[${entityName}]`;

// action types
export const FETCH_THREADS = `${THREAD} Fetch threads`;
export const FETCH_THREAD = `${THREAD} Fetch a thread`;
export const SET_THREADS = `${THREAD} Set threads`;
export const UPDATE_THREADS = `${THREAD} Update an item in threads`;

// action creators
export const fetchThreads = () => ({
  type: FETCH_THREADS,
});

export const setThreads = ({ threads }) => ({
  type: SET_THREADS,
  payload: threads,
  meta: {
    feature: THREAD,
  },
});

export const fetchThread = ({ threadId }) => ({
  type: FETCH_THREAD,
  payload: threadId,
  meta: {
    feature: THREAD,
  },
});

export const updateThreads = ({ thread }) => ({
  type: UPDATE_THREADS,
  payload: thread,
  meta: {
    feature: THREAD,
  },
});

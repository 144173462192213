import React, { Fragment, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Spinner } from "reactstrap";
import InfiniteScroll from "react-infinite-scroll-component";

import FalconCardHeader from "../../components/common/FalconCardHeader";
import Notification from "../../components/notification/Notification";
import { fetchNotifications } from "../../redux/actions/notifications.actions";
import { getNotifications } from "../../redux/reducers/notifications.reducer";
import { fieldPath } from "../../singletons/firebase";

export default function NotificationListPage() {
  const dispatch = useDispatch();
  const notifications = useSelector(getNotifications);
  let countRef = useRef();

  function fetchData() {
    dispatch(
      fetchNotifications({
        constraints: [["read", "==", false]],
        orderBy: [[fieldPath.documentId()]],
        startAfter: notifications[notifications.length - 1].id,
        limit: 10,
      })
    );
  }

  useEffect(() => {
    countRef.current = notifications.length;
  }, [notifications]);

  useEffect(() => {
    countRef.current = 0;
  }, []);

  return (
    <Fragment>
      <Card>
        <FalconCardHeader title="Notifikasi Anda" light />

        <CardBody className="p-0">
          <InfiniteScroll
            dataLength={notifications.length}
            hasMore={notifications.length !== countRef.current}
            next={fetchData}
            loader={
              <div className="text-center py-3">
                <Spinner type="grow" color="dark" />
              </div>
            }
            endMessage={
              <p className="text-center">
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            {notifications.map(notif => (
              <Notification
                key={notif.id}
                title={notif.title}
                avatar={{ src: notif.image, icon: "bell" }}
                unread={!notif.read}
                className="notification rounded-0 border-x-0 border-300 border-bottom-0"
              >
                {notif.body}
              </Notification>
            ))}
          </InfiniteScroll>
        </CardBody>
      </Card>
    </Fragment>
  );
}

import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Card, CardBody, CardFooter, Col, FormGroup, Label, Row } from "reactstrap";
import { Form, Formik } from "formik";
import { isEmpty } from "lodash";

import { waqfFactory, waqfFieldLabels, waqfValidation } from "../../entities/waqf";
import { getCategories } from "../../redux/reducers/categories.reducer";
import { getNazirs } from "../../redux/reducers/nazirs.reducer";
import { getProvinces, getRegencies } from "../../redux/reducers/regions.reducer";
import FalconCardHeader from "../common/FalconCardHeader";
import FormikCurrencyFormat from "./formik-field/FormikCurrencyFormat";
import FormikDatetime from "./formik-field/FormikDatetime";
import FormikDropzone from "./formik-field/FormikDropzone";
import FormikInput from "./formik-field/FormikInput";
import FormikSelect from "./formik-field/FormikSelect";
import FormikSwitch from "./formik-field/FormikSwitch";
import FormikEditor from "./formik-field/FormikEditor";

const WaqfForm = ({ title, initialValues = waqfFactory(), onSubmit, isNazir = false }) => {
  const categories = useSelector(getCategories);
  const nazirs = useSelector(getNazirs);
  const provinces = useSelector(getProvinces);
  const regencies = useSelector(getRegencies);

  function getRegenciesByProvince(province) {
    if (isEmpty(province)) {
      return [];
    }

    return regencies.filter(regency => regency.province_code === province.id);
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={waqfValidation(isNazir)}
    >
      {({ setFieldValue, values }) => (
        <Form>
          <Card className="mb-3">
            <FalconCardHeader title={title} />
            <CardBody>
              <FormGroup>
                <Label>{waqfFieldLabels.title}</Label>
                <FormikInput name="title" placeholder={waqfFieldLabels.title} />
              </FormGroup>
              <FormGroup>
                <Label>{waqfFieldLabels.date}</Label>
                <FormikDatetime name="date" placeholder={waqfFieldLabels.date} />
              </FormGroup>
              <FormGroup>
                <Label>{waqfFieldLabels.category}</Label>
                <FormikSelect
                  name="category"
                  placeholder={waqfFieldLabels.category}
                  options={categories}
                />
              </FormGroup>
              <FormGroup>
                <Label>{waqfFieldLabels.location}</Label>
                <Row>
                  <Col>
                    <FormikSelect
                      name="location.province"
                      placeholder={"Province"}
                      options={provinces}
                    />
                  </Col>
                  <Col>
                    <FormikSelect
                      name="location.regency"
                      placeholder={"Regency"}
                      options={getRegenciesByProvince(values.location.province)}
                    />
                  </Col>
                </Row>
              </FormGroup>
              {!isNazir && (
                <FormGroup>
                  <Label>{waqfFieldLabels.nazir}</Label>
                  <FormikSelect name="nazir" placeholder={waqfFieldLabels.nazir} options={nazirs} />
                </FormGroup>
              )}
              <FormGroup>
                <Label>{waqfFieldLabels.goal}</Label>
                <FormikCurrencyFormat name="goal" placeholder={waqfFieldLabels.goal} />
              </FormGroup>
              <FormGroup>
                <Label>{waqfFieldLabels.has_deadline}</Label>
                <br />
                <FormikSwitch
                  name="has_deadline"
                  onChange={value => {
                    setFieldValue("has_deadline", value);

                    if (!value) {
                      setFieldValue("deadline", null);
                    }
                  }}
                />
              </FormGroup>
              {values.has_deadline && (
                <FormGroup>
                  <Label>{waqfFieldLabels.deadline}</Label>
                  <FormikDatetime name="deadline" placeholder={waqfFieldLabels.deadline} />
                </FormGroup>
              )}
              <FormGroup>
                <Label>{waqfFieldLabels.thumbnail_image}</Label>
                <FormikDropzone name="thumbnail_image" accept="image/*" />
              </FormGroup>
              <FormGroup>
                <Label>{waqfFieldLabels.images}</Label>
                <FormikDropzone name="images" isMulti limit={8} accept="image/*" />
              </FormGroup>
              <FormGroup>
                <Label>{waqfFieldLabels.description}</Label>
                <FormikEditor name="description" />
              </FormGroup>
            </CardBody>
            <CardFooter className="d-flex justify-content-end bg-light">
              <Link to="/waqfs">
                <Button type="button" color="falcon-default" size="sm" className="mr-2">
                  Batal
                </Button>
              </Link>

              <Button type="submit" color="falcon-primary" size="sm">
                Simpan
              </Button>
            </CardFooter>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default WaqfForm;

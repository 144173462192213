import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import { CloseButton, Fade } from "../common/Toast";
import AuthRoutesGuard from "../../containers/route-guard/PublicRoute";

import AppRoutes from "./AppRoutes";
import ErrorRoutes from "./ErrorRoutes";
import AuthRoutes from "./AuthRoutes";

const Routes = () => {
  return (
    <>
      <Switch>
        <Route path="/errors" component={ErrorRoutes} />
        <AuthRoutesGuard path="/auth" component={AuthRoutes} />
        <Route path="/" component={AppRoutes} />
        <Redirect to="/errors" />
      </Switch>
      <ToastContainer
        transition={Fade}
        closeButton={<CloseButton />}
        position={toast.POSITION.TOP_RIGHT}
      />
    </>
  );
};

export default Routes;

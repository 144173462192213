import React, { useEffect, useState } from "react";
import { reduce, get } from "lodash";
import { Card, CardBody, Col, Row } from "reactstrap";
import { useSelector } from "react-redux";
import ReactEchartsCore from "echarts-for-react/lib/core";
import echarts from "echarts/lib/echarts";
import "echarts/lib/chart/pie";

import {
  isIterableArray,
  numberFormatter,
  randomColor,
  getOptionWaqfPie,
} from "../../helpers/utils";
import { getPaymentMethods } from "../../redux/reducers/paymentMethods.reducer";
import DonationPaymentMethodStatusItem from "./DonationPaymentMethodStatusItem";

const DonationPaymentMethod = ({ waqf }) => {
  const [paymentMethods, setPaymentMethods] = useState(useSelector(getPaymentMethods));
  const donationByPaymentMethod = get(waqf, "counter.donation_by_payment_method", {});
  const totalPaymentMethod = donationByPaymentMethod
    ? reduce(
        donationByPaymentMethod,
        function (sum, value) {
          return sum + value;
        },
        0
      )
    : 0;

  useEffect(() => {
    setPaymentMethods(
      paymentMethods.map(o => ({
        ...o,
        color: randomColor(),
        value: donationByPaymentMethod[o.id],
      }))
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card className="h-md-100">
      <CardBody>
        <Row noGutters className="h-100 justify-content-between">
          <Col xs={5} sm={6} className="col-xxl pr-2">
            <h6 className="mt-1">Metode Pembayaran Donasi</h6>
            <div className="fs--2 mt-3">
              {isIterableArray(paymentMethods) &&
                paymentMethods.map(paymentMethod => (
                  <DonationPaymentMethodStatusItem
                    key={paymentMethod.id}
                    {...paymentMethod}
                    totalPaymentMethod={totalPaymentMethod}
                  />
                ))}
            </div>
          </Col>
          <Col xs="auto">
            <div className="position-relative">
              <ReactEchartsCore
                echarts={echarts}
                option={getOptionWaqfPie(paymentMethods, false)}
                style={{ width: "6.625rem", height: "6.625rem" }}
              />
              <div className="absolute-centered font-weight-medium text-dark fs-2">
                {numberFormatter(totalPaymentMethod, 0)}
              </div>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default DonationPaymentMethod;

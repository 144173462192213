import React, { createRef, Fragment } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import Avatar from "../../components/common/Avatar";

import FirebaseTimestamp from "../../lib/FirebaseTimestamp";
import Money from "../../lib/Money";

const profileFormatter = (name, row) => {
  return (
    <div className="d-flex align-items-center">
      <Avatar src={row.image ? row.image.url : ""} useImage />
      <div className="pl-2">{name}</div>
    </div>
  );
};

const donationsFormatter = (donations, row) => (
  <Fragment>
    <Link to={`/donations?donor=${row.id}`}>{donations || 0} transaksi</Link>
  </Fragment>
);

const columns = [
  {
    dataField: "name",
    text: "Name Donatur",
    formatter: profileFormatter,
    classes: "border-0 align-middle pr-4",
    headerClasses: "border-0",
    headerStyle: { minWidth: "250px" },
    sort: true,
  },
  {
    dataField: "email",
    text: "Email",
    classes: "border-0 align-middle pr-4",
    headerClasses: "border-0",
    headerStyle: { minWidth: "150px" },
    sort: true,
  },
  {
    dataField: "counter.donations",
    text: "Total Transaksi",
    formatter: donationsFormatter,
    classes: "border-0 align-middle pr-4",
    headerClasses: "border-0",
    sort: true,
  },
  {
    dataField: "counter.total_donation",
    text: "Total Donasi",
    formatter: donation => new Money(donation).currencyFormat(),
    classes: "border-0 align-middle pr-4",
    headerClasses: "border-0",
    headerStyle: { minWidth: "150px" },
    sort: true,
  },
  {
    dataField: "created_at",
    text: "Terdaftar Sejak",
    formatter: time => new FirebaseTimestamp(time).formatted,
    classes: "border-0 align-middle pr-4",
    headerClasses: "border-0",
    sort: true,
  },
  {
    dataField: "action",
    classes: "border-0 align-middle text-right pr-4",
    headerClasses: "border-0",
    text: "",
  },
];

function appendAction(donor) {
  return {
    ...donor,
    action: <DonorAction donor={donor} />,
  };
}

const DonorAction = ({ donor }) => (
  <Fragment>
    <Link to={`/donors/${donor.id}`}>
      <Button color="link" size="sm" className="text-600 btn-reveal mr-1">
        <FontAwesomeIcon icon="eye" className="fs--1 text-info" />
      </Button>
    </Link>
  </Fragment>
);

const DonorsTable = ({ donors }) => {
  let table = createRef();

  return (
    <PaginationProvider
      pagination={paginationFactory({
        custom: true,
        totalSize: donors.length,
        sizePerPage: donors.length,
      })}
    >
      {({ paginationProps, paginationTableProps }) => {
        return (
          <Fragment>
            <div className="table-responsive">
              <BootstrapTable
                ref={table}
                bootstrap4
                keyField="id"
                data={donors.map(appendAction)}
                columns={columns}
                bordered={false}
                classes="table-dashboard table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
                rowClasses="btn-reveal-trigger border-top border-200"
                headerClasses="bg-200 text-900 border-y border-200"
                noDataIndication="Tidak ada data donor"
                {...paginationTableProps}
              />
            </div>
            <Row noGutters className="px-1 py-3">
              <Col className="pl-3 fs--1">
                <span>{paginationProps.totalSize} Donatur</span>
              </Col>
            </Row>
          </Fragment>
        );
      }}
    </PaginationProvider>
  );
};

DonorsTable.propTypes = {
  donors: PropTypes.array.isRequired,
};

export default DonorsTable;

import React, { Fragment } from "react";

import EventDetailsForm from "./EventDetailsForm";

const EventCreateContent = () => {
  return (
    <Fragment>
      <EventDetailsForm />
    </Fragment>
  );
};

export default EventCreateContent;

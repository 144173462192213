import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

import FalconCardHeader from "../../components/common/FalconCardHeader";
import ButtonIcon from "../../components/common/ButtonIcon";
import NazirsTable from "../../containers/nazir/NazirsTable";

export default function NazirListPage() {
  return (
    <Card className="mb-3">
      <FalconCardHeader title="Nazir" light={false}>
        <Link to="/nazirs/create">
          <ButtonIcon icon="plus" transform="shrink-3 down-2" color="falcon-default" size="sm">
            Tambah
          </ButtonIcon>
        </Link>
      </FalconCardHeader>
      <CardBody className="p-0">
        <NazirsTable />
      </CardBody>
    </Card>
  );
}

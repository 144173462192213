import { object, string, mixed, number } from "yup";

export const waqfReportValidation = amountCollected =>
  object().shape({
    category: mixed().required("Wajib memilih kategori laporan"),
    title: string().required("Wajib mengisi judul"),
    amount: number()
      .max(amountCollected, "Jumlah pencairan tidak boleh melebihi donasi yang terkumpul")
      .required("Wajib mengisi jumlah pencairan"),
    description: mixed().required("Wajib mengisi deskripsi"),
    images: mixed().required("Wajib mengisi galeri"),
    date: string().required("Wajib mengisi tanggal"),
  });

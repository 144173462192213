import React from "react";
import { useField } from "formik";
import { Input } from "reactstrap";
import classnames from "classnames";

const FormikInput = props => {
  const [field, meta] = useField(props);
  const error = meta.touched && meta.error;

  return (
    <>
      <Input {...field} {...props} className={classnames({ "border-danger": !!error })} />
      {error && <span className="text-danger fs--1">{error}</span>}
    </>
  );
};

export default FormikInput;

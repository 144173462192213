import { collectionName as donations } from "../../../entities/donation";
import {
  DASHBOARD,
  FETCH_LATEST_DONATIONS,
  FETCH_STATS_APP,
  setLatestDonations,
  setStatsApp,
} from "../../actions/dashboard";
import {
  databaseFetchCollection,
  databaseFetchItem,
  DATABASE_FETCH_COLLECTION_SUCCESS,
  DATABASE_FETCH_ITEM_SUCCESS,
} from "../../actions/database.actions";
import { setLoader } from "../../actions/ui.actions";

export const dashboardMiddleware = ({ dispatch }) => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_LATEST_DONATIONS:
      next([
        databaseFetchCollection({
          collection: donations,
          feature: DASHBOARD,
          constraints: [["status", "!=", "abandoned"]],
          orders: [["created_at", "desc"]],
          limit: 20,
        }),
        setLoader({
          state: true,
          feature: DASHBOARD,
        }),
      ]);
      break;

    case `${DASHBOARD} ${DATABASE_FETCH_COLLECTION_SUCCESS}`:
      next([
        setLatestDonations({ latestDonations: action.payload }),
        setLoader({
          state: false,
          feature: DASHBOARD,
        }),
      ]);
      break;

    case FETCH_STATS_APP:
      next(databaseFetchItem({ path: "stats/app", feature: DASHBOARD }));
      break;

    case `${DASHBOARD} ${DATABASE_FETCH_ITEM_SUCCESS}`:
      next(setStatsApp({ statsApp: action.payload }));
      break;

    default:
      break;
  }
};

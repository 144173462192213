import {
  DELETE_PAYMENT_METHOD,
  SET_PAYMENT_METHODS,
  UPDATE_PAYMENT_METHODS,
} from "../actions/paymentMethods.actions";
import entityReducer from "./entityReducer";

export const paymentMethodsReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_PAYMENT_METHODS:
    case UPDATE_PAYMENT_METHODS:
    case DELETE_PAYMENT_METHOD:
      return entityReducer(state, action);

    default:
      return state;
  }
};

// feature selectors
export const getPaymentMethods = state => Object.values(state.paymentMethods);
export const getPaymentMethod = id => state => state.paymentMethods[id];

import { entityName } from "../../entities/waqf-report";

// feature name
export const WAQF_REPORT = `[${entityName}]`;

// action types
export const FETCH_WAQF_REPORTS = `${WAQF_REPORT} Fetch waqf reports`;
export const FETCH_WAQF_REPORT = `${WAQF_REPORT} Fetch a waqf report`;
export const SET_WAQF_REPORTS = `${WAQF_REPORT} Set waqf reports`;
export const UPDATE_WAQF_REPORTS = `${WAQF_REPORT} Update an item in waqf reports`;
export const CREATE_WAQF_REPORT = `${WAQF_REPORT} Create waqf report`;
export const UPDATE_WAQF_REPORT = `${WAQF_REPORT} Update a waqf report`;
export const DELETE_WAQF_REPORT = `${WAQF_REPORT} Delete a waqf report`;

// action creators
export const fetchWaqfReports = ({ waqfId }) => ({
  type: FETCH_WAQF_REPORTS,
  payload: waqfId,
});

export const setWaqfReports = ({ waqfReports }) => ({
  type: SET_WAQF_REPORTS,
  payload: waqfReports,
});

export const fetchWaqfReport = ({ waqfId, waqfReportId }) => ({
  type: FETCH_WAQF_REPORT,
  payload: {
    waqfId,
    waqfReportId,
  },
});

export const updateWaqfReports = ({ waqfReport }) => ({
  type: UPDATE_WAQF_REPORTS,
  payload: waqfReport,
});

export const createWaqfReport = ({ waqfId, waqfReport }) => ({
  type: CREATE_WAQF_REPORT,
  payload: {
    waqfId,
    waqfReport,
  },
});

export const updateWaqfReport = ({ waqfId, waqfReport }) => ({
  type: UPDATE_WAQF_REPORT,
  payload: {
    waqfId,
    waqfReport,
  },
});

export const deleteWaqfReport = ({ waqfId, waqfReportId }) => ({
  type: DELETE_WAQF_REPORT,
  payload: {
    waqfId,
    waqfReportId,
  },
});

import React from "react";
import { Button, Card, CardBody, CardFooter, FormGroup, Label } from "reactstrap";
import { Field, Form, Formik } from "formik";
import { Link } from "react-router-dom";

import {
  paymentMethodFactory,
  paymentMethodFieldLabels,
  paymentMethodValidation,
} from "../../entities/payment-methods";
import FalconCardHeader from "../common/FalconCardHeader";
import FormikInput from "./formik-field/FormikInput";
import FormikDropzone from "./formik-field/FormikDropzone";
import FormikEditor from "./formik-field/FormikEditor";

const PaymentMethodForm = ({ initialValues = paymentMethodFactory(), title, onSubmit }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={paymentMethodValidation}
    >
      {() => (
        <Form>
          <Card className="mb-3">
            <FalconCardHeader title={title} />
            <CardBody>
              {/* name */}
              <FormGroup>
                <Label>{paymentMethodFieldLabels.name}</Label>
                <FormikInput name="name" placeholder={paymentMethodFieldLabels.name} />
              </FormGroup>
              {/* code */}
              <FormGroup>
                <Label>{paymentMethodFieldLabels.code}</Label>
                <FormikInput name="code" placeholder={paymentMethodFieldLabels.code} />
              </FormGroup>
              {/* type */}
              <FormGroup>
                <Label>{paymentMethodFieldLabels.type}</Label>
                <FormGroup check>
                  <Label check>
                    <Field type="radio" name="type" value="transfer" className="form-check-input" />{" "}
                    Transfer
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Field
                      type="radio"
                      name="type"
                      value="virtual_account"
                      className="form-check-input"
                    />{" "}
                    Virtual Account
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Field
                      type="radio"
                      name="type"
                      value="digital_wallet"
                      className="form-check-input"
                    />{" "}
                    Digital Wallet
                  </Label>
                </FormGroup>
              </FormGroup>
              {/* logo */}
              <FormGroup>
                <Label>{paymentMethodFieldLabels.logo}</Label>
                <FormikDropzone name="logo" accept="image/*" />
              </FormGroup>
              {/* bank_info.name */}
              <FormGroup>
                <Label>{paymentMethodFieldLabels.bank_info.name}</Label>
                <FormikInput
                  name="bank_info.name"
                  placeholder={paymentMethodFieldLabels.bank_info.name}
                />
              </FormGroup>
              {/* bank_info.account_holder */}
              <FormGroup>
                <Label>{paymentMethodFieldLabels.bank_info.account_holder}</Label>
                <FormikInput
                  name="bank_info.account_holder"
                  placeholder={paymentMethodFieldLabels.bank_info.account_holder}
                />
              </FormGroup>
              {/* bank_info.account_number */}
              <FormGroup>
                <Label>{paymentMethodFieldLabels.bank_info.account_number}</Label>
                <FormikInput
                  name="bank_info.account_number"
                  placeholder={paymentMethodFieldLabels.bank_info.account_number}
                />
              </FormGroup>
              {/* instructions */}
              <FormGroup>
                <Label>{paymentMethodFieldLabels.instructions}</Label>
                <FormikEditor name="instructions" />
              </FormGroup>
            </CardBody>

            <CardFooter className="d-flex justify-content-end bg-light">
              <Link to="/payment-methods">
                <Button type="button" color="falcon-default" size="sm" className="mr-2">
                  Batal
                </Button>
              </Link>

              <Button type="submit" color="falcon-primary" size="sm">
                Simpan
              </Button>
            </CardFooter>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default PaymentMethodForm;

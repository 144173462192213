import { DELETE_REPLIES, SET_REPLIES, UPDATE_REPLIES } from "../actions/replies.actions";
import entityReducer from "./entityReducer";

export const repliesReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_REPLIES:
    case UPDATE_REPLIES:
    case DELETE_REPLIES:
      return entityReducer(state, action);

    default:
      return state;
  }
};

// feature selectors
export const getReplies = state => Object.values(state.replies);

import { get } from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Row } from "reactstrap";
import FalconCardHeader from "../../components/common/FalconCardHeader";
import { getLinkAjaPaymentStatusText } from "../../helpers/linkAja";
import Money from "../../lib/Money";
import { checkPaymentStatus, setPaymentStatus } from "../../redux/actions/linkAja.actions";
import { getPaymentStatus } from "../../redux/reducers/linkAja.reducer";

const DonationLinkAjaCard = ({ donationId }) => {
  const dispatch = useDispatch();
  const paymentStatus = useSelector(getPaymentStatus);

  useEffect(() => {
    dispatch(checkPaymentStatus({ donationId }));

    return () => {
      dispatch(setPaymentStatus({ payment: {} }));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [donationId]);

  return (
    <Card>
      <FalconCardHeader title="Detail Pembayaran LinkAja"></FalconCardHeader>

      <CardBody>
        <Row>
          <Col md={5}>
            <p className="font-weight-semi-bold mb-1">Ref Num</p>
          </Col>
          <Col>{get(paymentStatus, "refNum", "")}</Col>
        </Row>

        <Row>
          <Col md={5}>
            <p className="font-weight-semi-bold mb-1">Terminal ID</p>
          </Col>
          <Col>{get(paymentStatus, "terminalId", "")}</Col>
        </Row>

        <Row>
          <Col md={5}>
            <p className="font-weight-semi-bold mb-1">LinkAja Trx ID</p>
          </Col>
          <Col>{get(paymentStatus, "linkAjaTrxId", "")}</Col>
        </Row>

        <Row>
          <Col md={5}>
            <p className="font-weight-semi-bold mb-1">Jumlah</p>
          </Col>
          <Col>{new Money(get(paymentStatus, "amount", "")).currencyFormat()}</Col>
        </Row>

        <Row>
          <Col md={5}>
            <p className="font-weight-semi-bold mb-1">Tanggal</p>
          </Col>
          <Col>{get(paymentStatus, "transactionDate", "")}</Col>
        </Row>

        <Row>
          <Col md={5}>
            <p className="font-weight-semi-bold mb-1">Status</p>
          </Col>
          <Col>{getLinkAjaPaymentStatusText(get(paymentStatus, "status", ""))}</Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default DonationLinkAjaCard;

import { entityName } from "../../entities/donor";

// feature name
export const DONOR = `[${entityName}]`;

// action types
export const FETCH_DONORS = `${DONOR} Fetch donors`;
export const FETCH_DONOR = `${DONOR} Fetch a donor`;
export const SET_DONORS = `${DONOR} Set donors`;
export const UPDATE_DONORS = `${DONOR} Update an item in donors`;

// action creators
export const fetchDonors = ({ constraints = [], orderBy = [], limit = 0 } = {}) => ({
  type: FETCH_DONORS,
  meta: {
    constraints,
    orderBy,
    limit,
  },
});

export const fetchDonor = ({ donorId }) => ({
  type: FETCH_DONOR,
  payload: donorId,
});

export const setDonors = ({ donors }) => ({
  type: SET_DONORS,
  payload: donors,
  meta: {
    feature: DONOR,
  },
});

export const updateDonors = ({ donor }) => ({
  type: UPDATE_DONORS,
  payload: donor,
  feature: {
    DONOR,
  },
});

import { capitalize, isEmpty, pick } from "lodash";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { storage } from "../singletons/firebase";

export const getPageName = pageName => {
  return window.location.pathname.split("/").slice(-1)[0] === pageName;
};

export const getItemFromStore = (key, defaultValue, store = localStorage) => {
  return JSON.parse(store.getItem(key)) || defaultValue;
};

export const setItemToStore = (key, payload, store = localStorage) => {
  store.setItem(key, JSON.stringify(payload));
};

export const isIterableArray = array => {
  return Array.isArray(array) && !!array.length;
};

export const sanitizeForm = (values, factory) => {
  const fields = Object.keys(factory());

  return pick(values, ["id", ...fields]);
};

export const randomString = () => {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
};

export const generateFileName = () => {
  return `${new Date().getTime()}_${randomString()}`;
};

export const storageFolder = intended => {
  const isLocal = window.location.hostname === "localhost";

  if (isLocal) return "local_uploads";

  if (isEmpty(intended)) return "upload";

  return intended;
};

export const uploadFile = async (file, folder, filename = generateFileName()) => {
  const extension = file.name.split(".").pop();
  const storageRef = storage.ref().child(`${storageFolder(folder)}/${filename}.${extension}`);
  const uploadTask = await storageRef.put(file, {
    contentType: file.type,
    cacheControl: "public,max-age=31536000",
    customMetadata: {
      name: file.name,
      path: file.path,
    },
  });

  return {
    url: await uploadTask.ref.getDownloadURL(),
    name: file.name,
    size: file.size,
    type: file.type,
    ref: uploadTask.ref.fullPath,
  };
};

export const numberFormatter = (number, fixed = 2) => {
  // Nine Zeroes for Billions
  return Math.abs(Number(number)) >= 1.0e9
    ? (Math.abs(Number(number)) / 1.0e9).toFixed(fixed) + "B"
    : // Six Zeroes for Millions
    Math.abs(Number(number)) >= 1.0e6
    ? (Math.abs(Number(number)) / 1.0e6).toFixed(fixed) + "M"
    : // Three Zeroes for Thousands
    Math.abs(Number(number)) >= 1.0e3
    ? (Math.abs(Number(number)) / 1.0e3).toFixed(fixed) + "K"
    : Math.abs(Number(number)).toFixed(fixed);
};

//===============================
// Colors
//===============================
export const hexToRgb = hexValue => {
  let hex;
  hexValue.indexOf("#") === 0 ? (hex = hexValue.substring(1)) : (hex = hexValue);
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
    hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)
  );
  return result
    ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)]
    : null;
};

export const rgbColor = (color = colors[0]) => `rgb(${hexToRgb(color)})`;
export const rgbaColor = (color = colors[0], alpha = 0.5) => `rgba(${hexToRgb(color)},${alpha})`;

export const colors = [
  "#2c7be5",
  "#00d97e",
  "#e63757",
  "#39afd1",
  "#fd7e14",
  "#02a8b5",
  "#727cf5",
  "#6b5eae",
  "#ff679b",
  "#f6c343",
];

export const themeColors = {
  primary: "#2c7be5",
  secondary: "#748194",
  success: "#00d27a",
  info: "#27bcfd",
  warning: "#f5803e",
  danger: "#e63757",
  light: "#f9fafd",
  dark: "#0b1727",
};

export const grays = {
  white: "#fff",
  100: "#f9fafd",
  200: "#edf2f9",
  300: "#d8e2ef",
  400: "#b6c1d2",
  500: "#9da9bb",
  600: "#748194",
  700: "#5e6e82",
  800: "#4d5969",
  900: "#344050",
  1000: "#232e3c",
  1100: "#0b1727",
  black: "#000",
};

export const randomColor = () => "#" + Math.floor(Math.random() * 16777215).toString(16);

export const darkGrays = {
  white: "#fff",
  1100: "#f9fafd",
  1000: "#edf2f9",
  900: "#d8e2ef",
  800: "#b6c1d2",
  700: "#9da9bb",
  600: "#748194",
  500: "#5e6e82",
  400: "#4d5969",
  300: "#344050",
  200: "#232e3c",
  100: "#0b1727",
  black: "#000",
};

export const getGrays = isDark => (isDark ? darkGrays : grays);

export const rgbColors = colors.map(color => rgbColor(color));
export const rgbaColors = colors.map(color => rgbaColor(color));

//===============================
// Echarts
//===============================
export const getPosition = (pos, params, dom, rect, size) => ({
  top: pos[1] - size.contentSize[1] - 10,
  left: pos[0] - size.contentSize[0] / 2,
});
export const getOptionTotalDonation = (month, data) => {
  const grays = getGrays(false);

  const endOfMonth = moment().month(month).clone().endOf("month");
  const dates = Array.from({ length: endOfMonth.date() }, (_, i) => i + 1);
  const values = dates.map(date => {
    const theDate = moment().month(month).date(date);
    if (theDate.isBefore(moment())) {
      return isEmpty(data[theDate.format("YYYY-MM-DD")])
        ? 0
        : data[theDate.format("YYYY-MM-DD")].donors.length;
    } else {
      return undefined;
    }
  });

  return {
    tooltip: {
      trigger: "axis",
      padding: [7, 10],
      backgroundColor: grays.white,
      borderColor: grays["300"],
      borderWidth: 1,
      textStyle: { color: themeColors.dark },
      formatter(params) {
        return getFormatter(params, month);
      },
      transitionDuration: 0,
      position(pos, params, dom, rect, size) {
        return getPosition(pos, params, dom, rect, size);
      },
    },
    xAxis: {
      type: "category",
      data: dates,
      boundaryGap: false,
      axisPointer: {
        lineStyle: {
          color: grays["300"],
          type: "dashed",
        },
      },
      splitLine: { show: false },
      axisLine: {
        lineStyle: {
          color: rgbaColor("#000", 0.01),
          type: "dashed",
        },
      },
      axisTick: { show: false },
      axisLabel: {
        color: grays["400"],
        formatter: function (value) {
          return `${capitalize(month.slice(0, 3))} ${value}`;
        },
        margin: 15,
      },
    },
    yAxis: {
      type: "value",
      axisPointer: { show: false },
      splitLine: {
        lineStyle: {
          color: grays["300"],
          type: "dashed",
        },
      },
      boundaryGap: false,
      axisLabel: {
        show: true,
        color: grays["400"],
        margin: 15,
      },
      axisTick: { show: false },
      axisLine: { show: false },
    },
    series: [
      {
        type: "line",
        data: values,
        lineStyle: { color: themeColors.primary },
        itemStyle: {
          color: grays["100"],
          borderColor: themeColors.primary,
          borderWidth: 2,
        },
        symbol: "circle",
        symbolSize: 10,
        smooth: false,
        hoverAnimation: true,
        areaStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: rgbaColor(themeColors.primary, 0.2),
              },
              {
                offset: 1,
                color: rgbaColor(themeColors.primary, 0),
              },
            ],
          },
        },
      },
    ],
    animationDuration: 90000,
    grid: { right: "28px", left: "40px", bottom: "15%", top: "5%" },
  };
};

export const getWaqfPercentage = waqf => {
  const goal = Number(waqf ? waqf.goal : 0);
  const totalDonation = isEmpty(waqf) ? 0 : waqf.counter ? waqf.counter.total_donation : 0;
  const percentage = (totalDonation / goal) * 100;

  return { goal, totalDonation, percentage };
};

function getFormatter(params, month) {
  const { name, value } = params[0];
  return `${month} ${name}, ${value}`;
}
export const useQuery = () => new URLSearchParams(useLocation().search);

export const getOptionWaqfPie = (data, isDark) => {
  const grays = getGrays(isDark);
  return {
    color: data.map(d => d.color),
    tooltip: {
      trigger: "item",
      padding: [7, 10],
      backgroundColor: grays.white,
      textStyle: { color: grays.black },
      transitionDuration: 0,
      borderColor: grays["300"],
      borderWidth: 1,
      formatter: function (params) {
        return `<strong>${params.data.name}:</strong> ${params.data.value}`;
      },
    },
    position(pos, params, dom, rect, size) {
      return getPosition(pos, params, dom, rect, size);
    },
    legend: { show: false },
    series: [
      {
        type: "pie",
        radius: ["100%", "87%"],
        avoidLabelOverlap: false,
        hoverAnimation: false,
        itemStyle: {
          borderWidth: 2,
          borderColor: isDark ? "#0E1C2F" : "#fff",
        },
        labelLine: { normal: { show: false } },
        data: data,
      },
    ],
  };
};

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

import NewsTable from "../../containers/news/NewsTable";
import FalconCardHeader from "../../components/common/FalconCardHeader";
import ButtonIcon from "../../components/common/ButtonIcon";
import { fetchNews } from "../../redux/actions/news.actions";
import { getNews } from "../../redux/reducers/news.reducer";

export default function NewsListPage() {
  const dispatch = useDispatch();
  const news = useSelector(getNews);

  useEffect(() => {
    dispatch(fetchNews());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card className="mb-3">
      <FalconCardHeader title="Berita" light={false}>
        <Link to="/news/create">
          <ButtonIcon icon="plus" transform="shrink-3 down-2" color="falcon-default" size="sm">
            Tambah
          </ButtonIcon>
        </Link>
      </FalconCardHeader>
      <CardBody className="p-0">
        <NewsTable items={news} />
      </CardBody>
    </Card>
  );
}

import React from "react";
import { Card, CardBody } from "reactstrap";
import FalconCardHeader from "../../components/common/FalconCardHeader";
import ThreadsTable from "../../containers/thread/ThreadsTable";

export default function ThreadListPage() {
  return (
    <Card className="mb-3">
      <FalconCardHeader title="Forum" light={false}></FalconCardHeader>
      <CardBody className="p-0">
        <ThreadsTable />
      </CardBody>
    </Card>
  );
}

import React from "react";
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import { Card, CardBody, CardFooter, FormGroup, FormText, Label, Button } from "reactstrap";

import FalconCardHeader from "../common/FalconCardHeader";
import FormikInput from "./formik-field/FormikInput";
import FormikDatetime from "./formik-field/FormikDatetime";
import FormikEditor from "./formik-field/FormikEditor";

import { newsFactory, newsFieldLabels, newsValidation } from "../../entities/news";
import FormikDropzone from "./formik-field/FormikDropzone";

const NewsForm = ({ title, initialValues = newsFactory(), onSubmit }) => {
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={newsValidation}>
      {() => (
        <Form>
          <Card className="mb-3">
            <FalconCardHeader title={title} />
            <CardBody>
              <FormGroup>
                <Label>{newsFieldLabels.title}</Label>
                <FormikInput name="title" placeholder={newsFieldLabels.title} />
              </FormGroup>
              <FormGroup>
                <Label>{newsFieldLabels.category}</Label>
                <FormikInput name="category" placeholder={newsFieldLabels.category} />
                <FormText>Opsional</FormText>
              </FormGroup>
              <FormGroup>
                <Label>{newsFieldLabels.date}</Label>
                <FormikDatetime name="date" placeholder={newsFieldLabels.date} />
              </FormGroup>
              <FormGroup>
                <Label>{newsFieldLabels.body}</Label>
                <FormikEditor name="body" />
              </FormGroup>
              <FormGroup>
                <Label>{newsFieldLabels.image}</Label>
                <FormikDropzone name="image" accept="image/*" />
              </FormGroup>
            </CardBody>
            <CardFooter className="d-flex justify-content-end bg-light">
              <Link to="/news">
                <Button type="button" color="falcon-default" size="sm" className="mr-2">
                  Batal
                </Button>
              </Link>

              <Button type="submit" color="falcon-primary" size="sm">
                Simpan
              </Button>
            </CardFooter>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default NewsForm;

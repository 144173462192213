import { entityName } from "../../entities/reply";

// feature name
export const REPLY = `[${entityName}]`;

// action types
export const FETCH_REPLIES = `${REPLY} Fetch replies`;
export const SET_REPLIES = `${REPLY} Set replies`;
export const UPDATE_REPLIES = `${REPLY} Update an item in replies`;
export const CREATE_REPLY = `${REPLY} Create reply`;
export const DELETE_REPLY = `${REPLY} Delete a reply`;
export const DELETE_REPLIES = `${REPLY} Delete an item in replies`;

// action creators
export const fetchReplies = ({ threadId }) => ({
  type: FETCH_REPLIES,
  payload: threadId,
});

export const setReplies = ({ replies }) => ({
  type: SET_REPLIES,
  payload: replies,
});

export const updateReplies = ({ replies }) => ({
  type: UPDATE_REPLIES,
  payload: replies,
});

export const createReply = ({ reply }) => ({
  type: CREATE_REPLY,
  payload: reply,
});

export const deleteReply = ({ replyId }) => ({
  type: DELETE_REPLY,
  payload: replyId,
});

export const deleteReplies = ({ replyId }) => ({
  type: DELETE_REPLIES,
  payload: replyId,
});

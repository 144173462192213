import { DELETE_NAZIR, SET_NAZIRS, UPDATE_NAZIRS } from "../actions/nazirs.actions";
import entityReducer from "./entityReducer";

export const nazirsReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_NAZIRS:
    case UPDATE_NAZIRS:
    case DELETE_NAZIR:
      return entityReducer(state, action);

    default:
      return state;
  }
};

// feature selectors
export const getNazirs = state => Object.values(state.nazirs);
export const getNazirById = id => state => state.nazirs[id];

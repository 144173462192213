import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Card, CardBody, CardFooter, FormGroup, Label, Button, FormText } from "reactstrap";
import { Formik, Form } from "formik";

import { nazirFactory, nazirValidation, nazirFieldLabels } from "../../entities/nazir";
import FalconCardHeader from "../common/FalconCardHeader";
import FormikDropzone from "./formik-field/FormikDropzone";
import FormikEditor from "./formik-field/FormikEditor";
import FormikInput from "./formik-field/FormikInput";
import FormikSwitch from "./formik-field/FormikSwitch";

const NazirForm = ({ title, initialValues, onSubmit, isEdit }) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={nazirValidation(isEdit)}
    >
      {() => (
        <Form>
          <Card className="mb-3">
            <FalconCardHeader title={title} />
            <CardBody>
              <FormGroup>
                <Label>{nazirFieldLabels.name}</Label>
                <FormikInput name="name" placeholder={nazirFieldLabels.name} />
              </FormGroup>
              <FormGroup>
                <Label>{nazirFieldLabels.email}</Label>
                <FormikInput type="email" name="email" placeholder={nazirFieldLabels.email} />
              </FormGroup>
              {!isEdit && (
                <FormGroup>
                  <Label>{nazirFieldLabels.password}</Label>
                  <FormikInput name="password" placeholder={nazirFieldLabels.password} />
                </FormGroup>
              )}
              <FormGroup>
                <Label>{nazirFieldLabels.phone}</Label>
                <FormikInput name="phone" placeholder={nazirFieldLabels.phone} />
                <FormText>Harap mengisi nomor telepon dimulai dengan +62 dan tanpa spasi</FormText>
              </FormGroup>
              <FormGroup>
                <Label>{nazirFieldLabels.verified}</Label>
                <br />
                <FormikSwitch name="verified" />
              </FormGroup>
              <FormGroup>
                <Label>{nazirFieldLabels.description}</Label>
                <FormikEditor name="description" />
              </FormGroup>
              <FormGroup>
                <Label>{nazirFieldLabels.image}</Label>
                <FormikDropzone name="image" accept="image/*" />
              </FormGroup>
            </CardBody>
            <CardFooter className="d-flex justify-content-end bg-light">
              <Link to="/nazirs" className="btn btn-falcon-default btn-sm mr-2">
                Batal
              </Link>

              <Button type="submit" color="falcon-primary" size="sm">
                Simpan
              </Button>
            </CardFooter>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

NazirForm.propTypes = {
  title: PropTypes.string.isRequired,
  initialValues: PropTypes.any,
  onSubmit: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
};

NazirForm.defaultProps = {
  initialValues: nazirFactory(),
  isEdit: false,
};

export default NazirForm;

import { DELETE_DONATION, SET_DONATIONS, UPDATE_DONATIONS } from "../actions/donations.actions";
import entityReducer from "./entityReducer";

export const donationsReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_DONATIONS:
    case UPDATE_DONATIONS:
    case DELETE_DONATION:
      return entityReducer(state, action);

    default:
      return state;
  }
};

// feature selectors
export const getDonations = state => Object.values(state.donations);
export const getDonationById = id => state => state.donations[id];

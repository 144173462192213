import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import CategoryForm from "../../components/form/CategoryForm";
import { fetchCategory, updateCategory } from "../../redux/actions/categories.actions";
import { getCategoryById } from "../../redux/reducers/categories.reducer";

export default function EditCategoryPage({ match }) {
  const dispatch = useDispatch();
  const categoryId = match.params.id;
  const category = useSelector(getCategoryById(categoryId));
  const { loading } = useSelector(state => state.ui);

  useEffect(() => {
    dispatch(fetchCategory({ categoryId }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId]);

  function handleSubmit(values) {
    dispatch(updateCategory({ category: values }));
  }

  if (loading || !category) {
    return <h1>Loading</h1>;
  }

  return <CategoryForm title="Edit Kategori" initialValues={category} onSubmit={handleSubmit} />;
}

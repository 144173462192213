export const LINK_AJA = `[LINK_AJA]`;

export const CHECK_PAYMENT_STATUS = `${LINK_AJA} Check payment status`;
export const SET_PAYMENT_STATUS = `${LINK_AJA} Set payment status`;

export const checkPaymentStatus = ({ donationId }) => ({
  type: CHECK_PAYMENT_STATUS,
  payload: {
    trxId: donationId,
  },
  meta: {
    feature: LINK_AJA,
  },
});

export const setPaymentStatus = ({ payment }) => ({
  type: SET_PAYMENT_STATUS,
  payload: payment,
  meta: {
    featuer: LINK_AJA,
  },
});

import { entityName } from "../../entities/payment-methods";

// feature name
export const PAYMENT_METHOD = `[${entityName}]`;

// action types
export const FETCH_PAYMENT_METHODS = `${PAYMENT_METHOD} Fetch payment methods`;
export const FETCH_PAYMENT_METHOD = `${PAYMENT_METHOD} Fetch a payment method`;
export const SET_PAYMENT_METHODS = `${PAYMENT_METHOD} Set payment methods`;
export const UPDATE_PAYMENT_METHODS = `${PAYMENT_METHOD} Update an item in payment methods`;
export const CREATE_PAYMENT_METHOD = `${PAYMENT_METHOD} Create payment method`;
export const UPDATE_PAYMENT_METHOD = `${PAYMENT_METHOD} Update a payment method`;
export const DELETE_PAYMENT_METHOD = `${PAYMENT_METHOD} Delete a payment method`;

// action creators
export const fetchPaymentMethods = () => ({
  type: FETCH_PAYMENT_METHODS,
});

export const setPaymentMethods = ({ paymentMethods }) => ({
  type: SET_PAYMENT_METHODS,
  payload: paymentMethods,
  meta: {
    feature: PAYMENT_METHOD,
  },
});

export const updatePaymentMethods = ({ paymentMethod }) => ({
  type: UPDATE_PAYMENT_METHODS,
  payload: paymentMethod,
  meta: {
    feature: PAYMENT_METHOD,
  },
});

export const fetchPaymentMethod = ({ paymentMethodId }) => ({
  type: FETCH_PAYMENT_METHOD,
  payload: paymentMethodId,
});

export const createPaymentMethod = ({ paymentMethod }) => ({
  type: CREATE_PAYMENT_METHOD,
  payload: paymentMethod,
  meta: {
    feature: PAYMENT_METHOD,
  },
});

export const updatePaymentMethod = ({ paymentMethod }) => ({
  type: UPDATE_PAYMENT_METHOD,
  payload: paymentMethod,
  meta: {
    feature: PAYMENT_METHOD,
  },
});

export const deletePaymentMethod = ({ paymentMethodId }) => ({
  type: DELETE_PAYMENT_METHOD,
  payload: paymentMethodId,
  meta: {
    feature: PAYMENT_METHOD,
  },
});

export const waqfFactory = () => {
  return {
    title: "",
    date: "",
    category: null,
    location: {
      province: null,
      regency: null,
    },
    nazir: null,
    goal: 0,
    has_deadline: false,
    deadline: null,
    thumbnail_image: null,
    images: [],
    description: "",
  };
};

import { SET_PAYMENT_STATUS } from "../actions/linkAja.actions";

const initialState = {
  paymentStatus: {},
};

export const linkAjaReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PAYMENT_STATUS:
      return { ...state, paymentStatus: action.payload };

    default:
      return state;
  }
};

export const getPaymentStatus = state => state.linkAja.paymentStatus;

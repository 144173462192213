import { Form, Formik } from "formik";
import React, { Fragment } from "react";
import { Button, Card, CardBody, CardFooter, FormGroup, Label } from "reactstrap";
import {
  waqfReportFactory,
  waqfReportFieldLabels,
  waqfReportValidation,
} from "../../entities/waqf-report";
import FalconCardHeader from "../common/FalconCardHeader";
import FormikCurrencyFormat from "./formik-field/FormikCurrencyFormat";
import FormikDatetime from "./formik-field/FormikDatetime";
import FormikDropzone from "./formik-field/FormikDropzone";
import FormikEditor from "./formik-field/FormikEditor";
import FormikInput from "./formik-field/FormikInput";
import FormikSelect from "./formik-field/FormikSelect";

const WaqfReportForm = ({
  initialValues = waqfReportFactory(),
  waqfAmountCollected = 0,
  onSubmit,
  onCancel,
  loading,
  classNames,
}) => {
  return (
    <Fragment>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={waqfReportValidation(waqfAmountCollected)}
        onSubmit={onSubmit}
      >
        {() => (
          <Card className={classNames}>
            <FalconCardHeader title="Form Laporan"></FalconCardHeader>

            <fieldset>
              <Form>
                <CardBody>
                  <FormGroup>
                    <Label>{waqfReportFieldLabels.category}</Label>
                    <FormikSelect
                      name="category"
                      placeholder={waqfReportFieldLabels.category}
                      options={[{ name: "Pencairan Dana", id: "pencairan-dana" }]}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>{waqfReportFieldLabels.title}</Label>
                    <FormikInput name="title" />
                  </FormGroup>
                  <FormGroup>
                    <Label>{waqfReportFieldLabels.amount}</Label>
                    <FormikCurrencyFormat name="amount" />
                  </FormGroup>
                  <FormGroup>
                    <Label>{waqfReportFieldLabels.description}</Label>
                    <FormikEditor name="description" />
                  </FormGroup>
                  <FormGroup>
                    <Label>{waqfReportFieldLabels.images}</Label>
                    <FormikDropzone name="images" isMulti limit={6} accept="image/*" />
                  </FormGroup>
                  <FormGroup>
                    <Label>{waqfReportFieldLabels.date}</Label>
                    <FormikDatetime name="date" placeholder={waqfReportFieldLabels.date} />
                  </FormGroup>
                </CardBody>
                <CardFooter className="d-flex justify-content-end bg-light">
                  <Button
                    type="button"
                    color="falcon-default"
                    size="sm"
                    className="mr-2"
                    onClick={onCancel}
                  >
                    Batal
                  </Button>

                  <Button type="submit" color="falcon-primary" size="sm">
                    Simpan
                  </Button>
                </CardFooter>
              </Form>
            </fieldset>
          </Card>
        )}
      </Formik>
    </Fragment>
  );
};

export default WaqfReportForm;

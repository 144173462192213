import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { get } from "lodash";

export default function PublicRoute({ component: Component, ...rest }) {
  const user = useSelector(state => state.auth);

  return (
    <Route
      {...rest}
      render={props => {
        const isLogoutPage = props.location.pathname.includes("logout");

        if (user && !isLogoutPage) {
          const intended = get(props.location, "state.from.pathname", "/");
          return <Redirect to={intended} />;
        }

        return <Component {...props} />;
      }}
    />
  );
}

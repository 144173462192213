import { entityName } from "../../entities/banner";

// feature name
export const BANNER = `[${entityName}]`;

// action types
export const FETCH_BANNERS = `${BANNER} Fetch banners`;
export const FETCH_BANNER = `${BANNER} Fetch a banner`;
export const SET_BANNERS = `${BANNER} Set banners`;
export const UPDATE_BANNERS = `${BANNER} Update an item in banners`;
export const CREATE_BANNER = `${BANNER} Create banner`;
export const UPDATE_BANNER = `${BANNER} Update a banner`;
export const DELETE_BANNER = `${BANNER} Delete a banner`;

// action creators
export const fetchBanners = ({ constraints = [], orderBy = [], limit = 0 } = {}) => ({
  type: FETCH_BANNERS,
  meta: {
    constraints,
    orderBy,
    limit,
  },
});

export const setBanners = ({ banners }) => ({
  type: SET_BANNERS,
  payload: banners,
});

export const updateBanners = ({ banner }) => ({
  type: UPDATE_BANNERS,
  payload: banner,
  meta: {
    feature: BANNER,
  },
});

export const fetchBanner = ({ bannerId }) => ({
  type: FETCH_BANNER,
  payload: bannerId,
});

export const createBanner = ({ banner }) => ({
  type: CREATE_BANNER,
  payload: banner,
  meta: {
    feature: BANNER,
  },
});

export const updateBanner = ({ banner }) => ({
  type: UPDATE_BANNER,
  payload: banner,
  meta: {
    feature: BANNER,
  },
});

export const deleteBanner = ({ bannerId }) => ({
  type: DELETE_BANNER,
  payload: bannerId,
  meta: {
    feature: BANNER,
  },
});

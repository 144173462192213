import { string, object, bool, mixed } from "yup";
import { isEmpty } from "lodash";

export const nazirValidation = (isEdit = false) =>
  object().shape({
    name: string().required("Wajib mengisi nama nazir"),
    email: string().email("Bukan format email yang valid").required("Wajib mengisi email"),
    password: isEdit ? string().notRequired() : string().required("Wajib mengisi password"),
    phone: string()
      .when("email", {
        is: email => isEmpty(email),
        then: string().required("Wajib mengisi nomor telepon apabila email dikosongkan"),
      })
      .test(
        "is-phone",
        "Harap input nomor telepon dimulai dengan +62",
        value => !isEmpty(value) && value.startsWith("+62")
      ),
    verified: bool(),
    description: mixed().required("Wajib mengisi deskripsi"),
    image: mixed().required("Wajib mengisi gambar"),
  });

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

import ButtonIcon from "../../components/common/ButtonIcon";
import FalconCardHeader from "../../components/common/FalconCardHeader";
import BannersTable from "../../containers/banner/BannersTable";
import { fetchBanners } from "../../redux/actions/banners.actions";
import { getBanners } from "../../redux/reducers/banner.reducer";

export default function BannerListPage() {
  const dispatch = useDispatch();
  const banners = useSelector(getBanners);

  useEffect(() => {
    dispatch(fetchBanners());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card className="mb-3">
      <FalconCardHeader title="Banner" light={false}>
        <Link to="/banners/create">
          <ButtonIcon icon="plus" transform="shrink-3 down-2" color="falcon-default" size="sm">
            Tambah
          </ButtonIcon>
        </Link>
      </FalconCardHeader>
      <CardBody className="p-0">
        <BannersTable banners={banners} />
      </CardBody>
    </Card>
  );
}

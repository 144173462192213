import { entityName } from "../../entities/nazir";

// feature name
export const NAZIR = `[${entityName}]`;

// action types
export const FETCH_NAZIRS = `${NAZIR} Fetch nazirs`;
export const FETCH_NAZIR = `${NAZIR} Fetch a nazir`;
export const SET_NAZIRS = `${NAZIR} Set nazirs`;
export const UPDATE_NAZIRS = `${NAZIR} Update an item in nazirs`;
export const CREATE_NAZIR = `${NAZIR} Create nazir`;
export const UPDATE_NAZIR = `${NAZIR} Update a nazir`;
export const DELETE_NAZIR = `${NAZIR} Delete a nazir`;

// action creators
export const fetchNazirs = () => ({
  type: FETCH_NAZIRS,
});

export const setNazirs = ({ nazirs }) => ({
  type: SET_NAZIRS,
  payload: nazirs,
  meta: { feature: NAZIR },
});

export const updateNazirs = ({ nazir }) => ({
  type: UPDATE_NAZIRS,
  payload: nazir,
  meta: { feature: NAZIR },
});

export const fetchNazir = ({ nazirId }) => ({
  type: FETCH_NAZIR,
  payload: nazirId,
});

export const createNazir = ({ nazir }) => ({
  type: CREATE_NAZIR,
  payload: nazir,
  meta: { feature: NAZIR },
});

export const updateNazir = ({ nazir }) => ({
  type: UPDATE_NAZIR,
  payload: nazir,
  meta: { feature: NAZIR },
});

export const deleteNazir = ({ nazirId }) => ({
  type: DELETE_NAZIR,
  payload: nazirId,
  meta: { feature: NAZIR },
});

import { push } from "connected-react-router";

import {
  DATABASE_FETCH_COLLECTION_SUCCESS,
  DATABASE_FETCH_ITEM_SUCCESS,
  DATABASE_UPDATE_SUCCESS,
  DATABASE_DELETE_SUCCESS,
  DATABASE_ERROR,
  databaseFetchCollection,
} from "../../actions/database.actions";

import { HTTP_SUCCESS, HTTP_ERROR, httpRequest } from "../../actions/http.actions";

import {
  CREATE_NAZIR,
  DELETE_NAZIR,
  FETCH_NAZIR,
  FETCH_NAZIRS,
  NAZIR,
  setNazirs,
  updateNazirs,
  UPDATE_NAZIR,
} from "../../actions/nazirs.actions";

import { fetchUser, updateUser, deleteUser } from "../../actions/users.actions";
import { setLoader } from "../../actions/ui.actions";
import { setToast } from "../../actions/toast.actions";
import { collectionName as collection, nazirFactory as factory } from "../../../entities/nazir";

export const nazirsMiddleware = ({ dispatch }) => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_NAZIRS:
      next([
        databaseFetchCollection({
          collection,
          feature: NAZIR,
          constraints: [["roles", "array-contains", "nazir"]],
        }),
        setLoader({ state: true, feature: NAZIR }),
      ]);
      break;

    case `${NAZIR} ${DATABASE_FETCH_COLLECTION_SUCCESS}`:
      next([setNazirs({ nazirs: action.payload }), setLoader({ state: false, feature: NAZIR })]);
      break;

    case FETCH_NAZIR:
      dispatch(fetchUser({ userId: action.payload, feature: NAZIR, collection }));
      break;

    case `${NAZIR} ${DATABASE_FETCH_ITEM_SUCCESS}`:
      next([updateNazirs({ nazir: action.payload }), setLoader({ state: false, feature: NAZIR })]);
      break;

    case CREATE_NAZIR:
      next([
        httpRequest({
          body: action.payload,
          feature: NAZIR,
          functionName: "createNazir",
        }),
        setLoader({ state: true, feature: NAZIR }),
      ]);
      break;

    case `${NAZIR} ${HTTP_SUCCESS}`:
      next([
        setToast({ message: "Berhasil menambahkan nazir", feature: NAZIR }),
        setLoader({ state: false, feature: NAZIR }),
      ]);
      dispatch(push("/nazirs"));
      break;

    case UPDATE_NAZIR:
      dispatch(updateUser({ user: action.payload, feature: NAZIR, collection, factory }));
      break;

    case `${NAZIR} ${DATABASE_UPDATE_SUCCESS}`:
      next([
        setToast({ message: "Berhasil mengupdate nazir", feature: NAZIR }),
        setLoader({ state: false, feature: NAZIR }),
      ]);
      dispatch(push("/nazirs"));
      break;

    case DELETE_NAZIR:
      dispatch(deleteUser({ userId: action.payload, feature: NAZIR, collection }));
      break;

    case `${NAZIR} ${DATABASE_DELETE_SUCCESS}`:
      next([
        setToast({ message: "Berhasil menghapus nazir", feature: NAZIR }),
        setLoader({ state: false, feature: NAZIR }),
      ]);
      break;

    case `${NAZIR} ${DATABASE_ERROR}`:
    case `${NAZIR} ${HTTP_ERROR}`:
      next([
        setToast({
          message: action.payload.message.replace(/:.*$/, ""),
          type: "error",
          feature: NAZIR,
        }),
        setLoader({ state: false, feature: NAZIR }),
      ]);
      break;

    default:
      break;
  }
};

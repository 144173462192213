import firebase from "firebase/app";
import { isEmpty, keyBy, omit } from "lodash";

import { db } from "../../../singletons/firebase";
import { sanitizeForm } from "../../../helpers/utils";
import {
  DATABASE_FETCH_COLLECTION,
  DATABASE_FETCH_ITEM,
  DATABASE_STORE,
  DATABASE_UPDATE,
  DATABASE_DELETE,
  DATABASE_PREPARE_WRITE,
  databaseFetchCollectionSuccess,
  databaseFetchItemSuccess,
  databaseStoreSuccess,
  databaseUpdateSuccess,
  databaseDeleteSuccess,
  databaseError,
  databaseStore,
  databaseUpdate,
} from "../../actions/database.actions";

import { setLoader } from "../../actions/ui.actions";

export const databaseMiddleware = ({ dispatch }) => next => action => {
  next(action);

  if (action.type.includes(DATABASE_FETCH_COLLECTION)) {
    let query = db.collection(action.payload);
    const { feature, constraints, orderBy, startAfter, limit } = action.meta;

    if (!isEmpty(constraints)) {
      for (const constraint of constraints) {
        query = query.where(...constraint);
      }
    }

    if (!isEmpty(orderBy)) {
      for (const order of orderBy) {
        query = query.orderBy(...order);
      }
    }

    if (!isEmpty(startAfter)) {
      query = query.startAfter(startAfter);
    }

    if (limit && limit > 0) {
      query = query.limit(limit);
    }

    query
      .get()
      .then(snap => {
        const items = snap.empty ? [] : keyBy(snap.docs.map(formatFirestore), "id");

        dispatch(databaseFetchCollectionSuccess({ items, feature, collection: action.payload }));
      })
      .catch(error => dispatch(databaseError({ error, feature })));
  }

  if (action.type.includes(DATABASE_FETCH_ITEM)) {
    const { feature } = action.meta;

    db.doc(action.payload)
      .get()
      .then(snap => dispatch(databaseFetchItemSuccess({ item: formatFirestore(snap), feature })))
      .catch(error => dispatch(databaseError({ error, feature })));
  }

  if (action.type.includes(DATABASE_STORE)) {
    const { collection, feature } = action.meta;

    const values = {
      ...action.payload,
      created_at: firebase.firestore.FieldValue.serverTimestamp(),
      updated_at: null,
    };

    db.collection(collection)
      .add(values)
      .then(response => dispatch(databaseStoreSuccess({ response, feature })))
      .catch(error => dispatch(databaseError({ error, feature })));
  }

  if (action.type.includes(DATABASE_UPDATE)) {
    const { collection, feature } = action.meta;

    const values = {
      ...action.payload,
      updated_at: firebase.firestore.FieldValue.serverTimestamp(),
    };

    db.doc(`${collection}/${values.id}`)
      .update(omit(values, "id"))
      .then(response => dispatch(databaseUpdateSuccess({ response, collection, feature })))
      .catch(error => dispatch(databaseError({ error, feature })));
  }

  if (action.type.includes(DATABASE_DELETE)) {
    const { collection, feature } = action.meta;

    db.doc(`${collection}/${action.payload}`)
      .delete()
      .then(response => dispatch(databaseDeleteSuccess({ response, collection, feature })))
      .catch(error => dispatch(databaseError({ error, feature })));
  }

  if (action.type.includes(DATABASE_PREPARE_WRITE)) {
    const { collection, factory, feature, databaseAction } = action.meta;
    const values = sanitizeForm(action.payload, factory);

    next(setLoader({ state: true, feature }));

    if (databaseAction === "create") {
      dispatch(databaseStore({ collection, values, feature }));
    }

    if (databaseAction === "update") {
      dispatch(databaseUpdate({ collection, values, feature }));
    }
  }
};

export const formatFirestore = doc => {
  return { ...doc.data(), id: doc.id };
};

import React, { Fragment, useState } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardImg,
  Col,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import noImage from "../../assets/img/no-image.jpg";
import { useField } from "formik";
import { uploadFile } from "../../helpers/utils";
import { storage } from "../../singletons/firebase";

const EventCreateBanner = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [field, meta, helpers] = useField(props);
  const error = meta.touched && meta.error;

  async function handleChange({ target }) {
    setIsLoading(true);

    if (field.value) {
      await storage.refFromURL(field.value.url).delete();
      helpers.setValue(null);
    }

    const image = await uploadFile(target.files[0], "event");

    helpers.setValue(image);

    setIsLoading(false);
  }

  return (
    <Fragment>
      <Card className="mb-3">
        <CardBody>
          <Row className="justify-content-between align-items-center">
            <Col md>
              <h5 className="mb-2 mb-md-0">Form Event</h5>
            </Col>
            <Col xs="auto">
              <Button color="falcon-default" size="sm" className="mr-2" type="submit">
                Save
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card className="overflow-hidden mb-3">
        {isLoading ? (
          <div
            className="d-flex justify-content-center align-items-center w-100"
            style={{
              height: "200px",
            }}
          >
            <Spinner type="grow" color="primary" />
          </div>
        ) : (
          <div className="cover-image overflow-hidden">
            <CardImg top src={field.value ? field.value.url : noImage} alt="" />
            <div>
              <Input
                name="image"
                className="d-none"
                id="upload-cover-image"
                type="file"
                accept="image/*"
                onChange={handleChange}
              />
              {error && (
                <div className="px-3 pt-3">
                  <Alert color="danger">{error}</Alert>
                </div>
              )}
            </div>
            <Label className="cover-image-file-input" htmlFor="upload-cover-image">
              <FontAwesomeIcon icon="camera" className="mr-2" />
              <span>Change cover photo</span>
            </Label>
          </div>
        )}
      </Card>
    </Fragment>
  );
};

export default EventCreateBanner;

import React from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import { useField } from "formik";
import { pick } from "lodash";

export function styles(error) {
  return {
    control: base => ({
      ...base,
      "border-color": error ? "#e63757" : base["border-color"],
    }),
  };
}

const FormikSelect = props => {
  const [field, meta, helpers] = useField(props);
  const error = meta.touched && meta.error;

  function onChange(value) {
    helpers.setValue(pick(value, props.pickValue));
  }

  return (
    <>
      <Select
        {...field}
        {...props}
        getOptionLabel={option => option.name}
        getOptionValue={option => option.id}
        onChange={onChange}
        styles={styles(error)}
      />
      {error && <span className="text-danger fs--1">{error}</span>}
    </>
  );
};

FormikSelect.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  pickValue: PropTypes.array,
  options: PropTypes.array,
};

FormikSelect.defaultProps = {
  placeholder: "",
  pickValue: ["id", "name"],
  options: [],
};

export default FormikSelect;

import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get, isEmpty } from "lodash";
import { Badge, Button, Card, CardBody, CardFooter, Col, Row } from "reactstrap";

import FalconCardHeader from "../../components/common/FalconCardHeader";
import DonationDonorCard from "../../containers/donation/DonationDonorCard";
import DonationWaqfCard from "../../containers/donation/DonationWaqfCard";
import Money from "../../lib/Money";
import FirebaseTimestamp from "../../lib/FirebaseTimestamp";
import { fetchDonation, updateDonation } from "../../redux/actions/donations.actions";
import { getDonationById } from "../../redux/reducers/donations.reducer";
import DonationLinkAjaCard from "../../containers/donation/DonationLinkAjaCard";

export default function DetailDonation({ match }) {
  const dispatch = useDispatch();
  const donationId = match.params.id;
  const donation = useSelector(getDonationById(donationId));
  const { loading } = useSelector(state => state.ui);
  const user = useSelector(state => state.auth);
  const userRoles = get(user, "roles", []);

  useEffect(() => {
    dispatch(fetchDonation({ donationId }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [donationId]);

  function handleConfirmation() {
    dispatch(
      updateDonation({
        donation: {
          id: donation.id,
          status: "completed",
        },
      })
    );
  }

  if (loading || !donation) {
    return <h1>Loading</h1>;
  }

  return (
    <Fragment>
      <h5 className="mb-3">
        Donasi <b>{donation.id}</b>
      </h5>

      <Row noGutters>
        <Col md={8} className="pr-lg-2">
          <DonationWaqfCard waqf={donation.waqf} />

          <DonationDonorCard donor={donation.donor} />

          {!isEmpty(get(donation, "link_aja")) && <DonationLinkAjaCard donationId={donation.id} />}
        </Col>
        <Col md={4} className="pl-lg-2">
          <div className="sticky-top sticky-sidebar">
            <Card>
              <FalconCardHeader title="Detail Donasi" />

              <CardBody>
                <h6>Tanggal Donasi</h6>
                <p className="mb-1">{new FirebaseTimestamp(donation.created_at).formatted}</p>

                <h6 className="mt-4">Jumlah</h6>
                <p className="mb-1">{new Money(donation.amount).currencyFormat()}</p>

                <h6 className="mt-4">Jumlah Fee</h6>
                <p className="mb-1">{new Money(donation.fee_amount).currencyFormat()}</p>

                <h6 className="mt-4">Kode Unik</h6>
                <p className="mb-1">{new Money(donation.unique_code).currencyFormat()}</p>

                <h6 className="mt-4">Total Pembayaran</h6>
                <p className="mb-1">{new Money(donation.total_amount).currencyFormat()}</p>

                <h6 className="mt-4">Metode Pembayaran</h6>
                <p className="mb-1">{get(donation, "payment_method.name", "")}</p>

                <h6 className="mt-4">Pesan</h6>
                <p className="mb-1">{donation.message}</p>

                <h6 className="mt-4">Status</h6>
                <Badge color={defineStatusColor(donation.status)}>
                  {donation.status.toUpperCase()}
                </Badge>
              </CardBody>
              {donation.status === "pending" &&
                ["admin", "payment_admin"].some(role => userRoles.includes(role)) && (
                  <CardFooter className="text-right border-top">
                    <Button
                      type="submit"
                      color="falcon-primary"
                      size="sm"
                      onClick={handleConfirmation}
                    >
                      Konfirmasi Pembayaran
                    </Button>
                  </CardFooter>
                )}
            </Card>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
}

function defineStatusColor(status) {
  switch (status) {
    case "completed":
      return "success";

    case "abandoned":
      return "danger";

    default:
      return "secondary";
  }
}

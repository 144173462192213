// feature name
export const UI = "[UI]";

// action types
export const SET_SHOW_BURGER_MENU = `${UI} Set show burger menu state`;
export const SET_NAVBAR_COLLAPSED = `${UI} Set top navbar collapsed state`;
export const SET_LOADER = "Set loader";

// action creators
export const setShowBurgerMenu = ({ state }) => ({
  type: SET_SHOW_BURGER_MENU,
  payload: state,
});

export const setNavbarCollapsed = ({ state }) => ({
  type: SET_NAVBAR_COLLAPSED,
  payload: state,
});

export const setLoader = ({ state, feature }) => ({
  type: `${feature} ${SET_LOADER}`,
  payload: state,
  meta: { feature },
});

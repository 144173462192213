import { collectionName as parentCollection } from "../../../entities/waqf";
import {
  collectionName as collection,
  waqfReportFactory as factory,
} from "../../../entities/waqf-report";
import {
  databaseDelete,
  databaseFetchCollection,
  databaseFetchItem,
  databasePrepareWrite,
  DATABASE_DELETE_SUCCESS,
  DATABASE_ERROR,
  DATABASE_FETCH_COLLECTION_SUCCESS,
  DATABASE_FETCH_ITEM_SUCCESS,
  DATABASE_STORE_SUCCESS,
  DATABASE_UPDATE_SUCCESS,
} from "../../actions/database.actions";
import { setToast } from "../../actions/toast.actions";
import { setLoader } from "../../actions/ui.actions";
import {
  CREATE_WAQF_REPORT,
  DELETE_WAQF_REPORT,
  fetchWaqfReports,
  FETCH_WAQF_REPORT,
  FETCH_WAQF_REPORTS,
  setWaqfReports,
  updateWaqfReports,
  UPDATE_WAQF_REPORT,
  WAQF_REPORT,
} from "../../actions/waqf-reports.actions";

export const waqfReportsMiddleware = ({ dispatch }) => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_WAQF_REPORTS:
      next([
        databaseFetchCollection({
          collection: `${parentCollection}/${action.payload}/${collection}`,
          feature: WAQF_REPORT,
        }),
        setLoader({
          state: true,
          feature: WAQF_REPORT,
        }),
      ]);
      break;

    case `${WAQF_REPORT} ${DATABASE_FETCH_COLLECTION_SUCCESS}`:
      next([
        setWaqfReports({ waqfReports: action.payload }),
        setLoader({
          state: false,
          feature: WAQF_REPORT,
        }),
      ]);
      break;

    case FETCH_WAQF_REPORT:
      next([
        databaseFetchItem({
          path: `${parentCollection}/${action.payload.waqfId}/${collection}/${action.payload.waqfReportId}`,
          feature: WAQF_REPORT,
        }),
      ]);
      break;

    case `${WAQF_REPORT} ${DATABASE_FETCH_ITEM_SUCCESS}`:
      next([
        updateWaqfReports({ waqfReport: action.payload }),
        setLoader({
          state: false,
          feature: WAQF_REPORT,
        }),
      ]);
      break;

    case CREATE_WAQF_REPORT:
      next([
        databasePrepareWrite({
          data: action.payload.waqfReport,
          factory,
          collection: `${parentCollection}/${action.payload.waqfId}/${collection}`,
          feature: WAQF_REPORT,
          databaseAction: "create",
        }),
      ]);
      break;

    case `${WAQF_REPORT} ${DATABASE_STORE_SUCCESS}`:
      next([
        setToast({
          message: "Berhasil menambahkan laporan wakaf",
          feature: WAQF_REPORT,
        }),
        setLoader({ state: false, feature: WAQF_REPORT }),
      ]);
      dispatch(fetchWaqfReports({ waqfId: action.payload.path.split("/")[1] }));
      break;

    case UPDATE_WAQF_REPORT:
      next([
        databasePrepareWrite({
          collection: `${parentCollection}/${action.payload.waqfId}/${collection}`,
          factory,
          data: action.payload.waqfReport,
          feature: WAQF_REPORT,
          databaseAction: "update",
        }),
      ]);
      break;

    case `${WAQF_REPORT} ${DATABASE_UPDATE_SUCCESS}`:
      next([
        setToast({
          message: "Berhasil mengupdate laporan wakaf",
          feature: WAQF_REPORT,
        }),
        setLoader({ state: false, feature: WAQF_REPORT }),
      ]);
      dispatch(fetchWaqfReports({ waqfId: action.meta.collection.split("/")[1] }));
      break;

    case DELETE_WAQF_REPORT:
      next([
        databaseDelete({
          collection: `${parentCollection}/${action.payload.waqfId}/${collection}`,
          id: action.payload.waqfReportId,
          feature: WAQF_REPORT,
        }),
        setLoader({
          state: true,
          feature: WAQF_REPORT,
        }),
      ]);
      break;

    case `${WAQF_REPORT} ${DATABASE_DELETE_SUCCESS}`:
      next([
        setToast({
          message: "Berhasil menghapus laporan wakaf",
          feature: WAQF_REPORT,
        }),
        setLoader({ state: false, feature: WAQF_REPORT }),
      ]);
      dispatch(fetchWaqfReports({ waqfId: action.meta.collection.split("/")[1] }));
      break;

    case `${WAQF_REPORT} ${DATABASE_ERROR}`:
      next([
        setToast({
          message: action.payload.message.replace(/:.*$/, ""),
          type: "error",
          feature: WAQF_REPORT,
        }),
        setLoader({ state: false, feature: WAQF_REPORT }),
      ]);
      break;

    default:
      break;
  }
};

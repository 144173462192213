import { FieldArray } from "formik";
import React from "react";
import { Fragment } from "react";
import { Alert, Card, CardBody, Col, Row } from "reactstrap";
import ButtonIcon from "../../components/common/ButtonIcon";
import FalconCardHeader from "../../components/common/FalconCardHeader";
import FormikInput from "../../components/form/formik-field/FormikInput";
import { eventFieldLabels } from "../../entities/event";
import { isIterableArray } from "../../helpers/utils";

const EventCreateAside = ({ speakers = [] }) => {
  return (
    <Card className="mb-3 mb-lg-0">
      <FalconCardHeader title="Other Info" light={false} />
      <CardBody className="bg-light">
        <FieldArray name="speakers">
          {arrayHelpers => (
            <Fragment>
              <Row>
                {isIterableArray(speakers) ? (
                  speakers.map((speaker, index) => (
                    <Col key={index} xs={12} className="d-flex align-items-start mb-3">
                      <div>
                        <FormikInput
                          name={`speakers[${index}]`}
                          placeholder={eventFieldLabels.speakers}
                        />
                      </div>

                      <div className="mx-3">
                        <ButtonIcon
                          icon="minus"
                          transform="shrink-3 down-2"
                          color="danger"
                          size="sm"
                          onClick={() => arrayHelpers.remove(index)}
                        />
                      </div>
                    </Col>
                  ))
                ) : (
                  <Col xs={12} className="mb-3">
                    <Alert color="info">Tidak ada {eventFieldLabels.speakers}</Alert>
                  </Col>
                )}
              </Row>

              <ButtonIcon
                icon="plus"
                transform="shrink-3 down-2"
                color="falcon-default"
                size="sm"
                onClick={() => arrayHelpers.push("")}
              >
                Tambah
              </ButtonIcon>
            </Fragment>
          )}
        </FieldArray>
      </CardBody>
    </Card>
  );
};

export default EventCreateAside;

export const replyFactory = () => {
  return {
    user: {
      id: "",
      name: "",
      photo: "",
    },
    thread_id: "",
    body: null,
    pinned: false,
  };
};

import { has, omit } from "lodash";

const entityReducer = (state, action) => {
  if (action.type.includes("Set")) {
    return action.payload;
  }

  if (action.type.includes("Update")) {
    const entityId = action.payload.id;

    if (has(state, entityId)) {
      return { ...state, [entityId]: { ...state[entityId], ...action.payload } };
    } else {
      return { ...state, [entityId]: action.payload };
    }
  }

  if (action.type.includes("Delete")) {
    return omit(state, action.payload);
  }

  return state;
};

export default entityReducer;

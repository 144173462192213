import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import BannerForm from "../../components/form/BannerForm";
import { fetchBanner, updateBanner } from "../../redux/actions/banners.actions";
import { getBannerById } from "../../redux/reducers/banner.reducer";

export default function EditBannerPage({ match }) {
  const dispatch = useDispatch();
  const bannerId = match.params.id;
  const banner = useSelector(getBannerById(bannerId));
  const { loading } = useSelector(state => state.ui);

  function handleSubmit(values) {
    dispatch(updateBanner({ banner: values }));
  }

  useEffect(() => {
    dispatch(fetchBanner({ bannerId }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading || !banner) {
    return <h1>Loading</h1>;
  }

  return <BannerForm title="Ubah Banner" initialValues={banner} onSubmit={handleSubmit} />;
}

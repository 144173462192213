import { auth } from "../../../singletons/firebase";
import {
  AUTH,
  LOGIN,
  LOGIN_SUCCESS,
  AUTH_ERROR,
  LOGOUT,
  CHECK_AUTH_STATE,
  CHECK_AUTH_PERMISSION,
  USER_IS_AUTHORIZED,
  loginSuccess,
  setAuthUser,
  checkAuthPermission,
  userIsAuthorized,
  authError,
  logout,
} from "../../actions/auth.actions";

import { DATABASE_FETCH_ITEM_SUCCESS } from "../../actions/database.actions";
import { fetchUser } from "../../actions/users.actions";
import { setLoader } from "../../actions/ui.actions";
import { setToast } from "../../actions/toast.actions";

export const authMiddleware = ({ dispatch }) => next => action => {
  next(action);

  switch (action.type) {
    case LOGIN:
      next(setLoader({ state: true, feature: AUTH }));

      const { email, password } = action.payload;

      auth.signInWithEmailAndPassword(email, password).then(
        res => dispatch(loginSuccess({ user: res.user })),
        error => dispatch(authError({ error }))
      );
      break;

    case LOGIN_SUCCESS:
      next(checkAuthPermission());
      break;

    case USER_IS_AUTHORIZED:
      next([
        setLoader({ state: false, feature: AUTH }),
        setAuthUser({ user: action.payload }),
        setToast({
          message: `Selamat datang kembali, ${action.payload.displayName ?? action.payload.email}`,
          feature: AUTH,
        }),
      ]);
      break;

    case LOGOUT:
      auth.signOut().catch(error => dispatch(authError({ error })));
      break;

    case CHECK_AUTH_STATE:
      auth.onAuthStateChanged(
        user => {
          if (user) {
            dispatch(checkAuthPermission());
          }
        },
        error => dispatch(authError({ error }))
      );
      break;

    case CHECK_AUTH_PERMISSION:
      auth.currentUser
        .getIdTokenResult()
        .then(idTokenResult => {
          if (idTokenResult.claims.has_dashboard_access) {
            const user = auth.currentUser;

            dispatch(fetchUser({ userId: user.uid, feature: AUTH }));
          } else {
            dispatch(authError({ error: `You don't have the permission` }));
            dispatch(logout());
          }
        })
        .catch(error => dispatch(authError({ error })));
      break;

    case `${AUTH} ${DATABASE_FETCH_ITEM_SUCCESS}`:
      dispatch(userIsAuthorized({ user: { ...auth.currentUser, ...action.payload } }));
      break;

    case AUTH_ERROR:
      next([
        setLoader({ state: false, feature: AUTH }),
        setToast({ message: action.payload, feature: AUTH, type: "error" }),
      ]);
      break;

    default:
      break;
  }
};

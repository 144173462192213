import { themeMiddleware } from "./middlewares/feature/theme";
import { authMiddleware } from "./middlewares/feature/auth";
import { usersMiddleware } from "./middlewares/feature/users";
import { categoriesMiddleware } from "./middlewares/feature/categories";
import { regionsMiddleware } from "./middlewares/feature/regions";
import { nazirsMiddleware } from "./middlewares/feature/nazirs";
import { donationsMiddleware } from "./middlewares/feature/donations";
import { waqfsMiddleware } from "./middlewares/feature/waqfs";
import { paymentMethodsMiddleware } from "./middlewares/feature/paymentMethods";
import { dashboardMiddleware } from "./middlewares/feature/dashboard";
import { donorsMiddleware } from "./middlewares/feature/donors";
import { notificationsMiddleware } from "./middlewares/feature/notifications";
import { threadsMiddleware } from "./middlewares/feature/threads";
import { repliesMiddleware } from "./middlewares/feature/replies";
import { bannersMiddleware } from "./middlewares/feature/banners";
import { waqfReportsMiddleware } from "./middlewares/feature/waqf-reports";
import { eventsMiddleware } from "./middlewares/feature/events";
import { newsMiddleware } from "./middlewares/feature/news";

import { actionSplitterMiddleware } from "./middlewares/core/actionSplitter";
import { toastMiddleware } from "./middlewares/core/toast";
import { databaseMiddleware } from "./middlewares/core/database";
import { httpMiddleware } from "./middlewares/core/http";
import { linkAjaMiddleware } from "./middlewares/feature/linkAja";

const featureMiddleware = [
  themeMiddleware,
  authMiddleware,
  categoriesMiddleware,
  regionsMiddleware,
  usersMiddleware,
  nazirsMiddleware,
  donationsMiddleware,
  waqfsMiddleware,
  paymentMethodsMiddleware,
  dashboardMiddleware,
  donorsMiddleware,
  notificationsMiddleware,
  threadsMiddleware,
  repliesMiddleware,
  bannersMiddleware,
  waqfReportsMiddleware,
  eventsMiddleware,
  newsMiddleware,
  linkAjaMiddleware,
];

const coreMiddleware = [
  actionSplitterMiddleware,
  toastMiddleware,
  databaseMiddleware,
  httpMiddleware,
];

const middlewares = [...featureMiddleware, ...coreMiddleware];

export default middlewares;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import FirebaseTimestamp from "../../lib/FirebaseTimestamp";
import NewsForm from "../../components/form/NewsForm";
import { fetchSingleNews, updateNews } from "../../redux/actions/news.actions";
import { getNewsById } from "../../redux/reducers/news.reducer";

export default function EditNewsPage({ match }) {
  const dispatch = useDispatch();
  const newsId = match.params.id;
  const news = useSelector(getNewsById(newsId));
  const { loading } = useSelector(state => state.ui);

  useEffect(() => {
    dispatch(fetchSingleNews({ newsId }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsId]);

  function handleSubmit(values) {
    dispatch(updateNews({ news: values }));
  }

  function toFormFormat(news) {
    return {
      ...news,
      date: moment(new FirebaseTimestamp(news.date).format()).toDate(),
    };
  }

  if (loading || !news) {
    return <h1>Loading</h1>;
  }

  return (
    <NewsForm title="Edit Berita" initialValues={toFormFormat(news)} onSubmit={handleSubmit} />
  );
}

import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field } from "formik";
import { FormGroup, Label, CustomInput, Row, Col, Button } from "reactstrap";

import AuthenticationLayout from "../../layouts/authentication";

import { login } from "../../redux/actions/auth.actions";

export default function LoginPage() {
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.ui);

  function handleSubmit({ email, password }) {
    dispatch(login({ email, password }));
  }

  return (
    <AuthenticationLayout
      leftSideContent={
        <>
          <p>
            Don't have an account?
            <br />
            <Link className="text-white text-underline" to="/authentication/card/register">
              Get started!
            </Link>
          </p>
          <p className="mb-0 mt-4 mt-md-5 fs--1 font-weight-semi-bold text-300">
            Read our{" "}
            <Link className="text-underline text-300" to="#!">
              terms
            </Link>{" "}
            and{" "}
            <Link className="text-underline text-300" to="#!">
              conditions{" "}
            </Link>
          </p>
        </>
      }
    >
      <h3>Account Login</h3>
      <Formik initialValues={{ email: "", password: "", remember: false }} onSubmit={handleSubmit}>
        {({ values, setFieldValue }) => (
          <Form>
            <FormGroup>
              <Label>Email address</Label>
              <Field
                type="email"
                name="email"
                placeholder="Email address"
                className="form-control"
              />
            </FormGroup>
            <FormGroup>
              <Label>Password</Label>
              <Field
                type="password"
                name="password"
                className="form-control"
                placeholder="Password"
              />
            </FormGroup>
            <Row className="justify-content-between align-items-center">
              <Col xs="auto">
                <CustomInput
                  id="customCheckRemember"
                  label="Remember me"
                  checked={values.remember}
                  onChange={({ target }) => setFieldValue("remember", target.checked)}
                  type="checkbox"
                />
              </Col>
              <Col xs="auto">
                <Link className="fs--1" to="/auth/forgot">
                  Forget Password?
                </Link>
              </Col>
            </Row>
            <FormGroup>
              <Button type="submit" color="primary" block className="mt-3" disabled={loading}>
                Log in
              </Button>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </AuthenticationLayout>
  );
}

import { entityName } from "../../entities/category";

// feature name
export const CATEGORY = `[${entityName}]`;

// action types
export const FETCH_CATEGORIES = `${CATEGORY} Fetch categories`;
export const FETCH_CATEGORY = `${CATEGORY} Fetch a category`;
export const SET_CATEGORIES = `${CATEGORY} Set categories`;
export const UPDATE_CATEGORIES = `${CATEGORY} Update an item in categories`;
export const CREATE_CATEGORY = `${CATEGORY} Create category`;
export const UPDATE_CATEGORY = `${CATEGORY} Update a category`;
export const DELETE_CATEGORY = `${CATEGORY} Delete a category`;

// action creators
export const fetchCategories = () => ({
  type: FETCH_CATEGORIES,
});

export const setCategories = ({ categories }) => ({
  type: SET_CATEGORIES,
  payload: categories,
  meta: { feature: CATEGORY },
});

export const updateCategories = ({ category }) => ({
  type: UPDATE_CATEGORIES,
  payload: category,
  meta: { feature: CATEGORY },
});

export const fetchCategory = ({ categoryId }) => ({
  type: FETCH_CATEGORY,
  payload: categoryId,
});

export const createCategory = ({ category }) => ({
  type: CREATE_CATEGORY,
  payload: category,
  meta: { feature: CATEGORY },
});

export const updateCategory = ({ category }) => ({
  type: UPDATE_CATEGORY,
  payload: category,
  meta: { feature: CATEGORY },
});

export const deleteCategory = ({ categoryId }) => ({
  type: DELETE_CATEGORY,
  payload: categoryId,
  meta: { feature: CATEGORY },
});

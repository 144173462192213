import { isEmpty, pick } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import WaqfForm from "../../components/form/WaqfForm";
import { createWaqf } from "../../redux/actions/waqfs.actions";

export default function CreateWaqfPage() {
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth);
  const isNazir = isEmpty(user) ? false : user.roles.includes("nazir");

  function handleSubmit(values) {
    if (isNazir) {
      values.nazir = pick(user, ["id", "name", "verified"]);
    }

    dispatch(
      createWaqf({
        waqf: values,
      })
    );
  }

  return <WaqfForm title="Tambah Wakaf" onSubmit={handleSubmit} isNazir={isNazir} />;
}

export default class Money {
  amount;

  constructor(amount) {
    this.amount = amount;
  }

  currencyFormat(currency = "IDR") {
    return `${currency} ${this.amount.toLocaleString()}`;
  }
}

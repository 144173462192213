import { bool, mixed, number, object, string } from "yup";

export const waqfValidation = (isNazir = false) => {
  let validationShape = {
    title: string().required("Wajib mengisi judul waqf"),
    date: string().required("Wajib mengisi tanggal"),
    category: mixed().required("Wajib mengisi kategori"),
    location: object().shape({
      province: mixed().required("Wajib mengisi provinsi"),
      regency: mixed().required("Wajib mengisi kabupaten"),
    }),
    nazir: mixed().required("Wajib mengisi nazir"),
    goal: number().min(1, "Minimal target Rp 1").required("Wajib mengisi target"),
    has_deadline: bool().required("Wajib mengisi memiliki tenggat waktu"),
    deadline: string(),
    thumbnail_image: mixed().required("Wajib mengisi thumbnail gambar"),
    images: mixed(),
    description: mixed().required("Wajib mengisi deskripsi"),
  };

  if (isNazir) {
    validationShape.nazir = mixed();
  }

  return object().shape(validationShape);
};

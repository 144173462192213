import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import EventForm from "../../containers/event/EventForm";
import { formatEvent } from "../../redux/middlewares/feature/events";
import { fetchEvent, updateEvent } from "../../redux/actions/events.actions";
import { getEventById } from "../../redux/reducers/events.reducer";
import { Spinner } from "reactstrap";

export default function EditEventPage({ match }) {
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.ui);
  const eventId = match.params.id;
  const event = useSelector(getEventById(eventId));

  function handleSubmit(values) {
    dispatch(updateEvent({ event: values }));
  }

  useEffect(() => {
    dispatch(fetchEvent({ eventId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  if (loading || !event) {
    return (
      <div className="d-flex justify-content-center align-items-center p-10">
        <Spinner type="grow" color="primary" />
      </div>
    );
  }

  return <EventForm initialValues={formatEvent(event)} loading={loading} onSubmit={handleSubmit} />;
}

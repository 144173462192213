import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import ButtonIcon from "../../components/common/ButtonIcon";
import FalconCardHeader from "../../components/common/FalconCardHeader";
import PaymentMethodsTable from "../../containers/payment-method/PaymentMethodsTable";
import { fetchPaymentMethods } from "../../redux/actions/paymentMethods.actions";
import { getPaymentMethods } from "../../redux/reducers/paymentMethods.reducer";

export default function PaymentMethodListPage() {
  const dispatch = useDispatch();
  const paymentMethods = useSelector(getPaymentMethods);

  useEffect(() => {
    dispatch(fetchPaymentMethods());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card className="mb-3">
      <FalconCardHeader title={`Metode Pembayaran`} light={false}>
        <Link to="/payment-methods/create">
          <ButtonIcon icon="plus" transform="shrink-3 down-2" color="falcon-default" size="sm">
            Tambah
          </ButtonIcon>
        </Link>
      </FalconCardHeader>
      <CardBody>
        <PaymentMethodsTable paymentMethods={paymentMethods} />
      </CardBody>
    </Card>
  );
}

import { push } from "connected-react-router";

import { collectionName as collection, waqfFactory as factory } from "../../../entities/waqf";
import {
  databaseDelete,
  databaseFetchCollection,
  databaseFetchItem,
  databasePrepareWrite,
  DATABASE_DELETE_SUCCESS,
  DATABASE_ERROR,
  DATABASE_FETCH_COLLECTION_SUCCESS,
  DATABASE_FETCH_ITEM_SUCCESS,
  DATABASE_STORE_SUCCESS,
  DATABASE_UPDATE_SUCCESS,
} from "../../actions/database.actions";
import {
  CREATE_WAQF,
  DELETE_WAQF,
  FETCH_WAQF,
  FETCH_WAQFS,
  FETCH_WAQF_DONORS,
  setWaqfs,
  updateWaqfs,
  UPDATE_WAQF,
  WAQF,
} from "../../actions/waqfs.actions";
import { setToast } from "../../actions/toast.actions";
import { setLoader } from "../../actions/ui.actions";

export const waqfsMiddleware = ({ dispatch, getState }) => next => action => {
  next(action);

  const user = getState().auth;

  switch (action.type) {
    case FETCH_WAQFS:
      const { constraints: metaContraints } = action.meta;
      let constraints = [...metaContraints];

      if (user.roles && user.roles.includes("nazir")) {
        constraints = [...constraints, ["nazir.id", "==", user.id]];
      }

      next([
        databaseFetchCollection({ collection, feature: WAQF, constraints }),
        setLoader({ state: true, feature: WAQF }),
      ]);
      break;

    case `${WAQF} ${DATABASE_FETCH_COLLECTION_SUCCESS}`:
      next([setWaqfs({ waqfs: action.payload }), setLoader({ state: false, feature: WAQF })]);
      break;

    case FETCH_WAQF:
      next([
        databaseFetchItem({ path: `${collection}/${action.payload}`, feature: WAQF }),
        setLoader({ state: true, feature: WAQF }),
      ]);
      break;

    case FETCH_WAQF_DONORS:
      next([
        databaseFetchCollection({
          collection: `${collection}/${action.payload}/donors_by_date`,
          feature: `${WAQF}_DONORS`,
        }),
      ]);
      break;

    case `${WAQF}_DONORS ${DATABASE_FETCH_COLLECTION_SUCCESS}`:
      const waqfId = action.meta.collection.split("/")[1];
      next([
        updateWaqfs({ waqf: { id: waqfId, donors_by_date: action.payload } }),
        setLoader({ state: false, feature: WAQF }),
      ]);
      break;

    case `${WAQF} ${DATABASE_FETCH_ITEM_SUCCESS}`:
      next([updateWaqfs({ waqf: action.payload }), setLoader({ state: false, feature: WAQF })]);
      break;

    case CREATE_WAQF:
      next([
        databasePrepareWrite({
          collection,
          factory,
          data: action.payload,
          feature: WAQF,
          databaseAction: "create",
        }),
      ]);
      break;

    case `${WAQF} ${DATABASE_STORE_SUCCESS}`:
      next([
        setToast({
          message: "Berhasil menambahkan waqf",
          feature: WAQF,
        }),
        setLoader({ state: false, feature: WAQF }),
      ]);
      dispatch(push("/waqfs"));
      break;

    case UPDATE_WAQF:
      next([
        databasePrepareWrite({
          collection,
          factory,
          data: action.payload,
          feature: WAQF,
          databaseAction: "update",
        }),
      ]);
      break;

    case `${WAQF} ${DATABASE_UPDATE_SUCCESS}`:
      next([
        setToast({
          message: "Berhasil mengupdate waqf",
          feature: WAQF,
        }),
        setLoader({ state: false, feature: WAQF }),
      ]);
      dispatch(push("/waqfs"));
      break;

    case DELETE_WAQF:
      next([
        databaseDelete({
          collection,
          id: action.payload,
          feature: WAQF,
        }),
        setLoader({ state: true, feature: WAQF }),
      ]);
      break;

    case `${WAQF} ${DATABASE_DELETE_SUCCESS}`:
      next([
        setToast({
          message: "Berhasil menghapus waqf",
          feature: WAQF,
        }),
        setLoader({ state: false, feature: WAQF }),
      ]);
      break;

    case `${WAQF} ${DATABASE_ERROR}`:
      next([
        setToast({
          message: action.payload.message.replace(/:.*$/, ""),
          type: "error",
          feature: WAQF,
        }),
        setLoader({ state: false, feature: WAQF }),
      ]);
      break;

    default:
      break;
  }
};

import { toast } from "react-toastify";

import { removeToast, SET_TOAST, setToast } from "../../actions/toast.actions";

export const toastMiddleware = () => next => action => {
  if (action.type.includes(SET_TOAST)) {
    const { payload, meta } = action;
    const id = new Date().getMilliseconds();

    // enrich the original payload with an id
    const notification = {
      id,
      message: payload,
    };

    // fire a new action with the enriched payload
    // note: the payload is an object
    next(setToast({ message: notification, feature: meta.feature }));

    // show toast notification
    toast(payload, { type: meta.type, toastId: id });

    // dispatch a clear action after a given time
    setTimeout(() => {
      next(removeToast({ notificationId: id, feature: meta.feature }));
    }, 1000);
  } else {
    next(action);
  }
};

export const waqfReportFactory = () => {
  return {
    category: "",
    title: "",
    date: "",
    images: [],
    description: "",
    amount: 0,
  };
};

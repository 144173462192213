import { plural } from "pluralize";

export const REGION = "[REGION]";

// action types
export const FETCH_REGIONS = `${REGION} Fetch regions`;
export const SET_REGIONS = `${REGION} Set regions`;

// action creators
export const fetchRegions = ({ level }) => ({
  type: FETCH_REGIONS,
  payload: level,
  meta: { collection: plural(level) },
});

export const setRegions = ({ regions, key }) => ({
  type: SET_REGIONS,
  payload: regions,
  meta: { feature: REGION, key },
});

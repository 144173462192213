import { omit } from "lodash";
import { SET_LATEST_DONATIONS, SET_STATS_APP } from "../actions/dashboard";

// NOTE: stats/app [donations, total_donation, total_fee, thread_replies, threads, waqfs, users, nazirs]

const initialState = {
  latestDonations: [],
  app: {},
};

export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LATEST_DONATIONS:
      return { ...state, latestDonations: action.payload };

    case SET_STATS_APP:
      return { ...state, app: omit(action.payload, "id") };

    default:
      return state;
  }
};

// feature selectors
export const getStatsApp = state => state.dashboard.app;
export const getLatestDonations = state => Object.values(state.dashboard.latestDonations);

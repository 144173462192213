import React from "react";
import { useDispatch, useSelector } from "react-redux";

import EventForm from "../../containers/event/EventForm";
import { createEvent } from "../../redux/actions/events.actions";

export default function CreateEventPage() {
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.ui);

  function handleSubmit(event) {
    dispatch(createEvent({ event }));
  }

  return <EventForm onSubmit={handleSubmit} loading={loading} />;
}

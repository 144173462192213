import React from "react";
import { ConnectedRouter } from "connected-react-router";

import Routes from "../components/routes";
import { history } from "../redux/store";

import "react-toastify/dist/ReactToastify.min.css";
import "react-datetime/css/react-datetime.css";
import "react-image-lightbox/style.css";
import "react-confirm-alert/src/react-confirm-alert.css";

const App = () => {
  return (
    <ConnectedRouter history={history}>
      <Routes />
    </ConnectedRouter>
  );
};

export default App;

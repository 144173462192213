import { entityName } from "../../entities/notifications";

// feature name
export const NOTIFICATION = `[${entityName}]`;

// action types
export const FETCH_NOTIFICATIONS = `${NOTIFICATION} Fetch notifications`;
export const SET_NOTIFICATIONS = `${NOTIFICATION} Set notifications`;

// action creators
export const fetchNotifications = ({
  constraints = [],
  orderBy = [],
  startAfter = "",
  limit = 20,
}) => ({
  type: FETCH_NOTIFICATIONS,
  payload: {
    constraints,
    orderBy,
    startAfter,
    limit,
  },
});

export const setNotifications = ({ notifications }) => ({
  type: SET_NOTIFICATIONS,
  payload: notifications,
});

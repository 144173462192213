import { get } from "lodash";

import {
  databaseFetchItem,
  databaseDelete,
  databasePrepareWrite,
} from "../../actions/database.actions";

import { setLoader } from "../../actions/ui.actions";
import { FETCH_USER, CREATE_USER, UPDATE_USER, DELETE_USER } from "../../actions/users.actions";

export const usersMiddleware = () => next => action => {
  next(action);

  const { feature, collection, factory } = get(action, "meta", {});

  switch (action.type) {
    case FETCH_USER:
      next([
        databaseFetchItem({ path: `${collection}/${action.payload}`, feature }),
        setLoader({ state: true, feature }),
      ]);
      break;

    case CREATE_USER:
      next(
        databasePrepareWrite({
          feature,
          collection,
          factory,
          data: action.payload,
          databaseAction: "create",
        })
      );
      break;

    case UPDATE_USER:
      next(
        databasePrepareWrite({
          feature,
          collection,
          factory,
          data: action.payload,
          databaseAction: "update",
        })
      );
      break;

    case DELETE_USER:
      next([
        databaseDelete({ id: action.payload, collection, feature }),
        setLoader({ state: true, feature }),
      ]);
      break;

    default:
      break;
  }
};

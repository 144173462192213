import { array, mixed, object, string } from "yup";

export const eventValidation = object().shape({
  title: string().required("Wajib mengisi judul"),
  image: mixed().required("Wajib mengisi gambar"),
  start: mixed().required("Wajib mengisi tanggal dan waktu mulai"),
  end: mixed().required("Wajib mengisi tanggal dan waktu berakhir"),
  venue_short: string(),
  venue: string().required("Wajib mengisi lokasi"),
  link: string(),
  speakers: array()
    .of(string().required("Wajib mengisi pemateri/pembicara"))
    .required("Wajib mengisi pemateri/pembicara"),
  description: mixed().required("Wajib mengisi deskripsi"),
});

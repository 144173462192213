import React from "react";
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import { Card, CardBody, CardFooter, FormGroup, FormText, Label, Button } from "reactstrap";

import FalconCardHeader from "../common/FalconCardHeader";
import FormikInput from "./formik-field/FormikInput";

import { categoryFactory, categoryFieldLabels, categoryValidation } from "../../entities/category";
import FormikDropzone from "./formik-field/FormikDropzone";

const CategoryForm = ({ title, initialValues = categoryFactory(), onSubmit }) => {
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={categoryValidation}>
      {() => (
        <Form>
          <Card className="mb-3">
            <FalconCardHeader title={title} />
            <CardBody>
              <FormGroup>
                <Label>{categoryFieldLabels.name}</Label>
                <FormikInput name="name" placeholder={categoryFieldLabels.name} />
              </FormGroup>
              <FormGroup>
                <Label>{categoryFieldLabels.icon}</Label>
                <FormikDropzone name="icon" accept="image/svg+xml" />
                <FormText>Gambar harus dalam format SVG</FormText>
              </FormGroup>
            </CardBody>
            <CardFooter className="d-flex justify-content-end bg-light">
              <Link to="/categories">
                <Button type="button" color="falcon-default" size="sm" className="mr-2">
                  Batal
                </Button>
              </Link>

              <Button type="submit" color="falcon-primary" size="sm">
                Simpan
              </Button>
            </CardFooter>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default CategoryForm;

import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Collapse, Nav, Navbar } from "reactstrap";
import classNames from "classnames";
import { get, isEmpty } from "lodash";
import is from "is_js";

import Flex from "../common/Flex";
import Logo from "./Logo";
import NavbarVerticalMenu from "./NavbarVerticalMenu";
import ToggleButton from "./ToggleButton";

import { appRoutes } from "../../constants/routes";
import { navbarBreakPoint } from "../../constants/config";

import bgNavbarImg from "../../assets/img/generic/bg-navbar.png";

const NavbarVertical = ({ navbarStyle }) => {
  const navBarRef = useRef(null);
  const { isNavbarVerticalCollapsed } = useSelector(state => state.theme);
  const { showBurgerMenu } = useSelector(state => state.ui);
  const user = useSelector(state => state.auth);
  const userRoles = get(user, "roles", []);

  const HTMLClassList = document.getElementsByTagName("html")[0].classList;
  //Control Component did mount and unmounted of hover effect
  if (isNavbarVerticalCollapsed) {
    HTMLClassList.add("navbar-vertical-collapsed");
  }

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add("windows");
    }
    if (is.chrome()) {
      HTMLClassList.add("chrome");
    }
    if (is.firefox()) {
      HTMLClassList.add("firefox");
    }
    return () => {
      HTMLClassList.remove("navbar-vertical-collapsed-hover");
    };
  }, [isNavbarVerticalCollapsed, HTMLClassList]);

  //Control mouseEnter event
  let time = null;
  const handleMouseEnter = () => {
    if (isNavbarVerticalCollapsed) {
      time = setTimeout(() => {
        HTMLClassList.add("navbar-vertical-collapsed-hover");
      }, 100);
    }
  };
  return (
    <Navbar
      expand={navbarBreakPoint}
      className={classNames("navbar-vertical navbar-glass", {
        [`navbar-${navbarStyle}`]: navbarStyle !== "transparent",
      })}
      light
    >
      <Flex align="center">
        <ToggleButton />
        <Logo at="navbar-vertical" width={40} />
      </Flex>

      <Collapse
        navbar
        isOpen={showBurgerMenu}
        className="scrollbar"
        innerRef={navBarRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={() => {
          clearTimeout(time);
          HTMLClassList.remove("navbar-vertical-collapsed-hover");
        }}
        style={
          navbarStyle === "vibrant" && {
            backgroundImage: `linear-gradient(-45deg, rgba(0, 160, 255, 0.86), #0048a2),url(${bgNavbarImg})`,
          }
        }
      >
        <Nav navbar vertical>
          {/* Return only route roles in userRoles or empty route roles */}
          <NavbarVerticalMenu
            routes={appRoutes.filter(
              route => isEmpty(route.roles) || route.roles.some(r => userRoles.includes(r))
            )}
          />
        </Nav>
        <div className="settings px-3 px-xl-0">
          {/* Put isCombo logic here if you intend to use top nav */}
        </div>
      </Collapse>
    </Navbar>
  );
};

NavbarVertical.protoTypes = {
  navbarStyle: PropTypes.string,
};

NavbarVertical.defaultProps = {
  navbarStyle: "transparent",
};

export default NavbarVertical;

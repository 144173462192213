import React, { Fragment } from "react";
import { useDispatch } from "react-redux";

import PaymentMethodForm from "../../components/form/PaymentMethodForm";
import { createPaymentMethod } from "../../redux/actions/paymentMethods.actions";

export default function CreatePaymentMethodPage() {
  const dispatch = useDispatch();

  function handleSubmit(values) {
    dispatch(createPaymentMethod({ paymentMethod: values }));
  }

  return (
    <Fragment>
      <PaymentMethodForm title={`Tambah Metode Pembayaran`} onSubmit={handleSubmit} />
    </Fragment>
  );
}

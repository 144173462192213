import React, { Fragment, useState } from "react";
import moment from "moment";
import CountUp from "react-countup";
import { Card, CardBody, Col, CustomInput, Progress, Row } from "reactstrap";
import echarts from "echarts/lib/echarts";
import ReactEchartsCore from "echarts-for-react/lib/core";
import { capitalize } from "lodash";

import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/chart/line";

import FalconCardHeader from "../../components/common/FalconCardHeader";
import Flex from "../../components/common/Flex";
import CardSummary from "../../components/home/CardSummary";
import { getOptionTotalDonation, getWaqfPercentage, isIterableArray } from "../../helpers/utils";
import FirebaseTimestamp from "../../lib/FirebaseTimestamp";
import Money from "../../lib/Money";
import DonationPaymentMethod from "./DonationPaymentMethod";
import DonationStatus from "./DonationStatus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DetailWaqfTab = ({ waqf }) => {
  const { goal, totalDonation, percentage } = getWaqfPercentage(waqf);
  const months = moment.months();
  const [month, setMonth] = useState(months[0]);

  return (
    <Fragment>
      <div className="card-deck mt-3">
        <CardSummary title="Total Donasi" color="warning" linkText="Lihat Semua" withoutLink>
          <CountUp
            end={waqf.counter ? waqf.counter.donations : 0}
            duration={5}
            separator=","
            decimal="."
          />
        </CardSummary>

        <CardSummary title="Donatur" color="info" linkText="Lihat Semua" withoutLink>
          <CountUp
            end={waqf.counter ? waqf.counter.donors : 0}
            duration={5}
            separator=","
            decimal="."
          />
        </CardSummary>

        <CardSummary title="Donasi Terkumpul" color="success" linkText="Lihat Semua" withoutLink>
          <CountUp
            end={waqf.counter ? waqf.counter.total_donation : 0}
            prefix="IDR "
            duration={3}
            separator=","
            decimal="."
          />
        </CardSummary>
      </div>

      <Row>
        <Col md={6} className="mb-3">
          <DonationPaymentMethod waqf={waqf} />
        </Col>
        <Col md={6} className="mb-3">
          <DonationStatus waqf={waqf} />
        </Col>
      </Row>

      <Card className="mb-3" color="light">
        <CardBody className="p-3">
          <p class="fs--1 mb-0">
            <a href="/#!">
              <FontAwesomeIcon
                icon="exchange-alt"
                className="mr-2"
                style={{
                  transform: "rotate(90deg)",
                }}
              />
              Total keseluruhan Kode Unik adalah{" "}
              <strong>
                {new Money(waqf.counter ? waqf.counter.unique_code : 0).currencyFormat()}
              </strong>
            </a>
          </p>
        </CardBody>
      </Card>

      <Card className="mb-3">
        <FalconCardHeader title="Detail Projek Wakaf"></FalconCardHeader>
        <CardBody>
          <Row>
            <Col md={12} className="mb-4">
              <img src={waqf.thumbnail_image.url} alt={waqf.title} className="rounded w-100" />
            </Col>

            <Col md={12} className="mb-4">
              <Row>
                {waqf.images &&
                  waqf.images.map(image => (
                    <Col md={3} key={image.ref}>
                      <img src={image.url} alt="images" className="w-100 rounded" />
                    </Col>
                  ))}
              </Row>
            </Col>

            <Col md={12} className="mb-4">
              <Fragment>
                <Progress value={percentage} />
                {new Money(totalDonation).currencyFormat()} of {new Money(goal).currencyFormat()}
              </Fragment>
            </Col>

            <Col>
              <Row>
                <Col md={3}>
                  <p className="font-weight-semi-bold mb-1">Judul</p>
                </Col>
                <Col>{waqf.title}</Col>
              </Row>
              <Row>
                <Col md={3}>
                  <p className="font-weight-semi-bold mb-1">Tanggal Rilis</p>
                </Col>
                <Col>{new FirebaseTimestamp(waqf.date).formatted}</Col>
              </Row>
              <Row>
                <Col md={3}>
                  <p className="font-weight-semi-bold mb-1">Deadline</p>
                </Col>
                <Col>
                  {waqf.has_deadline
                    ? waqf.deadline
                      ? new FirebaseTimestamp(waqf.deadline).formatted
                      : "-"
                    : "-"}
                </Col>
              </Row>

              <Row>
                <Col md={3}>
                  <p className="font-weight-semi-bold mb-1">Lokasi</p>
                </Col>
                <Col>{waqf.location.regency.name + ", " + waqf.location.province.name}</Col>
              </Row>

              <Row>
                <Col md={3}>
                  <p className="font-weight-semi-bold mb-1">Kategori</p>
                </Col>
                <Col>{waqf.category ? waqf.category.name : "-"}</Col>
              </Row>

              <Row>
                <Col md={3}>
                  <p className="font-weight-semi-bold mb-1">Deskripsi</p>
                </Col>
                <Col>
                  <div dangerouslySetInnerHTML={{ __html: waqf.description }}></div>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card className="mb-3">
        <FalconCardHeader title="Total Donasi" light={false} titleTag="h6" className="pb-0">
          <Flex>
            <CustomInput
              type="select"
              id="exampleCustomSelect"
              bsSize="sm"
              className="select-month mr-2"
              value={month}
              onChange={({ target }) => setMonth(target.value)}
            >
              {isIterableArray(months) &&
                months.map((month, index) => (
                  <option key={index} value={month}>
                    {capitalize(month)}
                  </option>
                ))}
            </CustomInput>
          </Flex>
        </FalconCardHeader>

        <CardBody className="h-100">
          {waqf.donors_by_date && (
            <ReactEchartsCore
              echarts={echarts}
              option={getOptionTotalDonation(month, waqf.donors_by_date)}
              style={{ minHeight: "18.75rem" }}
            />
          )}
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default DetailWaqfTab;

import { entityName } from "../../entities/waqf";

// feature name
export const WAQF = `[${entityName}]`;

// action types
export const FETCH_WAQFS = `${WAQF} Fetch waqfs`;
export const FETCH_WAQF = `${WAQF} Fetch a waqf`;
export const FETCH_WAQF_DONORS = `${WAQF} Fetch a waqf donors`;
export const SET_WAQFS = `${WAQF} Set waqfs`;
export const UPDATE_WAQFS = `${WAQF} Update an item in waqfs`;
export const CREATE_WAQF = `${WAQF} Create waqf`;
export const UPDATE_WAQF = `${WAQF} Update a waqf`;
export const DELETE_WAQF = `${WAQF} Delete a waqf`;

// action creators
export const fetchWaqfs = ({ constraints = [], orderBy = [], limit = 0 } = {}) => ({
  type: FETCH_WAQFS,
  meta: {
    constraints,
    orderBy,
    limit,
  },
});

export const setWaqfs = ({ waqfs }) => ({
  type: SET_WAQFS,
  payload: waqfs,
  meta: {
    feature: WAQF,
  },
});

export const fetchWaqf = ({ waqfId }) => ({
  type: FETCH_WAQF,
  payload: waqfId,
});

export const fetchWaqfDonors = ({ waqfId }) => ({
  type: FETCH_WAQF_DONORS,
  payload: waqfId,
});

export const updateWaqfs = ({ waqf }) => ({
  type: UPDATE_WAQFS,
  payload: waqf,
  meta: {
    feature: WAQF,
  },
});

export const createWaqf = ({ waqf }) => ({
  type: CREATE_WAQF,
  payload: waqf,
  meta: {
    feature: WAQF,
  },
});

export const updateWaqf = ({ waqf }) => ({
  type: UPDATE_WAQF,
  payload: waqf,
  meta: {
    feature: WAQF,
  },
});

export const deleteWaqf = ({ waqfId }) => ({
  type: DELETE_WAQF,
  payload: waqfId,
  meta: {
    feature: WAQF,
  },
});

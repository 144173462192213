import { checkAuthState } from "./actions/auth.actions";
import { fetchCategories } from "./actions/categories.actions";
import { fetchNazirs } from "./actions/nazirs.actions";
import { fetchRegions } from "./actions/regions.actions";
import { fetchPaymentMethods } from "./actions/paymentMethods.actions";

export default function bootStore(store) {
  store.dispatch(checkAuthState());
  store.dispatch(fetchCategories());
  store.dispatch(fetchPaymentMethods());
  store.dispatch(fetchNazirs());
  store.dispatch(fetchRegions({ level: "province" }));
  store.dispatch(fetchRegions({ level: "regency" }));
}

import React, { useEffect, useState } from "react";
import { isEmpty, reduce, upperFirst, get } from "lodash";
import { Card, CardBody, Col, Row } from "reactstrap";
import ReactEchartsCore from "echarts-for-react/lib/core";
import echarts from "echarts/lib/echarts";
import "echarts/lib/chart/pie";

import {
  getOptionWaqfPie,
  isIterableArray,
  numberFormatter,
  randomColor,
} from "../../helpers/utils";
import DonationPaymentMethodStatusItem from "./DonationPaymentMethodStatusItem";

const DonationStatus = ({ waqf }) => {
  const [totalStatus, setTotalStatus] = useState(0);
  const [donationStatus, setDonationStatus] = useState([]);
  const counterDonationByStatus = get(waqf, "counter.donation_by_status", {});

  useEffect(() => {
    setDonationStatus(
      Object.keys(counterDonationByStatus).map(key => ({
        name: upperFirst(key),
        value: counterDonationByStatus[key],
        color: randomColor(),
      }))
    );

    setTotalStatus(
      isEmpty(counterDonationByStatus)
        ? 0
        : reduce(counterDonationByStatus, (sum, value) => sum + value)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card className="h-md-100">
      <CardBody>
        <Row noGutters className="h-100 justify-content-between">
          <Col xs={5} sm={6} className="col-xxl pr-2">
            <h6 className="mt-1">Status Donasi</h6>
            <div className="fs--2 mt-3">
              {isIterableArray(donationStatus) &&
                donationStatus.map(status => (
                  <DonationPaymentMethodStatusItem key={status.name} {...status} />
                ))}
            </div>
          </Col>
          <Col xs="auto">
            <div className="position-relative">
              <ReactEchartsCore
                echarts={echarts}
                option={getOptionWaqfPie(donationStatus, false)}
                style={{ width: "6.625rem", height: "6.625rem" }}
              />
              <div className="absolute-centered font-weight-medium text-dark fs-2">
                {numberFormatter(totalStatus, 0)}
              </div>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default DonationStatus;

// action types
export const SET_TOAST = "Set toast";
export const REMOVE_TOAST = "Remove Toast";

// action creators
export const setToast = ({ message, feature, type = "default" }) => ({
  type: `${feature} ${SET_TOAST}`,
  payload: message,
  meta: { feature, type },
});

export const removeToast = ({ toastId, feature }) => ({
  type: `${feature} ${REMOVE_TOAST}`,
  payload: toastId,
  meta: { feature },
});

import React, { Fragment, createRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import { Col, Row, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { confirmAlert } from "react-confirm-alert";

import { deleteCategory } from "../../redux/actions/categories.actions";
import { getCategories } from "../../redux/reducers/categories.reducer";
import FirebaseTimestamp from "../../lib/FirebaseTimestamp";

const timeFormatter = time => <Fragment>{new FirebaseTimestamp(time).formatted}</Fragment>;
const iconFormatter = icon => (
  <Fragment>{icon && <img src={icon.url} alt={icon.name} height={30} />}</Fragment>
);

const columns = [
  {
    dataField: "name",
    text: "Nama Kategori",
    classes: "border-0 align-middle",
    headerClasses: "border-0",
    sort: true,
  },
  {
    dataField: "icon",
    text: "Icon Kategori",
    formatter: iconFormatter,
    classes: "border-0 align-middle",
    headerClasses: "border-0",
    sort: false,
  },
  {
    dataField: "created_at",
    text: "Ditambahkan pada",
    formatter: timeFormatter,
    classes: "border-0 align-middle",
    headerClasses: "border-0",
    sort: true,
  },
  {
    dataField: "updated_at",
    text: "Terakhir diupdate pada",
    formatter: timeFormatter,
    classes: "border-0 align-middle",
    headerClasses: "border-0",
    sort: true,
  },
  {
    dataField: "action",
    classes: "border-0 align-middle text-right",
    headerClasses: "border-0",
    text: "",
  },
];

function appendAction(category) {
  return {
    ...category,
    action: <CategoryAction category={category} />,
  };
}

const CategoryAction = ({ category }) => {
  const dispatch = useDispatch();

  function handleDelete() {
    confirmAlert({
      title: "Hapus Kategori",
      message: `Apakah anda yakin ingin menghapus kategori ${category.name}`,
      buttons: [
        {
          label: "Ya, Hapus",
          onClick: () => dispatch(deleteCategory({ categoryId: category.id })),
        },
        { label: "Tidak" },
      ],
    });
  }

  return (
    <Fragment>
      <Link to={`/categories/${category.id}`}>
        <Button color="link" size="sm" className="text-600 btn-reveal mr-1">
          <FontAwesomeIcon icon="edit" className="fs--1 text-info" />
        </Button>
      </Link>

      <Button color="link" size="sm" className="text-600 btn-reveal" onClick={handleDelete}>
        <FontAwesomeIcon icon="trash" className="fs--1 text-danger" />
      </Button>
    </Fragment>
  );
};

const CategoriesTable = () => {
  let table = createRef();
  const categories = useSelector(getCategories);

  return (
    <PaginationProvider
      pagination={paginationFactory({
        custom: true,
        totalSize: categories.length,
        sizePerPage: categories.length,
      })}
    >
      {({ paginationProps, paginationTableProps }) => {
        return (
          <Fragment>
            <div className="table-responsive">
              <BootstrapTable
                ref={table}
                bootstrap4
                keyField="id"
                data={categories.map(appendAction)}
                columns={columns}
                bordered={false}
                classes="table-dashboard table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
                rowClasses="btn-reveal-trigger border-top border-200"
                headerClasses="bg-200 text-900 border-y border-200"
                noDataIndication="Tidak ada data kategori"
                {...paginationTableProps}
              />
            </div>
            <Row noGutters className="px-1 py-3">
              <Col className="pl-3 fs--1">
                <span>{paginationProps.totalSize} Kategori</span>
              </Col>
            </Row>
          </Fragment>
        );
      }}
    </PaginationProvider>
  );
};

export default CategoriesTable;

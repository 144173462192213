import React, { Fragment, useState } from "react";
import { Form, Formik } from "formik";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import { keys, omit } from "lodash";
import { Link } from "react-router-dom";

import FalconCardHeader from "../common/FalconCardHeader";
import { bannerFactory, bannerFieldLabels, bannerValidation } from "../../entities/banner";
import FormikInput from "./formik-field/FormikInput";
import FormikDropzone from "./formik-field/FormikDropzone";

const BannerDataForm = ({ onSubmit }) => {
  const [name, setName] = useState("");
  const [value, setValue] = useState("");

  function handleSubmit() {
    onSubmit({ [name]: value });
    setName("");
    setValue("");
  }

  return (
    <Row className="align-items-center mb-3">
      <Col>
        <Input
          value={name}
          placeholder={bannerFieldLabels["data.name"]}
          onChange={({ target }) => setName(target.value)}
        />
      </Col>
      <Col>
        <Input
          value={value}
          placeholder={bannerFieldLabels["data.value"]}
          onChange={({ target }) => setValue(target.value)}
        />
      </Col>
      <Col>
        <Button type="button" color="secondary" onClick={handleSubmit}>
          {bannerFieldLabels["data.add"]}
        </Button>
      </Col>
    </Row>
  );
};

const BannerDataTable = ({ data, onDelete }) => {
  return (
    <Table>
      <thead>
        <tr>
          <th>{bannerFieldLabels["data.name"]}</th>
          <th>{bannerFieldLabels["data.value"]}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {data.map((values, index) => (
          <tr key={index}>
            <td>{values.name}</td>
            <td>{values.value}</td>
            <td>
              <Button color="danger" size="sm" onClick={() => onDelete(values.name)}>
                Hapus
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

const BannerForm = ({ title, initialValues = bannerFactory(), onSubmit }) => {
  return (
    <Formik initialValues={initialValues} validationSchema={bannerValidation} onSubmit={onSubmit}>
      {({ values, setFieldValue }) => (
        <Form>
          <Card className="mb-3">
            <FalconCardHeader title={title} />

            <CardBody>
              <FormGroup>
                <Label>{bannerFieldLabels.title}</Label>
                <FormikInput name="title" placeholder={bannerFieldLabels.title} />
              </FormGroup>
              <FormGroup>
                <Label>{bannerFieldLabels.image}</Label>
                <FormikDropzone name="image" accept="image/*" />
              </FormGroup>
              <FormGroup>
                <Label>{bannerFieldLabels.type}</Label>
                <FormikInput name="type" placeholder={bannerFieldLabels.type} />
              </FormGroup>
              <FormGroup>
                <Label>{bannerFieldLabels.data}</Label>

                <Fragment>
                  <BannerDataForm
                    onSubmit={data => {
                      const key = Object.keys(data)[0];

                      setFieldValue("data", {
                        ...values.data,
                        [key]: data[key],
                      });
                    }}
                  />

                  <BannerDataTable
                    data={keys(values.data).map((key, _) => ({
                      name: key,
                      value: values.data[key],
                    }))}
                    onDelete={key => {
                      setFieldValue("data", omit(values.data, key));
                    }}
                  />
                </Fragment>
              </FormGroup>
            </CardBody>

            <CardFooter className="d-flex justify-content-end bg-light">
              <Link to="/banners">
                <Button type="button" color="falcon-default" size="sm" className="mr-2">
                  Batal
                </Button>
              </Link>

              <Button type="submit" color="falcon-primary" size="sm">
                Simpan
              </Button>
            </CardFooter>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default BannerForm;

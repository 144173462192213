export const nazirFactory = () => {
  return {
    name: "",
    email: "",
    password: "wakafyuk-1234",
    phone: "",
    verified: false,
    description: null,
    image: null,
    roles: ["nazir"],
  };
};

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import WaqfForm from "../../components/form/WaqfForm";
import { waqfFormat } from "../../entities/waqf";
import { fetchWaqf, updateWaqf } from "../../redux/actions/waqfs.actions";
import { getWaqfById } from "../../redux/reducers/waqfs.reducer";

export default function EditWaqfPage({ match }) {
  const dispatch = useDispatch();
  const waqfId = match.params.id;
  const waqf = useSelector(getWaqfById(waqfId));
  const { loading } = useSelector(state => state.ui);

  useEffect(() => {
    dispatch(fetchWaqf({ waqfId }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waqfId]);

  function handleSubmit(values) {
    dispatch(
      updateWaqf({
        waqf: values,
      })
    );
  }

  if (loading || !waqf) {
    return <h1>Loading</h1>;
  }

  return <WaqfForm title="Ubah Wakaf" initialValues={waqfFormat(waqf)} onSubmit={handleSubmit} />;
}

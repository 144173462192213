// feature name
export const THEME = "[Theme]";

// action types
export const SET_COLOR = `${THEME} Set color`;
export const SET_TEXT_DIRECTION = `${THEME} Set text direction`;
export const SET_CONTAINER_LAYOUT = `${THEME} Set container layout`;
export const SET_NAV_POSITION = `${THEME} Set navbar position`;
export const SET_NAV_STYLE = `${THEME} Set navbar style`;
export const SET_VERTICAL_NAV_COLLAPSED = `${THEME} Set vertical navbar collapsed state`;

// action creators
export const setColor = ({ color }) => ({
  type: SET_COLOR,
  payload: color,
  meta: {
    key: "color",
  },
});

export const setTextDirection = ({ direction }) => ({
  type: SET_TEXT_DIRECTION,
  payload: direction,
  meta: {
    key: "direction",
  },
});

export const setContainerLayout = ({ container }) => ({
  type: SET_CONTAINER_LAYOUT,
  payload: container,
  meta: {
    key: "container",
  },
});

export const setNavPosition = ({ position }) => ({
  type: SET_NAV_POSITION,
  payload: position,
  meta: {
    key: "navPosition",
  },
});

export const setNavStyle = ({ style }) => ({
  type: SET_NAV_STYLE,
  payload: style,
  meta: {
    key: "navStyle",
  },
});

export const setVerticalNavCollapsed = ({ state }) => ({
  type: SET_VERTICAL_NAV_COLLAPSED,
  payload: state,
  meta: {
    key: "isNavbarVerticalCollapsed",
  },
});

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody } from "reactstrap";
import FalconCardHeader from "../../components/common/FalconCardHeader";
import DonorsTable from "../../containers/donor/DonorsTable";
import { fetchDonors } from "../../redux/actions/donors.actions";
import { getDonors } from "../../redux/reducers/donors.reducer";

export default function DonorListPage() {
  const dispatch = useDispatch();
  const donors = useSelector(getDonors);

  useEffect(() => {
    dispatch(fetchDonors());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card className="mb-3">
      <FalconCardHeader title="Donatur" light={false}></FalconCardHeader>
      <CardBody className="p-0">
        <DonorsTable donors={donors} />
      </CardBody>
    </Card>
  );
}

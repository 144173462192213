import React, { Fragment } from "react";
import echarts from "echarts/lib/echarts";
import ReactEchartsCore from "echarts-for-react/lib/core";
import moment from "moment";

import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/chart/line";
import { getGrays, getPosition, rgbaColor, themeColors } from "../../helpers/utils";
import FirebaseTimestamp from "../../lib/FirebaseTimestamp";

function getFormatter(params) {
  const { name, value } = params[0];
  return `${moment(name).format("D MMMM YYYY")}, ${value} donasi`;
}

const getOptionTotalDonation = ({ time, startDate, endDate, donations }) => {
  const grays = getGrays(false);
  const xAxis = [];
  let day = startDate;

  if (time === "year") {
    for (let index = 0; index < 12; index++) {
      xAxis.push(moment().month(index).toDate());
    }
  } else {
    while (day <= endDate) {
      xAxis.push(day.toDate());
      day = day.clone().add(1, "d");
    }
  }

  const yAxis = xAxis.map(xAxisDay => {
    const filtered = donations.filter(donation => {
      const date = moment(new FirebaseTimestamp(donation.created_at).format());

      return date.isSame(xAxisDay, time === "year" ? "month" : "day");
    });

    return filtered.length;
  });

  return {
    tooltip: {
      trigger: "axis",
      padding: [7, 10],
      backgroundColor: grays.white,
      borderColor: grays["300"],
      borderWidth: 1,
      textStyle: { color: themeColors.dark },
      formatter(params) {
        return getFormatter(params);
      },
      transitionDuration: 0,
      position(pos, params, dom, rect, size) {
        return getPosition(pos, params, dom, rect, size);
      },
    },
    xAxis: {
      type: "category",
      data: xAxis,
      boundaryGap: false,
      axisPointer: {
        lineStyle: {
          color: grays["300"],
          type: "dashed",
        },
      },
      splitLine: { show: false },
      axisLine: {
        lineStyle: {
          color: rgbaColor("#000", 0.01),
          type: "dashed",
        },
      },
      axisTick: { show: false },
      axisLabel: {
        color: grays["400"],
        formatter: value =>
          time === "year" ? moment(value).format("MMM, YYYY") : moment(value).format("MMM, DD"),
        margin: 15,
      },
    },
    yAxis: {
      type: "value",
      axisPointer: { show: false },
      splitLine: {
        lineStyle: {
          color: grays["300"],
          type: "dashed",
        },
      },
      boundaryGap: false,
      axisLabel: {
        show: true,
        color: grays["400"],
        margin: 15,
      },
      axisTick: { show: false },
      axisLine: { show: false },
    },
    series: [
      {
        type: "line",
        data: yAxis,
        lineStyle: { color: themeColors.primary },
        itemStyle: {
          color: grays["100"],
          borderColor: themeColors.primary,
          borderWidth: 2,
        },
        symbol: "circle",
        symbolSize: 10,
        smooth: false,
        hoverAnimation: true,
        areaStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: rgbaColor(themeColors.primary, 0.2),
              },
              {
                offset: 1,
                color: rgbaColor(themeColors.primary, 0),
              },
            ],
          },
        },
      },
    ],
    animationDuration: 90000,
    grid: { right: "28px", left: "40px", bottom: "15%", top: "5%" },
  };
};

const DonationChart = ({ time, startDate, endDate, donations }) => {
  return (
    <Fragment>
      <ReactEchartsCore
        echarts={echarts}
        option={getOptionTotalDonation({ time, startDate, endDate, donations })}
        style={{ minHeight: "18.75rem" }}
      />
    </Fragment>
  );
};

export default DonationChart;

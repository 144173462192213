import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { get, isEmpty } from "lodash";

export default function PrivateRoute({ component: Component, roles = [], ...rest }) {
  const user = useSelector(state => state.auth);
  const userRoles = get(user, "roles", []);

  return (
    <Route
      {...rest}
      render={props => {
        if (!user) {
          return <Redirect to={{ pathname: "/auth/login", state: { from: props.location } }} />;
        }

        if (
          !isEmpty(userRoles) &&
          !isEmpty(roles) &&
          !roles.some(role => userRoles.includes(role))
        ) {
          return <Redirect to="/errors/403" />;
        }

        return <Component {...props} />;
      }}
    />
  );
}

import React, { useState } from "react";
import { DropdownMenu, Form, Input, Dropdown, DropdownToggle } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SearchBox = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");

  function toggle() {
    setDropdownOpen(false);
  }

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} className="search-box">
      <DropdownToggle tag="div" data-toggle="dropdown" aria-expanded={dropdownOpen}>
        <Form>
          <Input
            type="search"
            placeholder="Search..."
            aria-label="Search"
            className="rounded-pill search-input"
            value={searchInputValue}
            onChange={({ target }) => setSearchInputValue(target.value)}
            onClick={() => setDropdownOpen(false)}
          />
          <FontAwesomeIcon icon="search" className="position-absolute text-400 search-box-icon" />
        </Form>
        {searchInputValue && (
          <button className="close" onClick={() => setSearchInputValue("")}>
            <FontAwesomeIcon icon="times" />
          </button>
        )}
      </DropdownToggle>
      <DropdownMenu>
        <div className="scrollbar py-3" style={{ maxHeight: "24rem" }}></div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default SearchBox;

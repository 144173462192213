import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Button, Card, CardBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEmpty } from "lodash";

import FirebaseTimestamp from "../../lib/FirebaseTimestamp";

import defaultAvatar from "../../assets/img/generic/user.png";

const ThreadCard = ({
  replyId,
  creator,
  title,
  body,
  likes,
  replies,
  createdAt,
  hideReplies,
  hideDelete,
  onDelete,
}) => {
  const auth = useSelector(state => state.auth);
  const photo = isEmpty(creator.photo) ? defaultAvatar : creator.photo;

  return (
    <Card className="mb-3">
      <CardBody>
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center mb-3">
            <img
              src={photo}
              alt={creator.name}
              width={36}
              height={36}
              className="rounded-circle mr-2"
            />

            <div className="d-flex flex-column">
              <div className="d-flex flex-column">
                <b>{creator.name}</b>
                <small>{new FirebaseTimestamp(createdAt).formatted}</small>
              </div>
            </div>
          </div>

          {!hideDelete && creator.id === auth.uid && (
            <div>
              <Button type="button" color="danger" size="sm" onClick={() => onDelete(replyId)}>
                <FontAwesomeIcon icon="trash" />
              </Button>
            </div>
          )}
        </div>

        {title && <p className="text-black font-weight-bold mb-1">{title}</p>}
        <div dangerouslySetInnerHTML={{ __html: body }} className="text-black"></div>
        <div className="d-flex align-items-center mt-3">
          <span className="mr-3">
            <FontAwesomeIcon icon="heart" /> {likes}
          </span>
          {!hideReplies && (
            <span>
              <FontAwesomeIcon icon="comments" /> {replies}
            </span>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

ThreadCard.propTypes = {
  replyId: PropTypes.string.isRequired,
  creator: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    photo: PropTypes.string,
  }).isRequired,
  body: PropTypes.any.isRequired,
  title: PropTypes.string,
  likes: PropTypes.number,
  replies: PropTypes.number,
  createdAt: PropTypes.any,
  hideReplies: PropTypes.bool,
  hideDelete: PropTypes.bool,
  onDelete: PropTypes.func,
};

ThreadCard.defaultProps = {
  createdAt: "",
  title: "",
  likes: 0,
  replies: 0,
  hideReplies: false,
  hideDelete: false,
  onDelete: () => null,
};

export default ThreadCard;

import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import { themeReducer } from "./reducers/theme.reducer";
import { uiReducer } from "./reducers/ui.reducer";
import { authReducer } from "./reducers/auth.reducer";
import { toastReducer } from "./reducers/toast.reducer";
import { categoriesReducer } from "./reducers/categories.reducer";
import { regionsReducer } from "./reducers/regions.reducer";
import { nazirsReducer } from "./reducers/nazirs.reducer";
import { donationsReducer } from "./reducers/donations.reducer";
import { waqfsReducer } from "./reducers/waqfs.reducer";
import { paymentMethodsReducer } from "./reducers/paymentMethods.reducer";
import { dashboardReducer } from "./reducers/dashboard.reducer";
import { donorsReducer } from "./reducers/donors.reducer";
import { notificationsReducer } from "./reducers/notifications.reducer";
import { threadsReducer } from "./reducers/threads.reducer";
import { repliesReducer } from "./reducers/replies.reducer";
import { bannersReducer } from "./reducers/banner.reducer";
import { waqfReportsReducer } from "./reducers/waqf-reports.reducer";
import { eventsReducer } from "./reducers/events.reducer";
import { newsReducer } from "./reducers/news.reducer";
import { linkAjaReducer } from "./reducers/linkAja.reducer";

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    theme: themeReducer,
    ui: uiReducer,
    notifications: notificationsReducer,
    auth: authReducer,
    toast: toastReducer,
    categories: categoriesReducer,
    regions: regionsReducer,
    nazirs: nazirsReducer,
    donations: donationsReducer,
    waqfs: waqfsReducer,
    paymentMethods: paymentMethodsReducer,
    dashboard: dashboardReducer,
    donors: donorsReducer,
    threads: threadsReducer,
    replies: repliesReducer,
    banners: bannersReducer,
    waqf_reports: waqfReportsReducer,
    events: eventsReducer,
    news: newsReducer,
    linkAja: linkAjaReducer,
  });

export default createRootReducer;

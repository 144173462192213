import {
  databaseFetchCollection,
  DATABASE_ERROR,
  DATABASE_FETCH_COLLECTION_SUCCESS,
} from "../../actions/database.actions";

import { FETCH_REGIONS, REGION, setRegions } from "../../actions/regions.actions";

import { setLoader } from "../../actions/ui.actions";
import { setToast } from "../../actions/toast.actions";

export const regionsMiddleware = () => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_REGIONS:
      next([
        databaseFetchCollection({ collection: action.meta.collection, feature: REGION }),
        setLoader({ state: true, feature: REGION }),
      ]);
      break;

    case `${REGION} ${DATABASE_FETCH_COLLECTION_SUCCESS}`:
      next([
        setRegions({ regions: action.payload, key: action.meta.collection }),
        setLoader({ state: false, feature: REGION }),
      ]);
      break;

    case `${REGION} ${DATABASE_ERROR}`:
      next([
        setToast({ message: "Gagal memuat wilayah", type: "error", feature: REGION }),
        setLoader({ state: false, feature: REGION }),
      ]);
      break;

    default:
      break;
  }
};

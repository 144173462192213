import React from "react";
import { useField } from "formik";
import CurrencyFormat from "react-currency-format";
import classnames from "classnames";

const FormikCurrencyFormat = props => {
  const [field, meta, helpers] = useField(props);
  const error = meta.touched && meta.error;

  function onChange({ value }) {
    helpers.setValue(Number(value));
  }

  return (
    <>
      <CurrencyFormat
        thousandSeparator={true}
        prefix={"Rp "}
        {...props}
        value={field.value}
        onValueChange={onChange}
        className={classnames("form-control", { "border-danger": !!error })}
      />
      {error && <span className="text-danger fs--1">{error}</span>}
    </>
  );
};

export default FormikCurrencyFormat;

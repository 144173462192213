import React from "react";
import { useField } from "formik";
import Switch from "react-switch";

const FormikSwitch = props => {
  const [field, meta, helpers] = useField(props);
  const error = meta.touched && meta.error;

  function onChange(value) {
    helpers.setValue(value);
  }

  return (
    <>
      <Switch {...field} onChange={onChange} {...props} checked={field.value} />
      {error && <span className="text-danger fs--1">{error}</span>}
    </>
  );
};

export default FormikSwitch;

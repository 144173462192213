import { entityName } from "../../entities/donation";

// feature name
export const DONATION = `[${entityName}]`;

// action types
export const FETCH_DONATIONS = `${DONATION} Fetch donations`;
export const FETCH_DONATION = `${DONATION} Fetch a donation`;
export const SET_DONATIONS = `${DONATION} Set donations`;
export const UPDATE_DONATIONS = `${DONATION} Update an item in donations`;
export const UPDATE_DONATION = `${DONATION} Update a donation`;
export const DELETE_DONATION = `${DONATION} Delete a donation`;

// action creators
export const fetchDonations = ({ constraints = [], orderBy = [], limit = 0 } = {}) => ({
  type: FETCH_DONATIONS,
  meta: {
    constraints,
    orderBy,
    limit,
  },
});

export const setDonations = ({ donations }) => ({
  type: SET_DONATIONS,
  payload: donations,
  meta: {
    feature: DONATION,
  },
});

export const updateDonations = ({ donation }) => ({
  type: UPDATE_DONATIONS,
  payload: donation,
  meta: {
    feature: DONATION,
  },
});

export const fetchDonation = ({ donationId }) => ({
  type: FETCH_DONATION,
  payload: donationId,
  meta: {
    feature: DONATION,
  },
});

export const updateDonation = ({ donation }) => ({
  type: UPDATE_DONATION,
  payload: donation,
  meta: {
    feature: DONATION,
  },
});

export const deleteDonation = ({ donationId }) => ({
  type: DELETE_DONATION,
  payload: donationId,
  meta: {
    feature: DONATION,
  },
});

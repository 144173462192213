import React, { createRef, Fragment } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { PaginationProvider } from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom";
import { Badge, Col, Row, Button } from "reactstrap";
import { upperFirst } from "lodash";

import Money from "../../lib/Money";
import FirebaseTimestamp from "../../lib/FirebaseTimestamp";

const statusFormatter = status => {
  let color;
  let icon;

  switch (status) {
    case "pending":
      color = "warning";
      icon = "stream";
      break;

    case "completed":
      color = "success";
      icon = "check";
      break;

    case "abandoned":
      color = "danger";
      icon = "ban";
      break;

    default:
      color = "secondary";
      break;
  }

  return (
    <Fragment>
      <Badge className={`rounded-capsule badge-soft-${color}`}>
        {upperFirst(status)} <FontAwesomeIcon icon={icon} className="ml-1" />
      </Badge>
    </Fragment>
  );
};

const columns = [
  {
    dataField: "donor.name",
    text: "Nama Donatur",
    classes: "border-0 align-middle",
    headerClasses: "border-0",
    headerStyle: {
      minWidth: "150px",
    },
    sort: true,
  },
  {
    dataField: "waqf.title",
    text: "Waqf",
    classes: "border-0 align-middle",
    headerClasses: "border-0",
    headerStyle: {
      minWidth: "200px",
    },
    sort: true,
  },
  {
    dataField: "amount",
    text: "Jumlah",
    formatter: amount => new Money(amount).currencyFormat(),
    classes: "border-0 align-middle",
    headerClasses: "border-0",
    headerStyle: {
      minWidth: "150px",
    },
    sort: true,
  },
  {
    dataField: "status",
    text: "Status",
    formatter: statusFormatter,
    classes: "border-0 align-middle",
    headerClasses: "border-0",
    headerStyle: {
      minWidth: "150px",
    },
    sort: true,
  },
  {
    dataField: "created_at",
    text: "Tanggal Donasi",
    formatter: date => new FirebaseTimestamp(date).formatted,
    classes: "border-0 align-middle",
    headerClasses: "border-0",
    headerStyle: {
      minWidth: "150px",
    },
    sort: true,
  },
  {
    dataField: "payment_method.name",
    text: "Metode Pembayaran",
    classes: "border-0 align-middle",
    headerClasses: "border-0",
    headerStyle: {
      minWidth: "200px",
    },
    sort: true,
  },
  {
    dataField: "action",
    classes: "border-0 align-middle text-right",
    headerClasses: "border-0",
    text: "",
  },
];

function appendAction(donation) {
  return {
    ...donation,
    action: (
      <Fragment>
        <Link to={`/donations/${donation.id}`}>
          <Button color="link" size="sm" className="text-600 btn-reveal mr-1">
            <FontAwesomeIcon icon="eye" className="fs--1 text-info" />
          </Button>
        </Link>
      </Fragment>
    ),
  };
}

const DonationsTable = ({ donations }) => {
  let table = createRef();

  return (
    <PaginationProvider
      pagination={paginationFactory({
        custom: true,
        totalSize: donations.length,
        sizePerPage: donations.length,
      })}
    >
      {({ paginationProps, paginationTableProps }) => {
        return (
          <Fragment>
            <div className="table-responsive">
              <BootstrapTable
                ref={table}
                bootstrap4
                keyField="id"
                data={donations.map(appendAction)}
                columns={columns}
                bordered={false}
                classes="table-dashboard table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
                rowClasses="btn-reveal-trigger border-top border-200"
                headerClasses="bg-200 text-900 border-y border-200"
                noDataIndication="Tidak ada data donasi"
                defaultSorted={[{ dataField: "created_at", order: "desc" }]}
                {...paginationTableProps}
              />
            </div>
            <Row noGutters className="px-1 py-3">
              <Col className="pl-3 fs--1">
                <span>{paginationProps.totalSize} Donasi</span>
              </Col>
            </Row>
          </Fragment>
        );
      }}
    </PaginationProvider>
  );
};

DonationsTable.propTypes = {
  donations: PropTypes.array,
};

DonationsTable.defaultProps = {
  donations: [],
};

export default DonationsTable;

import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

import CategoriesTable from "../../containers/category/CategoriesTable";
import FalconCardHeader from "../../components/common/FalconCardHeader";
import ButtonIcon from "../../components/common/ButtonIcon";

export default function CategoryListPage() {
  return (
    <Card className="mb-3">
      <FalconCardHeader title="Kategori Wakaf" light={false}>
        <Link to="/categories/create">
          <ButtonIcon icon="plus" transform="shrink-3 down-2" color="falcon-default" size="sm">
            Tambah
          </ButtonIcon>
        </Link>
      </FalconCardHeader>
      <CardBody className="p-0">
        <CategoriesTable />
      </CardBody>
    </Card>
  );
}

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody } from "reactstrap";

import FalconCardHeader from "../../components/common/FalconCardHeader";
import DonationsTable from "../../containers/donation/DonationsTable";
import { fetchDonations } from "../../redux/actions/donations.actions";
import { getDonations } from "../../redux/reducers/donations.reducer";
import { useQuery } from "../../helpers/utils";

export default function DonationListPage() {
  const dispatch = useDispatch();
  const donations = useSelector(getDonations);
  const query = useQuery();

  useEffect(() => {
    const donorId = query.get("donor");
    const waqfId = query.get("waqf");
    let constraints = [];

    if (donorId) {
      constraints = [["donor.user_id", "==", donorId]];
    } else if (waqfId) {
      constraints = [["waqf.id", "==", waqfId]];
    }

    dispatch(fetchDonations({ constraints }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card className="mb-3">
      <FalconCardHeader title="Donasi" light={false}></FalconCardHeader>
      <CardBody className="p-0">
        <DonationsTable donations={donations} />
      </CardBody>
    </Card>
  );
}

import { DELETE_BANNER, SET_BANNERS, UPDATE_BANNERS } from "../actions/banners.actions";
import entityReducer from "./entityReducer";

export const bannersReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_BANNERS:
    case UPDATE_BANNERS:
    case DELETE_BANNER:
      return entityReducer(state, action);

    default:
      return state;
  }
};

// feature selectors
export const getBanners = state => Object.values(state.banners);
export const getBannerById = id => state => state.banners[id];

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import NazirForm from "../../components/form/NazirForm";
import { fetchNazir, updateNazir } from "../../redux/actions/nazirs.actions";
import { getNazirById } from "../../redux/reducers/nazirs.reducer";

export default function EditCategoryPage({ match }) {
  const dispatch = useDispatch();
  const nazirId = match.params.id;
  const nazir = useSelector(getNazirById(nazirId));
  const { loading } = useSelector(state => state.ui);

  useEffect(() => {
    dispatch(fetchNazir({ nazirId }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nazirId]);

  function handleSubmit(values) {
    dispatch(updateNazir({ nazir: values }));
  }

  if (loading || !nazir) {
    return <h1>Loading</h1>;
  }

  return <NazirForm title="Edit Nazir" initialValues={nazir} onSubmit={handleSubmit} isEdit />;
}

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { createRef, Fragment } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";

import { deleteBanner } from "../../redux/actions/banners.actions";

const imageFormatter = image => (
  <Fragment>
    <img src={image.url} alt={image.name} widtH={50} />
  </Fragment>
);

const dataFormatter = data => (
  <div>
    <pre>{JSON.stringify(data, null, 2)}</pre>
  </div>
);

const columns = [
  {
    dataField: "title",
    text: "Judul",
    classes: "border-0 align-middle",
    headerClasses: "border-0",
    headerStyle: {
      minWidth: "250px",
    },
    sort: true,
  },
  {
    dataField: "position",
    text: "Posisi",
    classes: "border-0 align-middle",
    headerClasses: "border-0",
    headerStyle: {
      minWidth: "100px",
    },
    sort: true,
  },
  {
    dataField: "type",
    text: "Tipe",
    classes: "border-0 align-middle",
    headerClasses: "border-0",
    headerStyle: {
      minWidth: "100px",
    },
    sort: true,
  },
  {
    dataField: "image",
    text: "Gambar",
    formatter: imageFormatter,
    classes: "border-0 align-middle",
    headerClasses: "border-0",
    headerStyle: {
      minWidth: "150px",
    },
    sort: true,
  },
  {
    dataField: "data",
    text: "Data",
    formatter: dataFormatter,
    classes: "border-0 align-middle",
    headerClasses: "border-0",
    headerStyle: {
      minWidth: "200px",
    },
    sort: true,
  },
  {
    dataField: "action",
    classes: "border-0 align-middle text-right",
    headerClasses: "border-0",
    text: "",
  },
];

function appendAction(banner) {
  return {
    ...banner,
    action: <BannerAction banner={banner} />,
  };
}

const BannerAction = ({ banner }) => {
  const dispatch = useDispatch();

  function handleDelete() {
    confirmAlert({
      title: "Hapus Banner",
      message: `Apakah anda yakin ingin menghapus banner ${banner.title}`,
      buttons: [
        {
          label: "Ya, Hapus",
          onClick: () => dispatch(deleteBanner({ bannerId: banner.id })),
        },
        { label: "Tidak" },
      ],
    });
  }

  return (
    <div className="d-flex">
      <Link to={`/banners/${banner.id}`}>
        <Button color="link" size="sm" className="text-600 btn-reveal mr-1">
          <FontAwesomeIcon icon="edit" className="fs--1 text-info" />
        </Button>
      </Link>

      <Button color="link" size="sm" className="text-600 btn-reveal" onClick={handleDelete}>
        <FontAwesomeIcon icon="trash" className="fs--1 text-danger" />
      </Button>
    </div>
  );
};

const BannersTable = ({ banners }) => {
  let table = createRef();

  return (
    <PaginationProvider
      pagination={paginationFactory({
        custom: true,
        totalSize: banners.length,
        sizePerPage: banners.length,
      })}
    >
      {({ paginationProps, paginationTableProps }) => (
        <Fragment>
          <div className="table-responsive">
            <BootstrapTable
              ref={table}
              bootstrap4
              keyField="id"
              data={banners.map(appendAction)}
              columns={columns}
              bordered={false}
              classes="table-dashboard table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
              rowClasses="btn-reveal-trigger border-top border-200"
              headerClasses="bg-200 text-900 border-y border-200"
              noDataIndication="Tidak ada data banner"
              {...paginationTableProps}
            />
          </div>
          <Row noGutters className="px-1 py-3">
            <Col className="pl-3 fs--1">
              <span>{paginationProps.totalSize} Banner</span>
            </Col>
          </Row>
        </Fragment>
      )}
    </PaginationProvider>
  );
};

export default BannersTable;

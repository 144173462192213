import { SET_NOTIFICATIONS } from "../actions/notifications.actions";

export const notificationsReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_NOTIFICATIONS:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

// feature selectors
export const getNotifications = state => Object.values(state.notifications);

export const bannerFieldLabels = {
  title: "Judul Banner",
  position: "Posisi Banner",
  image: "Gambar Banner",
  type: "Jenis Banner",
  data: "Data Mengenai Banner",
  "data.name": "Nama Data",
  "data.value": "Nilai",
  "data.add": "Tambah",
};

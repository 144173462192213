import { push } from "connected-react-router";

import {
  PAYMENT_METHOD,
  CREATE_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD,
  FETCH_PAYMENT_METHOD,
  UPDATE_PAYMENT_METHOD,
  setPaymentMethods,
  updatePaymentMethods,
} from "../../actions/paymentMethods.actions";
import {
  databaseDelete,
  databaseFetchCollection,
  databaseFetchItem,
  databasePrepareWrite,
  DATABASE_DELETE_SUCCESS,
  DATABASE_ERROR,
  DATABASE_FETCH_COLLECTION_SUCCESS,
  DATABASE_FETCH_ITEM_SUCCESS,
  DATABASE_STORE_SUCCESS,
  DATABASE_UPDATE_SUCCESS,
} from "../../actions/database.actions";
import { FETCH_PAYMENT_METHODS } from "../../actions/paymentMethods.actions";
import {
  collectionName as collection,
  paymentMethodFactory as factory,
} from "../../../entities/payment-methods";
import { setLoader } from "../../actions/ui.actions";
import { setToast } from "../../actions/toast.actions";

export const paymentMethodsMiddleware = ({ dispatch }) => next => action => {
  next(action);

  switch (action.type) {
    case FETCH_PAYMENT_METHODS:
      next([
        databaseFetchCollection({ collection, feature: PAYMENT_METHOD }),
        setLoader({ state: true, feature: PAYMENT_METHOD }),
      ]);

      break;

    case `${PAYMENT_METHOD} ${DATABASE_FETCH_COLLECTION_SUCCESS}`:
      next([
        setPaymentMethods({ paymentMethods: action.payload }),
        setLoader({ state: false, feature: PAYMENT_METHOD }),
      ]);
      break;

    case FETCH_PAYMENT_METHOD:
      next([
        databaseFetchItem({ path: `${collection}/${action.payload}`, feature: PAYMENT_METHOD }),
        setLoader({ state: true, feature: PAYMENT_METHOD }),
      ]);
      break;

    case `${PAYMENT_METHOD} ${DATABASE_FETCH_ITEM_SUCCESS}`:
      next([
        updatePaymentMethods({ paymentMethod: action.payload }),
        setLoader({ state: false, feature: PAYMENT_METHOD }),
      ]);
      break;

    case CREATE_PAYMENT_METHOD:
      next([
        databasePrepareWrite({
          collection,
          factory,
          data: action.payload,
          feature: PAYMENT_METHOD,
          databaseAction: "create",
        }),
      ]);
      break;

    case `${PAYMENT_METHOD} ${DATABASE_STORE_SUCCESS}`:
      next([
        setToast({
          message: "Berhasil menambahkan metode pembayaran",
          feature: PAYMENT_METHOD,
        }),
        setLoader({ state: false, feature: PAYMENT_METHOD }),
      ]);
      dispatch(push("/payment-methods"));
      break;

    case UPDATE_PAYMENT_METHOD:
      next(
        databasePrepareWrite({
          collection,
          factory,
          data: action.payload,
          feature: PAYMENT_METHOD,
          databaseAction: "update",
        })
      );
      break;

    case `${PAYMENT_METHOD} ${DATABASE_UPDATE_SUCCESS}`:
      next([
        setToast({
          message: "Berhasil mengupdate metode pembayaran",
          feature: PAYMENT_METHOD,
        }),
        setLoader({
          state: false,
          feature: PAYMENT_METHOD,
        }),
      ]);
      dispatch(push("/payment-methods"));
      break;

    case DELETE_PAYMENT_METHOD:
      next([
        databaseDelete({ collection, id: action.payload, feature: PAYMENT_METHOD }),
        setLoader({ state: true, feature: PAYMENT_METHOD }),
      ]);
      break;

    case `${PAYMENT_METHOD} ${DATABASE_DELETE_SUCCESS}`:
      next([
        setToast({
          message: "Berhasil menghapus metode pembayaran",
          feature: PAYMENT_METHOD,
        }),
        setLoader({ state: false, feature: PAYMENT_METHOD }),
      ]);
      break;

    case `${PAYMENT_METHOD} ${DATABASE_ERROR}`:
      next([
        setToast({
          message: action.payload.message.replace(/:.*$/, ""),
          type: "error",
          feature: PAYMENT_METHOD,
        }),
        setLoader({ state: false, feature: PAYMENT_METHOD }),
      ]);
      break;

    default:
      break;
  }
};

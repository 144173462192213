import { SET_DONORS, UPDATE_DONORS } from "../actions/donors.actions";
import entityReducer from "./entityReducer";

export const donorsReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_DONORS:
    case UPDATE_DONORS:
      return entityReducer(state, action);

    default:
      return state;
  }
};

// feature selectors
export const getDonors = state => Object.values(state.donors);
export const getDonorById = id => state => state.donors[id];

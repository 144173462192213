import {
  DELETE_WAQF_REPORT,
  SET_WAQF_REPORTS,
  UPDATE_WAQF_REPORTS,
} from "../actions/waqf-reports.actions";
import entityReducer from "./entityReducer";

export const waqfReportsReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_WAQF_REPORTS:
    case UPDATE_WAQF_REPORTS:
    case DELETE_WAQF_REPORT:
      return entityReducer(state, action);

    default:
      return state;
  }
};

// feature selectors
export const getWaqfReports = state => Object.values(state.waqf_reports);
export const getWaqfReportById = id => state => state.waqf_reports[id];

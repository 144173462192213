import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import NavbarTop from "../components/navbar/NavbarTop";
import NavbarVertical from "../components/navbar/NavbarVertical";
import { getPageName } from "../helpers/utils";
import { getIsFluid, getHasVerticalNav } from "../redux/reducers/theme.reducer";

const DashboardLayout = ({ location, children }) => {
  const isFluid = useSelector(getIsFluid);
  const isVertical = useSelector(getHasVerticalNav);
  const navbarStyle = useSelector(state => state.theme.navStyle);
  const isKanban = getPageName("kanban");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className={isFluid || isKanban ? "container-fluid" : "container"}>
      {isVertical && <NavbarVertical isKanban={isKanban} navbarStyle={navbarStyle} />}
      <div className="content">
        <NavbarTop />
        {children}
        <p className="mb-5"></p>
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  location: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default withRouter(DashboardLayout);

import { REMOVE_TOAST, SET_TOAST } from "../actions/toast.actions";

export const toastReducer = (toast = [], action) => {
  switch (true) {
    case action.type.includes(SET_TOAST):
      return [...toast, action.payload];

    case action.type.includes(REMOVE_TOAST):
      return toast.filter(toast => toast.id !== action.payload);

    default:
      return toast;
  }
};
